import styled, { css } from 'styled-components';
import { LANDSCAPE_FORM_MAX_HEIGHT_IN_PX, TK_PLAYER_DIV_ID_2D, TK_PLAYER_DIV_ID_3D } from '../../../utils/constants';
import SVG from 'react-inlinesvg';

interface DeviceInfo {
  isMobile: boolean;
  isIpad: boolean;
  isDesktop: boolean;
}

interface Theme {
  device: DeviceInfo;
  appName: string;
}

interface PictureProps {
  theme: Theme;
  slideDirection: string | undefined;
}
interface StepPicture {
  theme: Theme;
  slideDirection: string | undefined;
}

interface WrapperProps {
  height?: string;
  maxHeight?: string;
  width?: string;
  border?: string;
  isRotable?: boolean;
  conditionalCSS?: boolean;
  displayTutorial?: boolean;
  fullScreen: boolean;
  isActiveAnimation: boolean;
  isMobile: boolean;
}

export const Wrapper = styled.div
  .attrs<WrapperProps>(({ theme, isRotable, conditionalCSS, displayTutorial, height, isActiveAnimation }) => ({
    style: {
      cursor: isRotable ? 'grab' : 'default',
      height: isActiveAnimation ? '100svh' : height,
      transition: 'height 1s ease-in-out',
    },
  }))
  .withConfig({
    shouldForwardProp: (prop) =>
      !['isRotable', 'conditionalCSS', 'fullScreen', 'displayTutorial', 'isActiveAnimation', 'isMobile'].includes(prop),
  })<WrapperProps>(
  ({ fullScreen, displayTutorial, isActiveAnimation, height, isMobile }) => `
  position: ${fullScreen ? '' : 'relative'};
  user-select: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;



  &:active {
    cursor: grabbing !important;
  }

  #canvas {
    position: absolute;
    width: ${isMobile ? '512px' : '1024px'};
    height:${isMobile ? '512px' : '1024px'};
    top:${isMobile ? '50%' : '45%'} ;
    left: 50%;
  }

  #${TK_PLAYER_DIV_ID_2D}, #${TK_PLAYER_DIV_ID_3D} {
      min-height: ${isMobile ? '100vw' : `calc(100vh - ${LANDSCAPE_FORM_MAX_HEIGHT_IN_PX}px)`};
      min-width: ${isMobile ? '100vw' : '100vw'};
      height: ${fullScreen ? '100svh' : '100%'};
      width: ${fullScreen ? '100vw' : '100%'};
      position: ${fullScreen ? 'absolute' : 'relative'};
      top: ${fullScreen ? '0' : ''};
      left: ${fullScreen ? '0' : ''};
      background-color: ${fullScreen ? 'white' : 'transparent'};
      z-index: ${fullScreen ? 9999999 : 0};
      overflow: hidden;
      opacity: ${displayTutorial ? '0' : '1'};
      container-type: size;
      transition: transform 1s ease-in-out;
      

      @media (max-width: 768px) {
        transform: translate(0%, 0%);
      }

      div[class*='waterMark'] {
        display: none;
      }
    
      div[class*='threekit'] {
        div[class*='holder'] {
          div[class*='player'] {
               div[class*='logo'] {
              display: none;
            }
            div[class*='layerContainer']{
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              position:relative;

              
            }
            

          }
        }
      }
    }
`
);

export const PlayerBackground = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const Picture = styled.img
  .attrs<PictureProps>((props) => ({}))
  .withConfig({
    shouldForwardProp: (prop) => !['slideDirection'].includes(prop),
  })`
  ${({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    slideDirection,
  }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 70%;
    min-width: 300px;
    max-width: 500px;
    justify-content: center;

    @media (min-width: 767px){
      ${
        isMobile
          ? css`
              width: 70%;
            `
          : css`
              width: 50%;
            `
      }
    }
  `}
`;
export const RotationImage = styled.svg.withConfig({
  shouldForwardProp: (prop) => !['matrix', 'show', 'bottom', 'isMobile', 'sliderPosition'].includes(prop),
})<{ matrix: string; show: boolean; sliderPosition: string; isMobile: boolean }>`
  ${({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    show,
    sliderPosition,
    matrix,
  }) => `
  position: absolute;
  opacity: ${show ? '100' : '0'};
  bottom: ${sliderPosition};
  left: 50%;
  width: ${isMobile ? '50%' : '30%'};
  transform: translate(-50%, 0%);
  z-index: 100;
  height: 60px;
  transition: opacity 1s;
  pointer-events: none;
  .anime {
    width:100px;
    height:100px;
    transform: ${matrix};
    transition: transform 1s ease-in-out;
  }
`}
`;
export const StepPicture = styled.img
  .attrs<StepPicture>((props) => ({}))
  .withConfig({
    shouldForwardProp: (prop) => !['slideDirection'].includes(prop),
  })`
  ${({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    slideDirection,
  }) => `
    z-index: 999999;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    border-radius: 5px;
    min-width: 300px;
    max-width: 500px;
    justify-content: center;
    transform: translate(-50%, -50%);

    @keyframes slideInFromRight {
        from {
            transform: translate(100%, -50%);
            opacity: 0;
          }
          to {
            transform: translate(-50%, -50%);
            opacity: 1;
          }
      }
  
      @keyframes slideInFromLeft {
        from {
            transform: translate(-100%, -50%);
            opacity: 0;
          }
          to {
            transform: translate(-50%, -50%);
            opacity: 1;
          }
      }
    animation: ${slideDirection === 'next' ? 'slideInFromRight' : 'slideInFromLeft'} 0.5s forwards;

    @media (min-width: 767px){
      ${
        isMobile
          ? css`
              width: 70%;
            `
          : css`
              width: 50%;
            `
      }
    }

  `}
`;

export const PlayerElements = styled.div.withConfig({
  shouldForwardProp: (prop) => !['show'].includes(prop),
})<{ show: boolean }>`
  display: flex;
  position: absolute;
  z-index: 1;
  bottom: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 1%;
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
`;
