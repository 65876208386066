import { getAllAttributes, IAttribute, setActiveGroup, setConfiguration } from '../../../store/threekitSlicer';
import { isChooseValue } from '../../../utils/function/functions';
import { useDispatch, useSelector } from 'react-redux';
import { ResetButtonWrapper } from './resetButton.styles';
import { useCallback } from 'react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { usePlayerView } from '../../../hooks';
import { t } from 'i18next';

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

function ResetButton({
  attributes,
  css = {},
  all = false,
}: {
  attributes?: IAttribute[];
  css?: Record<string, any>;
  all?: boolean,
}) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const allAttributes = useSelector(getAllAttributes);
  const { changeView } = usePlayerView();

  const resetAttributes = useCallback((attributesToReset: IAttribute[]) => {
    if (!attributesToReset) return;
    const newConfiguration = attributesToReset.reduce((configAccumulator, attribute) => {
      const selectValue = attribute.values.find((value: any) => isChooseValue(value)) || attribute?.defaultValue;
      return selectValue?.assetId
        ? {
            ...configAccumulator,
            [attribute.name]: { assetId: selectValue.assetId },
          }
        : {
          ...configAccumulator,
          [attribute.name]: selectValue,
        };
    }, {});
    dispatch(setConfiguration(newConfiguration) as any);
  }, [dispatch]);

  const resetAll = useCallback(() => {
    const arrayAllAttributes = Object.values(allAttributes || {});
    resetAttributes(arrayAllAttributes);
    dispatch(setActiveGroup(''));
    changeView({ newView: "default" });
  }, [allAttributes, resetAttributes, dispatch, changeView]);


  return (
    <ResetButtonWrapper onClick={all ? resetAll : attributes ? () => resetAttributes(attributes) : ()=>{}} style={{ ...css }}>
      {t('button.label.reset', 'Reset')}
    </ResetButtonWrapper>
  );
}

export default ResetButton;
