import styled from 'styled-components';

export const FiltersValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: auto;
  width: 100%;
`;

export const Arrow = styled.div.withConfig({
  shouldForwardProp: (prop) => !['img', 'direction'].includes(prop),
})<{ img: string; direction: 'left' | 'right' }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    img,
    direction,
  }) => {
    return `
  position: absolute;
  ${direction}: 15px;
  height: 110px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
  background-image: url(${img});
  background-color: white;
  background-position: center;
  background-size: 9px;
  background-repeat: no-repeat;
  ${isMobile &&
      `
     ${direction}: 5px;
     width: 30px;
    `
      };
`
  });

export const AttributeValuesContainer = styled.ul`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  gap: 20px;
  height: fit-content;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const AttributeValue = styled.li
  .attrs({ className: 'AttributeValue' })
  .withConfig({
    shouldForwardProp: (prop) => !['isSelected', 'inStock', 'img'].includes(prop),
  })<{ isSelected: boolean; inStock: boolean; img: string, count: number }>(
    ({
      theme: {
        device: { isMobile, isIpad, isDesktop },
      },
      isSelected,
      inStock,
      img,
      count,
    }) => {
      return `
    position: relative;
    background-image: url(${img});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${inStock ? 'transparent' : '#F5F5F5'};
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid ${isSelected ? (inStock ? '#000' : '#C53929') : '#E1E1E1'};
    margin: 1px;
    flex: 0 0 auto;
    max-height: 90%;
    height: 100px;
    aspect-ratio: 1/1;
    > img {
      max-width: 100px;
      aspect-ratio: 1 / 1;
      visibility: hidden;
    }

    @media (hover: hover) {
      &:hover {
        background-color: #f0f0f0;
      }
      &:active {
        background-color: #e0e0e0;
      }
    }

    @media (orientation: landscape) {
      height: auto;
      > img {
        max-height: min(90%, 140px);
        aspect-ratio: 1 / 1;
      }
    }
    &::after {
      content: ${count > 0 ? `'${count}'` : "''"};
      width: ${count > 0 ? '15%' : '10%'};
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: ${!inStock ? '#C53929' : '#000'};
      color: #fff;
      position: absolute;
      top: 5px;
      right: 5px;
      text-align: center;
      font-size: 12px;
      opacity: ${(!inStock || count > 0) ? 1 : 0};
      transition: opacity 0.3s ease;
    }
    }
    `;
    }
  );

export const FiltersWrapper = styled.div<{ countFilters: number }>`
  display: grid;
  grid-template-columns: repeat(${({ countFilters }) => countFilters}, 1fr);
  gap: 10px;
  width: 100%;
`;

export const Filter = styled.div<{ isActive: boolean }>`
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: 1px solid ${({ isActive }) => (isActive ? '#000' : '#E1E1E1')};
  text-align: center;
  font-family: LouisVuitton_Regular;
`;
