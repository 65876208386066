import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsRotable, getLastAngle, setLastAngle } from '../../store/threekitSlicer';
import { PlusIcon, RotateIcon, RotatePlayerWrapper } from './rotatePlayerButton.styles';
import { CLOSE_ICON_BLACK, OPEN_ROTATE_ICON, ROTATE_LEFT, ROTATE_RIGHT } from '../../assets';
import { useAttribute } from '../../utils/threekitHooks';

function RotatePlayerButton() {
  const dispatch = useDispatch();
  const [isRotateOpen, setIsRotateOpen] = useState(false);
  const [rotateAttribute] = useAttribute('Rotate Model');
  const lastAngle = useSelector(getLastAngle);
  const isRotatable = useSelector(getIsRotable);
  const lastAngleRef = useRef(lastAngle);
  const [rotateInterval, setRotateInterval] = useState<ReturnType<typeof setInterval> | null>(null);

  const [rotationStep, rotationMax] = useMemo(
    () => [rotateAttribute?.step || 10, rotateAttribute?.max || 350],
    [rotateAttribute]
  );

  const rotate = useCallback(
    (direction: 'left' | 'right') => {
      const newAngle =
        direction === 'left'
          ? lastAngleRef.current === 0
            ? rotationMax
            : lastAngleRef.current - rotationStep
          : lastAngleRef.current === rotationMax
            ? 0
            : lastAngleRef.current + rotationStep;
      window?.threekit.configurator?.setConfiguration({
        'Rotate Model': newAngle,
      });
      dispatch(setLastAngle(newAngle));
      lastAngleRef.current = newAngle;
    },
    [dispatch, rotationMax, rotationStep]
  );

  const startRotate = useCallback(
    (direction: 'left' | 'right') => {
      const intervalId = setInterval(() => rotate(direction), 30);
      setRotateInterval(intervalId);
    },
    [rotate]
  );

  const stopRotate = useCallback(() => {
    if (rotateInterval) {
      clearInterval(rotateInterval);
      setRotateInterval(null);
    }
  }, [rotateInterval]);

  useEffect(() => {
    lastAngleRef.current = lastAngle;
  }, [lastAngle]);

  return isRotatable && <RotatePlayerWrapper
    onPointerUp={stopRotate}
    onPointerLeave={stopRotate}
    isRotateOpen={isRotateOpen}
  >
    <PlusIcon
      onClick={() => setIsRotateOpen(!isRotateOpen)}
      img={isRotateOpen ? CLOSE_ICON_BLACK : OPEN_ROTATE_ICON}
      isRotateOpen={isRotateOpen}
    />
    <RotateIcon
      show={isRotateOpen}
      img={ROTATE_LEFT}
      onPointerDown={() => startRotate('left')}
    />
    <RotateIcon
      show={isRotateOpen}
      img={ROTATE_RIGHT}
      onPointerDown={() => startRotate('right')}
    />
  </RotatePlayerWrapper>
}

export default RotatePlayerButton;
