import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer/selectors';
import {
  Container,
  ProductSKU,
  InnerContainer,
  ProductName,
  Wrapper,
  ShareAndWish,
} from './TopSection.styles';
import {
  CATALOG_DESKTOP_APPNAME,
  PRODUCT_NAME_MAPPING,
  TK_SAVED_CONFIG_PARAM_KEY,
} from '../../utils/constants';
import ProductPrice from '../ProductPrice';
import { useProductName } from '../../hooks';
import { WishButton, CopyToClipboardButton, ShareButton } from '../Buttons';

const TopSection = ({ summaryPage }: any) => {
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const {
    appName,
    sku = [],
    recipeId,
    [TK_SAVED_CONFIG_PARAM_KEY]: threekitID,
  } = globalSettingsParams || {};
  const productName = useProductName();
  const isCatalogDesktopApp = appName === CATALOG_DESKTOP_APPNAME;
  const skus = Array.isArray(sku) ? sku : [sku];

  return (
    <Container>
      <InnerContainer>
        <Wrapper>
          <div className="rigthSide">
            <ProductSKU>
              {skus[0]}
              {isCatalogDesktopApp && (
                <CopyToClipboardButton buttonText={skus[0]} />
              )}
            </ProductSKU>
            {isCatalogDesktopApp && (
              <>
                <ProductSKU>
                  {recipeId || 'receipeID'}
                  <CopyToClipboardButton buttonText={recipeId || 'receipeID'} />
                </ProductSKU>
                <ProductSKU>
                  {'All Skus'}
                  <CopyToClipboardButton buttonText={skus.join(';')} />
                </ProductSKU>
              </>
            )}
          </div>
          <ShareAndWish>
            <ShareButton />
            <WishButton />
          </ShareAndWish>
        </Wrapper>
        <ProductName>{t(`label.productname.${PRODUCT_NAME_MAPPING?.[productName || productName?.toLowerCase()]}`, productName)}</ProductName>
        <ProductPrice />
      </InnerContainer>
    </Container>
  );
};

export default TopSection;
