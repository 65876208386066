import { useEffect, useMemo, useState } from 'react';
import { ProductImgLoader } from '../SummaryPage.styles';
import { HOME_CONTAINER, MAIN_ATTACHMENT_KEY, PICTURE_FORMAT } from '../../../utils/constants';
import { PICTURE_PLACEHOLDER, PLAYER_GREY_BACKGROUND } from '../../../assets';
import { constructImageUrl } from '../../../utils/function/functions';
import { AssetsSlider } from '../../../components';

export const ProductImage = ({ productImages }: { productImages: Record<string, any> }) => {
  const [imagesState, setImagesState] = useState<
    Record<string, { imgToShow: string; loaded: boolean; error: boolean }>
  >({});

  useEffect(() => {
    if (productImages && Object.keys(productImages)?.length) {
      setImagesState(
        Object.keys(productImages)?.reduce((acc, key) => {
          return {
            ...acc,
            [key]: {
              imgToShow: constructImageUrl(productImages[key], PICTURE_FORMAT),
              loaded: imagesState?.[key]?.loaded || false,
              error: imagesState?.[key]?.error || false,
            },
          };
        }, {})
      );
    }
    else {
      setImagesState({ [MAIN_ATTACHMENT_KEY]: { imgToShow: PICTURE_PLACEHOLDER, loaded: true, error: false } });
    }
  }, [productImages]);

  const handleImageLoad = (img: string) => {
    setImagesState({ ...imagesState, [img]: { ...imagesState[img], loaded: true } });
  };

  const handleImageError = (img: string) => {
    setImagesState({ ...imagesState, [img]: { ...imagesState[img], imgToShow: PICTURE_PLACEHOLDER, error: true } });
  };

  const isLoading = useMemo(() => Object.values(imagesState)?.every((img) => !img?.loaded), [imagesState]);

  return (
    <ProductImgLoader isLoading={isLoading} bgImg={PLAYER_GREY_BACKGROUND}>
      <AssetsSlider
        assets={imagesState}
        progressBar={!isLoading}
        isArrows={false}
        handleImageError={handleImageError}
        handleImageLoad={handleImageLoad}
        sortByKey={MAIN_ATTACHMENT_KEY}
        scrollDownDesktop
        parentSliderElId={HOME_CONTAINER}
      />
    </ProductImgLoader>
  );
};

export default ProductImage;
