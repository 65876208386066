import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AssetContainer,
  AssetsContainer,
  CloseIconWrapper,
  LeftArrow,
  ProgressBarContainer,
  RightArrow,
  SliderContainer,
} from './assestsSlider.styled';
import ProgressBar from '../ProgressBar';
import { useWindowSize } from '../../utils/threekitHooks';
import { LEFT_ARROW_SLIDER_BLACK, RIGHT_ARROW_SLIDER_BLACK } from '../../assets';
import CloseIcon from '../CloseIcon';
import { useDoubleTap } from '../../hooks';

interface AssetsSliderProps {
  parentSliderElId?: string;
  assets: Record<string, { imgToShow: string; loaded: boolean; error: boolean }>;
  progressBar?: boolean;
  isArrows?: boolean;
  handleImageLoad?: (img: string) => void;
  handleImageError?: (img: string) => void;
  sortByKey?: string;
  scrollDownDesktop?: boolean;
}

function AssetsSlider({
  parentSliderElId = '',
  assets,
  progressBar,
  isArrows,
  handleImageLoad,
  handleImageError,
  sortByKey,
  scrollDownDesktop,
}: AssetsSliderProps) {
  const MIN_DISTANCE_TO_SCROLL = 10;
  const { isMobile, isTouch, isDesktop } = useWindowSize();
  const handleDoubleTap = useDoubleTap();
  const sliderRef = useRef<HTMLDivElement>(null);
  const parentSliderEl = document.getElementById(parentSliderElId);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startScroll, setStartScroll] = useState(null);
  const [currentMovePosition, setCurrentMovePosition] = useState<number>(0);
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);
  const sliderChildren = sliderRef?.current?.children;

  const isSlideView = useMemo(() => isMobile, [isMobile]);

  const sortedAssets = useMemo(
    () =>
      Object.entries(assets)
        .sort((a, b) => {
          if (sortByKey) {
            return a[0] === sortByKey ? -1 : b[0] === sortByKey ? 1 : 0;
          }
          return 0;
        })
        .filter(([_, value]) => value.imgToShow !== ''),
    [assets, sortByKey]
  );

  const scrollToIndex = useCallback(
    (index: number, behavior = 'smooth' as ScrollBehavior) => {
      const assetsEl = sliderChildren as unknown as HTMLDivElement[];
      if (!assetsEl || !assetsEl?.[0]) return;
      const assetWidth = assetsEl?.[0]?.offsetWidth;
      const sliderWidth = sliderRef?.current?.offsetWidth || 0;
      const assetsPosition = [...assetsEl].map(
        (asset) => asset?.offsetLeft - (sliderWidth - assetWidth) / 2
      );
      sliderRef?.current?.scrollTo({
        left: assetsPosition[index],
        behavior: behavior,
      });
      setCurrentIndex(index);
    },
    [sliderRef, sliderChildren]
  );

  const scrollToPrevIndex = useCallback(() => {
    scrollToIndex(currentIndex === 0 ? 0 : currentIndex - 1);
  }, [currentIndex, scrollToIndex]);

  const scrollToNextIndex = useCallback(() => {
    scrollToIndex(
      currentIndex === Object.values(sortedAssets).length - 1
        ? Object.values(sortedAssets).length - 1
        : currentIndex + 1
    );
  }, [currentIndex, sortedAssets, scrollToIndex]);

  const handleTouchStart = useCallback((e: any) => {
    setStartScroll(e.touches[0].clientX);
    setCurrentMovePosition(e.touches[0].clientX);
  }, []);

  const handleTouchMove = useCallback(
    (e: any) => {
      if (!startScroll || !sliderRef?.current) return;
      const distance = currentMovePosition - e.touches[0].clientX;
      sliderRef.current.scrollLeft = sliderRef.current.scrollLeft + distance;
      setCurrentMovePosition(e.touches[0].clientX);
    },
    [startScroll, sliderRef, currentMovePosition]
  );

  const handleTouchEnd = useCallback(
    (e: any) => {
      if (!startScroll) return;
      const distance = startScroll - e.changedTouches[0].clientX;
      if (distance >= MIN_DISTANCE_TO_SCROLL) {
        scrollToNextIndex();
      } else if (distance <= -MIN_DISTANCE_TO_SCROLL) {
        scrollToPrevIndex();
      }
      setStartScroll(null);
      setCurrentMovePosition(0);
    },
    [startScroll, setCurrentMovePosition, scrollToNextIndex, scrollToPrevIndex]
  );

  const handleCloseFullScreen = useCallback(() => {
    if (!scrollDownDesktop) scrollToIndex(0, 'instant');
    else {
      setTimeout(() => {
        if (!isSlideView && scrollDownDesktop && sliderChildren) {
          const currentIndexPosition = (sliderChildren?.[currentIndex] as HTMLElement)
            ?.offsetTop;
          if (parentSliderEl) {
            const parentSliderElPosition = parentSliderEl?.offsetTop;
            const currentIndexPositionWithParent =
              currentIndexPosition - parentSliderElPosition;
            parentSliderEl?.scrollTo({
              top: currentIndexPositionWithParent,
              behavior: 'instant',
            });
          }
          sliderRef?.current?.scrollTo({
            top: currentIndexPosition,
            behavior: 'instant',
          });
        } else {
          scrollToIndex(currentIndex, 'instant');
        }
      }, 10);
    }
    setIsFullScreenOpen(false);
  }, [
    sliderRef,
    currentIndex,
    isSlideView,
    scrollDownDesktop,
    sliderChildren,
    scrollToIndex,
    parentSliderEl,
  ]);

  // when screen size changes scroll to the current index
  useEffect(() => {
    setTimeout(() => scrollToIndex(isSlideView ? currentIndex : 0, 'instant'), 100);
    if (isSlideView) handleCloseFullScreen();
  }, [isMobile, isSlideView]);

  return (
    <SliderContainer
      onTouchStart={isTouch ? handleTouchStart : () => {}}
      onTouchEnd={isTouch ? handleTouchEnd : () => {}}
      onTouchMove={isTouch ? handleTouchMove : () => {}}
      isFullScreenOpen={isFullScreenOpen}
      isSlideView={isSlideView}
      className={isFullScreenOpen ? 'scale-and-fade-in' : 'scale-out'}
    >
      {isFullScreenOpen && (
        <CloseIconWrapper onClick={handleCloseFullScreen}>
          <CloseIcon css={{ position: 'static', width: '12px', height: 'auto' }} />
        </CloseIconWrapper>
      )}
      {(isArrows || isFullScreenOpen) && currentIndex > 0 && (
        <LeftArrow onClick={scrollToPrevIndex}>
          <img src={LEFT_ARROW_SLIDER_BLACK} alt="left arrow" />
        </LeftArrow>
      )}
      <AssetsContainer
        ref={sliderRef}
        isSlideView={isSlideView}
        scrollDownDesktop={scrollDownDesktop}
        isFullScreenOpen={isFullScreenOpen}
      >
        {sortedAssets?.map(([assetName, assetObj], index) => (
          <AssetContainer
            key={index}
            onClick={
              isFullScreenOpen
                ? isTouch
                  ? () => handleDoubleTap(handleCloseFullScreen)
                  : () => {}
              : !isTouch || isDesktop
                ? () => {
                    setIsFullScreenOpen(true);
                    setTimeout(() => scrollToIndex(index, 'instant'), 10);
                  }
                : () =>
                    handleDoubleTap(() => {
                      setIsFullScreenOpen(true);
                      setTimeout(() => scrollToIndex(index, 'instant'), 10);
                    })
            }
            isFullScreenOpen={isFullScreenOpen}
            isSlideView={isSlideView}
          >
            <img
              src={assetObj.imgToShow}
              alt="product"
              onLoad={() => handleImageLoad && handleImageLoad(assetName)}
              onError={() => handleImageError && handleImageError(assetName)}
            />
          </AssetContainer>
        ))}
      </AssetsContainer>
      {(isArrows || isFullScreenOpen) && currentIndex < sortedAssets?.length - 1 && (
        <RightArrow onClick={scrollToNextIndex}>
          <img src={RIGHT_ARROW_SLIDER_BLACK} alt="right arrow" />
        </RightArrow>
      )}
      {(isSlideView || isFullScreenOpen) &&
        progressBar &&
        sliderRef?.current &&
        sliderRef?.current?.scrollWidth > sliderRef?.current?.offsetWidth && (
          <ProgressBarContainer>
            <ProgressBar
              containerRef={sliderRef}
              isUseChildren
              currentIndex={currentIndex}
              barWidth={isMobile ? '100vw' : 'max(200px, 80%)'}
            />
          </ProgressBarContainer>
        )}
    </SliderContainer>
  );
}

export default AssetsSlider;
