import { ContainerToBeRemoved, ProductInfosContainer, ProductName } from '../Home.styles';
import ProductPrice from '../../../components/ProductPrice';
import { useProductName } from '../../../hooks';
import AutoDisappearElement from './AutoDisappearElement';
import { useWindowSize } from '../../../utils/threekitHooks';
import { useSelector } from 'react-redux';
import { getIsRetailApp } from '../../../store/globalSettingsSlicer';
import { getIsFullScreen } from '../../../store/threekitSlicer';
import { ZoomButton } from '../../../components/Buttons';
import { t } from 'i18next';
import { PRODUCT_NAME_MAPPING } from '../../../utils/constants';

export const ProductInfos = () => {
  const { isMobile } = useWindowSize();
  const productName: string = useProductName();
  const isRetailApp = useSelector(getIsRetailApp);
  const isFullScreen = useSelector(getIsFullScreen);

  return (
    <ContainerToBeRemoved >
      <ProductInfosContainer>
        <AutoDisappearElement>
          <ProductName>{t(`label.productname.${PRODUCT_NAME_MAPPING?.[productName || productName?.toLowerCase()]}`, productName)}</ProductName>
        </AutoDisappearElement>
        <ProductPrice />
      </ProductInfosContainer>
    </ContainerToBeRemoved>
  );
};

export default ProductInfos;
