import React, { useCallback, useEffect, useState } from 'react';
import { Wrapper } from './progressBar.styled';

function ProgressBar({ containerRef, isUseChildren, barWidth = '90vw', barHeight = '2px', currentIndex }: {
  containerRef: React.RefObject<HTMLDivElement>;
  isUseChildren: boolean;
  barWidth?: string;
  barHeight?: string;
  currentIndex: number;
}) {
  const [progressWidth, setProgressWidth] = useState('0%');
  const container = containerRef.current;

  const updateProgress = useCallback(() => {
    let progress;
    if (isUseChildren) {
      const containerChildrenLength = container?.children?.length || 0;
      progress = ((currentIndex + 1) / containerChildrenLength) * 100;
    } else {
      const containerWidth = container?.scrollWidth || 0;
      const displayWidth = container?.offsetWidth || 0;
      if (containerWidth <= displayWidth) {
        progress = 100;
      } else {
        const scrollLeft = container?.scrollLeft || 0;
        progress = (scrollLeft / (containerWidth - displayWidth)) * 100;
      }
    }
    setProgressWidth(progress + '%');
  }, [container, currentIndex, isUseChildren]);

  useEffect(() => {
    updateProgress();
    container?.addEventListener('scroll', updateProgress);

    return () => {
      container?.removeEventListener('scroll', updateProgress);
    };
  }, [currentIndex, container, isUseChildren, updateProgress]);

  return <Wrapper progressPercents={progressWidth} barWidth={barWidth} barHeight={barHeight} isUseChildren={isUseChildren} />;
}

export default ProgressBar;
