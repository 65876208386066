import { ButtonContainer } from '../buttons.style';
import { MODIFY_BUTTON_LABEL } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { useCallback } from 'react';
import { getInitialConfiguration, setConfiguration, setPage } from '../../../store/threekitSlicer';
import { closeRecap } from '../../../store/flowSlicer';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { useProductName } from '../../../hooks';
import { PRODUCT_NAME_MAPPING } from '../../../utils/constants';

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

function ModifyButton() {
  const dispatch = useDispatch<AppThunkDispatch>();
  const initialConfig = useSelector(getInitialConfiguration);
  const productName = useProductName();

  const handleEdit = useCallback((): void => {
    dispatch(setPage('home'));
    dispatch(setConfiguration(initialConfig));
    dispatch(closeRecap(true));
  }, [dispatch, initialConfig]);

  return (
    <>
      <ButtonContainer buttonName={MODIFY_BUTTON_LABEL} data-dtname={MODIFY_BUTTON_LABEL} onClick={handleEdit}>
        {t('button.label.edit', { defaultValue: MODIFY_BUTTON_LABEL }) + ` ${t(`label.productname.${PRODUCT_NAME_MAPPING?.[productName || productName?.toLowerCase()]}`, productName)}`}
      </ButtonContainer>
    </>
  );
}

export default ModifyButton;
