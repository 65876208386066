import { useSelector } from "react-redux";
import { getProductType } from "../../store/threekitSlicer";
import { useMemo } from "react";
import { PRODUCT_TYPES } from "../../utils/constants";

export const useProductType = () => {
  const productType = useSelector(getProductType);

  const isCapucines = useMemo(() => productType === PRODUCT_TYPES.CAPUCINES, [productType]);
  const productNameForTranslations = useMemo(() => isCapucines ? "capucines" : "lvi", [isCapucines]);

  return { isCapucines, productNameForTranslations }
};

export default useProductType;
