import styled from 'styled-components';

export const SelectedValuesContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['page'].includes(prop),
})<{page?: 'configurator' | 'summary'}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page='configurator',
  }) => `
  display: flex;
  flex-wrap: wrap;
  // white-space: pre;
  min-height: ${page === 'configurator' ? '32px' : ''};
`);

export const SelectedValue = styled.div
  .attrs({
    className: 'SelectedValue',
  })
  .withConfig({
    shouldForwardProp: (prop) => !['inStock', 'page'].includes(prop),
  })<{ inStock: boolean, page?: 'configurator' | 'summary' }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    inStock,
    page='configurator',
  }) => {
    return `
  display: flex;
  align-items: center;
  font-size: ${page === 'summary' ? '13px' : '14px'};
  font-family: ${page === 'summary' ? 'LouisVuitton_Medium' : ''};
  line-height: 16px;
  color: ${page === 'configurator' ? !inStock ? '#C53929' : '#767676' : '#5E5A56'};
  text-align:  ${page === 'configurator' ? 'start' :'end'};
  // text-wrap: nowrap;
  ${
    !inStock && page==='configurator'
      ? `&::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #C53929;
        margin-right: 0.2rem;
  }`
      : ''
  }
`;
  }
);
