import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getActiveGroup, getIsInStock, getScannedSkus } from '../../store/threekitSlicer';
import { useAttributes, useWindowSize } from '../../utils/threekitHooks';
import {
  Arrow,
  AttributeValue,
  AttributeValuesContainer,
  Filter,
  FiltersValuesWrapper,
  FiltersWrapper,
} from './attributeValues.styles';
import { useDispatch, useSelector } from 'react-redux';
import { isChooseValue, scanAction } from '../../utils/function/functions';
import { SCAN_ICON } from '../../assets';
import { getParams } from '../../utils/function/navigationParams';
import {
  JOURNEYIOS_APPNAME,
  LANDSCAPE_FORM_BASE_HEIGHT_IN_PX,
  PORTRAIT_FORM_BASE_HEIGHT_IN_PX,
} from '../../utils/constants';
import { setFormHeight } from '../../store/flowSlicer';
import { LEFT_ARROW_SLIDER_BLACK, RIGHT_ARROW_SLIDER_BLACK } from '../../assets';
import { useProductType, useSlider } from '../../hooks';
import { showPopUp } from '../PopUp/ShowPopUp';
import { popUpTypes } from '../PopUp/PopUpType';
import { t } from 'i18next';

function AttributeValues({ attributeNames, selectedValues, groupName }: { attributeNames: string[]; selectedValues: any[]; groupName: string }) {
  const { isMobile, isTouch, width } = useWindowSize();
  const dispatch = useDispatch();
  const maxSelections = attributeNames.length;
  const attributesObject = useAttributes(attributeNames);
  const inStockData = useSelector(getIsInStock);
  const { appName } = getParams();
  const scannedSkus = useSelector(getScannedSkus) || [];
  const data = {
    message: 'scanProductSkus',
    mustScan: false, // it's a configuration mode, the app has to select components, not only scan
    numberOfComponents: attributeNames.length, // the number of skus to be configured (ex: 1 for the bag, 3 for the charms, etc)
    canSelectMultipleTimes: true, // true if we can add several times the same sku, false otherwise
    skus: [],
  };
  const activeGroup = useSelector(getActiveGroup);
  const filtersEl = useRef<HTMLDivElement>(null);
  const valuesScrollRef = useRef<HTMLUListElement>(null);
  const { isCapucines } = useProductType();
  const [nextIndexToSelect, setNextIndexToSelect] = useState<number>(0);

  const isValueInStock = useCallback(
    (value: any, count = 1) => isChooseValue(value) || Number(inStockData?.[value?.sku]?.qty_available_to_order) >= count,
    [inStockData]
  );

  const firstAttribute =
    window?.dataDrivenConfiguratorExtension?.getStatus().validAttributesAndTheirValues_typeB[0].name;

  const values = useMemo(
    () => attributesObject?.[attributeNames?.[0]]?.data?.values?.filter((v: any) => !isChooseValue(v)) || [],
    [attributesObject, attributeNames]
  );

  const filters = useMemo(
    () => Array.from(new Set<string>(values?.map((value: any) => value?.metadata?.filter)?.filter((v: any) => v))),
    [values]
  );

  const [activeFilter, setActiveFilter] = useState(filters[0] || '');

  useEffect(() => {
    const baseDefaultHeight = isMobile ? PORTRAIT_FORM_BASE_HEIGHT_IN_PX : LANDSCAPE_FORM_BASE_HEIGHT_IN_PX;
    if (!activeGroup) {
      dispatch(setFormHeight(baseDefaultHeight));
      return;
    }
    if (activeGroup !== groupName) return;
    if (filters?.length && filtersEl.current) {
      dispatch(setFormHeight(baseDefaultHeight + filtersEl.current.clientHeight + 8));
    } else {
      dispatch(setFormHeight(baseDefaultHeight));
    }
  }, [filters, activeGroup, attributeNames, dispatch, isMobile, groupName]);

  const filteredOrderedValues = useMemo(() => {
    return !isCapucines
      ? values
        .sort((a: any, b: any) => (isValueInStock(a) === isValueInStock(b) ? 0 : isValueInStock(a) ? -1 : 1))
        .filter((value: any) => activeFilter === '' || value?.metadata?.filter === activeFilter)
      : isCapucines
        ? maxSelections === 1
          ? values
            .sort((a: any, b: any) => (isValueInStock(a) === isValueInStock(b) ? 0 : isValueInStock(a) ? -1 : 1))
            .filter((value: any) => activeFilter === '' || value?.metadata?.filter === activeFilter)
          : values.filter((value: any) => activeFilter === '' || value?.metadata?.filter === activeFilter)
        : values;
  }, [values, maxSelections, activeFilter, isCapucines, isValueInStock]);

  const { hasHorizontalScroll, scrollStart, scrollEnd, startScrolling, stopScrolling } = useSlider(valuesScrollRef, {
    dependencies: [width, filteredOrderedValues?.length],
  });

  useEffect(() => {
    if (valuesScrollRef.current) {
      valuesScrollRef.current.scrollLeft = 0;
    }
  }, [activeFilter]);

  const notSelectedAttributes = useMemo(
    () =>
      Object.keys(attributesObject).filter(
        (attributeName) =>
          attributesObject[attributeName].data?.values?.find((value: any) => value?.selected && isChooseValue(value))
      ),
    [attributesObject]
  );

  const changeAllValues = useCallback(
    (values: any[]) => {
      attributesObject.changeAll(
        values.reduce((acc: Record<string, string>, selectedValue, index) => {
          acc[attributeNames[index]] = selectedValue?.assetId || '';
          return acc;
        }, {})
      );
    },
    [attributesObject, attributeNames]
  );

  const handleClick = useCallback(
    (value: any) => {
      const showOutOfStockPopUp = () => showPopUp({ popUpType: popUpTypes.outOfStock, img: value?.thumbnailPath });

      const inStock = isValueInStock(value);
      const indexAlreadySelected = selectedValues.findIndex(
        (selectedValue) => selectedValue?.assetId === value?.assetId && !isChooseValue(value)
      );

      if (maxSelections === 1) {
        const attributeName = attributeNames[0];
        const newValue = indexAlreadySelected > -1 ? '' : value;
        attributesObject[attributeName]?.change(newValue?.assetId || '');
        if (!inStock && indexAlreadySelected === -1) {
          showOutOfStockPopUp();
        }
      } else {
        if (!isCapucines && indexAlreadySelected > -1) {
          const newSelectedValues = [...selectedValues].map((selectedValue) =>
            selectedValue?.assetId === value?.assetId || isChooseValue(selectedValue) ? '' : selectedValue
          );
          changeAllValues(newSelectedValues);
        } else if (notSelectedAttributes.length) {
          const notSelectedAttribute = notSelectedAttributes[0];
          attributesObject[notSelectedAttribute].change(value?.assetId);
          setNextIndexToSelect((nextIndexToSelect + 1) % 3);
          if (!inStock) {
            showOutOfStockPopUp();
          }
        } else {
          const newSelectedValues = isCapucines
            ? selectedValues.map((v, index) => (index === nextIndexToSelect ? value : v))
            : [...selectedValues.slice(0, -1), value];
          changeAllValues(newSelectedValues);
          setNextIndexToSelect((nextIndexToSelect + 1) % 3);
          if (!inStock) {
            showOutOfStockPopUp();
          }
        }
      }
    },
    [
      attributeNames,
      attributesObject,
      notSelectedAttributes,
      selectedValues,
      maxSelections,
      changeAllValues,
      isCapucines,
      nextIndexToSelect,
      isValueInStock
    ]
  );

  return (
    <FiltersValuesWrapper>
      {filters?.length > 1 ? (
        <FiltersWrapper countFilters={filters?.length || 0} ref={filtersEl}>
          {filters.map((filter) => (
            <Filter
              key={filter}
              onClick={() => setActiveFilter(filter)}
              isActive={filter === activeFilter}
            >
              {t(filter, filter?.split('.')?.pop()?.replace(/^./, str => str.toUpperCase()) || filter)}
            </Filter>
          ))}
        </FiltersWrapper>
      ) : null}
      <AttributeValuesContainer ref={valuesScrollRef}>
        {appName === JOURNEYIOS_APPNAME && (
          <AttributeValue
            style={{ backgroundSize: 'inherit', width: '100px', height: '100px' }}
            key={'scan'}
            isSelected={false}
            inStock={true}
            onClick={() => scanAction(true, data, attributeNames, scannedSkus)}
            img={SCAN_ICON}
            count={0}
          >
            <img src={SCAN_ICON} alt={'scan'} />
          </AttributeValue>
        )}

        {filteredOrderedValues.map((value: any) => {
          const isSelected = selectedValues.some(
            (selectedValue) => selectedValue?.assetId && selectedValue.assetId === value?.assetId
          );
          const countSelected = selectedValues.filter(
            (selectedValue) => selectedValue?.assetId === value?.assetId && !isChooseValue(value)
          )?.length;
          const inStock = isValueInStock(value, countSelected || 1);

          return value?.thumbnailPath ? (
            <AttributeValue
              key={value?.name || value?.label}
              isSelected={isSelected}
              inStock={inStock}
              aria-pressed={isSelected}
              aria-live={isSelected ? 'polite' : 'off'}
              onClick={() => handleClick(value)}
              img={value?.thumbnailPath || ''}
              count={isCapucines ? maxSelections > 1 ? countSelected : 0 : 0}
            >
              <img src={value?.thumbnailPath || ''} alt={value?.name} />
            </AttributeValue>
          ) : null;
        })}
        {hasHorizontalScroll && (
          <>
            <Arrow
              style={{ display: `${scrollStart ? 'none' : ''}` }}
              img={LEFT_ARROW_SLIDER_BLACK}
              direction="left"
              onMouseDown={() => startScrolling(-1)}
              onMouseUp={stopScrolling}
              onMouseLeave={stopScrolling}
              onTouchStart={isTouch ? () => startScrolling(-1) : () => { }}
              onTouchEnd={isTouch ? stopScrolling : () => { }}
            />
            <Arrow
              style={{ display: `${scrollEnd ? 'none' : ''}` }}
              img={RIGHT_ARROW_SLIDER_BLACK}
              direction="right"
              onMouseDown={() => startScrolling(1)}
              onMouseUp={stopScrolling}
              onMouseLeave={stopScrolling}
              onTouchStart={isTouch ? () => startScrolling(1) : () => { }}
              onTouchEnd={isTouch ? stopScrolling : () => { }}
            />
          </>
        )}
      </AttributeValuesContainer>
    </FiltersValuesWrapper>
  );
}

export default AttributeValues;
