import { DOT_ACTIVE, DOT_INACTIVE } from '../../assets';
import styled from 'styled-components';

export const ViewDotsContainer = styled.div.withConfig({})(
  ({
    theme: {
      device: { isDesktop },
    },
  }) => {
    return `display: flex;
      gap: 10px;
      position: absolute;
      bottom: 5%;
      z-index:500;
      left: 50%;
      transform: translate(-50%, -50%);`;
  }
);

interface IViewDot {
  isActive: boolean;
}

export const ViewDot = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<IViewDot>(
  ({
    theme: {
      device: { isDesktop },
    },
    isActive,
  }) => {
    return `
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: ${isActive ? '#000' : '#A6A6A6'};
      cursor: pointer;
     
  `;
  }
);

export const ArrowContainer = styled.div.withConfig({})(
  ({
    theme: {
      device: { isDesktop },
    },
  }) => {
    return `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 999999;
  `;
  }
);

export const Arrow = styled.div.withConfig({
  shouldForwardProp: (prop) => !['disable'].includes(prop),
})<{ disable: boolean }>`
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props: any) => (props.disable ? '0' : '1')};
  cursor: ${(props: any) => (props.disable ? 'initial' : 'pointer')};

  > img {
    height: 16px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid black;
  }
`;
