import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as flowReducers from './reducers';
import { LANDSCAPE_FORM_BASE_HEIGHT_IN_PX, PORTRAIT_FORM_BASE_HEIGHT_IN_PX } from '../../utils/constants';

export interface FlowState {
  helper: {
    displayHelper: boolean;
  };

  recap: {
    displayRecap: boolean;
    recapOpenPercentage: number;
  };

  form: {
    height: number;
  }
  activeSurpriseMe:boolean;
  playerView: string;
}

const isPortrait = window.innerHeight > window.innerWidth;

const initialState: FlowState = {
  helper: {
    displayHelper: false,
  },
  recap: {
    displayRecap: false,
    recapOpenPercentage: 0,
  },
  form: {
    height: isPortrait ? PORTRAIT_FORM_BASE_HEIGHT_IN_PX : LANDSCAPE_FORM_BASE_HEIGHT_IN_PX,
  },
  activeSurpriseMe:true,
  playerView: 'default',
};

type FlowReducers = {
  [K in keyof typeof flowReducers]: (
    state: FlowState,
    action: PayloadAction<any>
  ) => void;
};

const flowSlice = createSlice({
  name: 'flowSlice',
  initialState,
  reducers: flowReducers as unknown as FlowReducers,
});

export const {
  setDisplayRecap,
  setRecapOpenPercentage,
  setDisplayHelper,
  openRecap,
  closeRecap,
  setFormHeight,
  setActiveSurpriseMe,
  setPlayerView,
} = flowSlice.actions;

export default flowSlice.reducer;

export * from './reducers';
export * from './selectors';
