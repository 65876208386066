import { getIsActionFired } from './../../store/threekitSlicer/selectors/playerSettings';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsAllSelectedValuesInStock,
  getIsInStock,
  getPage,
  setActiveGroup,
  setConfiguration,
  setInitialConfiguration,
  setPage,
} from '../../store/threekitSlicer';
import { useThreekitInitStatus } from '../../utils/threekitHooks';
import { RootState } from '../../store';
import { getParams } from '../../utils/function/navigationParams';
import { NO_RECAP_APP, TK_SAVED_CONFIG_PARAM_KEY } from '../../utils/constants';
import { PopUpType, showPopUp } from '../../components/PopUp/ShowPopUp';
import { popUpTypes } from '../../components/PopUp/PopUpType';
import useSavedConfiguration from '../useSavedConfiguration';
import { isEmptyObj } from '../../utils/function/functions';
import { getSavedConfigurationData } from '../../store/threekitSlicer/selectors/savedConfigurationData';

function useThreekitIdConfiguration(isThreekitLaunch: boolean) {
  type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
  const dispatch = useDispatch<AppThunkDispatch>();
  const [popupSent, setPopupSent] = useState(false);
  const isLoaded = useThreekitInitStatus();
  const isAllSelectedSkusAvailable = useSelector(getIsAllSelectedValuesInStock);
  const inStockData = useSelector(getIsInStock);
  const params = getParams();
  const isActionFired = useSelector(getIsActionFired);
  const page = useSelector(getPage);
  const { recipeId, page: pageParam } = params || { recipeId: '', page: '' };
  const threekitID = params?.[TK_SAVED_CONFIG_PARAM_KEY] || '';
  const {
    savedConfigurationData,
    isLoadingSavedConfigurationData,
    isErrorSavedConfigurationData,
    errorSavedConfigurationData,
  } = useSavedConfiguration(recipeId ? recipeId : threekitID);
  const savedConfigurationFromStore = useSelector(getSavedConfigurationData);

  const [savedConfiguration, isConfigurationExists] = useMemo(
    () => [
      savedConfigurationFromStore?.configuration || savedConfigurationData?.configuration || {},
      !isEmptyObj(savedConfigurationFromStore?.configuration || savedConfigurationData?.configuration || {}),
    ],
    [savedConfigurationData, savedConfigurationFromStore]
  );

  useEffect(() => {
    const openPDPRecap = () => {
      dispatch(setPage(''));
      dispatch(setActiveGroup(''));
    };
    if (isConfigurationExists && isLoaded && !isActionFired && isThreekitLaunch) {
      if (
        !NO_RECAP_APP.some((element) => element === params?.appName)
      ) {
        openPDPRecap();
        dispatch(setInitialConfiguration(savedConfiguration));
        setTimeout(() => {
          dispatch(setConfiguration(savedConfiguration));
        }, 500);
      }else{
        dispatch(setInitialConfiguration(savedConfiguration));
        setTimeout(() => {
          dispatch(setConfiguration(savedConfiguration));
        }, 500);
      }
    }
  }, [isConfigurationExists, dispatch, savedConfiguration, isLoaded, isActionFired, isThreekitLaunch]);



  useEffect(() => {
    if (
      !popupSent &&
      !isEmptyObj(inStockData) &&
      isAllSelectedSkusAvailable === false &&
      isLoaded &&
      page === '' &&
      ((threekitID || recipeId) &&
      pageParam !== 'configurator')
    ) {
      showPopUp({
        popUpType: popUpTypes.outOfStock as PopUpType,
      });
      setPopupSent(true);
    }
  }, [inStockData, isLoaded, isAllSelectedSkusAvailable, page, popupSent, threekitID, recipeId, pageParam]);

  return { savedConfigurationData };
}

export default useThreekitIdConfiguration;
