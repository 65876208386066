import React, { useCallback } from 'react'
import { useSelector } from 'react-redux';
import { getIsInStock } from '../../store/threekitSlicer';
import { isChooseValue, isEmptyObj } from '../../utils/function/functions';

const useValuesStock = () => {
  const inStockData = useSelector(getIsInStock);

  // Gets a list of values and check if they are in stock as a group!
  // This function does not care of other values, but only the values passed in the list
  const isSkusInStock = useCallback(
    ({ valuesToCheck }: { valuesToCheck: any[] }) => {
      if (isEmptyObj(inStockData)) return [];
      let updatingStock = { ...inStockData };
      return valuesToCheck?.map((value: any) => {
        const sku = value?.sku;
        if (!isChooseValue(value)) {
          const qtyAvailable = updatingStock[sku]?.qty_available_to_order;
          if (qtyAvailable > 0) {
            updatingStock[sku] = { qty_available_to_order: qtyAvailable - 1 };
            return true;
          } else return false;
        }
        return true;
      });
    },
    [inStockData]
  );

  return isSkusInStock;
}

export default useValuesStock