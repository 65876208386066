import { RefObject, useCallback, useEffect, useRef, useState } from "react";

function useSlider(valuesScrollRef: RefObject<HTMLDivElement | HTMLUListElement>, { dependencies }: { dependencies: any[] }) {
  const scrollAnimationFrame = useRef<number | null>(null);
  const [valuesScrollObj, setValuesScrollObj] = useState({
    hasHorizontalScroll: false,
    scrollStart: true,
    scrollEnd: true,
  });

  const updateValuesScrollObj = useCallback(() => {
    const element = valuesScrollRef.current;
    if (element) {
      const hasHorizontalScroll = element.scrollWidth > element.clientWidth;
      const scrollStart = element.scrollLeft === 0;
      const scrollEnd = element.scrollWidth - element.clientWidth === element.scrollLeft;

      console.log('updation to new: ', { hasHorizontalScroll, scrollStart, scrollEnd });

      setValuesScrollObj({ hasHorizontalScroll, scrollStart, scrollEnd });
    }
  }, [valuesScrollRef]);

  useEffect(() => {
    const element = valuesScrollRef.current;
    if (element) {
      updateValuesScrollObj();
      const handleScroll = () => {
        updateValuesScrollObj();
      };
      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, [updateValuesScrollObj, valuesScrollRef, ...dependencies]);

  const startScrolling = useCallback((direction: number) => {
    const scroll = () => {
      const element = valuesScrollRef.current;
      if (element) {
        element.scrollBy({ left: direction * 5 });
        scrollAnimationFrame.current = requestAnimationFrame(scroll);
        updateValuesScrollObj();
      }
    };
    scrollAnimationFrame.current = requestAnimationFrame(scroll);
  }, [updateValuesScrollObj, valuesScrollRef]);

  const stopScrolling = useCallback(() => {
    if (scrollAnimationFrame.current !== null) {
      cancelAnimationFrame(scrollAnimationFrame.current);
      scrollAnimationFrame.current = null;
    }
  }, []);

  return {
    ...valuesScrollObj,
    startScrolling,
    stopScrolling,
  };
}

export default useSlider;
