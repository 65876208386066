import styled from 'styled-components';

const overlappingCirclePX = 14;

export const AttributeCircleContainer = styled.li
  .attrs({ className: 'AttributeCircleContainer' })
  .withConfig({
    shouldForwardProp: (props) => !['isActive'].includes(props),
  })<{ isActive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;

  @media (orientation: landscape) {
    gap: 5%;
    max-width: unset;
    margin: 0 auto;
  }
`;

export const CirclesContainer = styled.div
.withConfig({
  shouldForwardProp: (props) => !['countCircles'].includes(props),
})<{ countCircles: number }>`
  display: flex;
  position: relative;
  left: ${(props) => `${(props.countCircles - 1) * overlappingCirclePX / 2}px`};
  flex-grow: 1;
  align-items: center;
`;

export const CircleContainer = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    !['img', 'isSelected', 'isTutorialCurrentStep', 'countCircles', 'index'].includes(prop),
})<{
  img: any;
  isSelected: boolean;
  isTutorialCurrentStep: boolean;
  countCircles: number;
  index: number;
}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    img,
    isSelected,
    isTutorialCurrentStep,
    countCircles,
    index,
  }) => {
    return `
      position: relative;
      right: ${(index * overlappingCirclePX)}px;
      z-index: ${countCircles - index};
      width: 100%;
      max-width: 60px;
      background-color: #FFF;
      background-image: url(${img});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: ${isSelected ? '#F5F5F5' : ''};
      border: none;
      border-radius: 50%;
      border: ${isSelected && countCircles > 1 ? '1px solid #E5E5E5;' : 'none'};
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1/1;
      cursor: pointer;
      height: max-content;
      padding: 0;
            

      @media (orientation: landscape) {
        width: unset;
      }
`;
  }
);

export const CircleImage = styled.div.withConfig({
  shouldForwardProp: (prop) => !['inStock'].includes(prop),
})<{
  inStock: boolean;
}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    inStock,
  }) => {
    return `
  border-radius: 50%;
  border: none;
  width: 100%;
  height: 100%;
  &::before {
    content: '';
    position: absolute;
    top: 3%;
    right: 5%;
    width: 9px;
    height: 9px;
    aspect-ratio: 1/1;
    background-color: ${inStock ? 'transparent' : '#C53929'};
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
`;
  }
);

export const Image = styled.img`
  visibility: hidden;
  width: 100%;
  @media (orientation: landscape) {
    width: 40px;
    height: 40px;
  }
`;

export const AttributeName = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isTutorialCurrentStep', 'isActive'].includes(prop),
})<{ isTutorialCurrentStep: boolean; isActive: boolean }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    isTutorialCurrentStep,
    isActive,
  }) => {
    return `
    font-size: 14px;
    font-weight: 500;
    color: ${isTutorialCurrentStep ? '#FFFFFF' : '#000000'};
    text-align: center;
    text-wrap: balance;
 
    @media (orientation: landscape) {
      text-wrap: nowrap;
      margin-bottom: 21px;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 1px;
        background-color: black;
        transition: width 0.3s ease;
        ${isActive && `width: 100%;`}
      }
    }
`;
  }
);
