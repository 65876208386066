import { Container, ContentWrapper, ImagesWrapper, WrapperContainer } from './SummaryPage.styles';
import TopSection from '../../components/TopSection/TopSection';
import { usePageTitle, useProductType } from '../../hooks';
import ProductImage from './components/ProductImage';
import { SummaryButtons } from '../../components/SummaryButtons';
import { getSavedConfigurationData } from '../../store/threekitSlicer/selectors/savedConfigurationData';
import { useSelector } from 'react-redux';
import { fitSkusDataToOrderButtons, OrderButtons } from '../../components/OrderButtons';
import { useQuery } from '@tanstack/react-query';
import { fetchSkusData } from '../../utils/ApiCalls/ApiCalls';
import { useMemo } from 'react';
import { isEmptyObj } from '../../utils/function/functions';
import { getConfigVariables } from '../../store/globalSettingsSlicer';

const SummaryPage = () => {
  usePageTitle({ page: 'summary' });
  const savedConfigurationData = useSelector(getSavedConfigurationData);
  const { isCapucines } = useProductType();
  const configVariables = useSelector(getConfigVariables);
  const { orgId = '', threekitProductEnv = '' } = configVariables || {};
  const skusList = window?.dataDrivenConfiguratorExtension?.getStatus()?.skus;

  const queryKey = useMemo(() => ['getSkuData', ...skusList], [skusList]);
  const {
    data: skusData = [],
    isLoading: isLoadingSkusData,
    isError: isErrorSkusData,
  } = useQuery({
    queryKey,
    queryFn: () => fetchSkusData({ skus: skusList }),
    staleTime: 300000,
    refetchOnWindowFocus: false,
    enabled: !!skusList?.length,
    retry: false,
  });

  const skuDataForOrderButtons = useMemo(() => fitSkusDataToOrderButtons({ skusData, isCapucines }), [skusData]);
  const baseURL = `${threekitProductEnv}/api/configurations/${savedConfigurationData?.recipeId}/image`;
  const productImagesSaved = !isEmptyObj(savedConfigurationData?.attachments) ?
    savedConfigurationData?.attachments : {
      front: `${baseURL}/Front?orgId=${orgId}`,
      side: `${baseURL}/Side?orgId=${orgId}`,
      focus_on_strap: `${baseURL}/Focus_on_strap?orgId=${orgId}`,
      zoom_on_charms: `${baseURL}/Zoom_on_charms?orgId=${orgId}`,
    };

  return (
    <Container>
      <ImagesWrapper>
        <ProductImage productImages={productImagesSaved || null} />
      </ImagesWrapper>
      <ContentWrapper>
        <WrapperContainer>
          <TopSection summaryPage />
          <SummaryButtons />
          <OrderButtons data={skuDataForOrderButtons} isLoadingSkusData={isLoadingSkusData} />
          {/* <ConfigurationSummary page="summary" /> */}
        </WrapperContainer>
      </ContentWrapper>
    </Container>
  );
};

export default SummaryPage;
