import React from 'react';
import {
  BackgroundWrapper,
  BesideAttributesContainer,
  BottomFormContainer,
  FormContainer,
  IconsWrapper,
  SelectionSummaryIcon,
  TitlePriceContainer,
  TopFormContainer,
} from './landscapeForm.styles';
import Attributes from '../../../components/Attributes';
import ActiveAttributeTitle from '../../../components/ActiveAttributeTitle';
import { SUMMARY_ICON } from '../../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupedAttributes } from '../../../store/threekitSlicer';
import SelectedValuesOfAttribute from '../../../components/SelectedValuesOfAttribute';
import { closeRecap, getActiveSurpriseMe, getDisplayRecap, getFormHeight, openRecap } from '../../../store/flowSlicer';
import RecapPage from '../../RecapPage';
import FunctionalButtons from '../../../components/FunctionalButtons';
import ProductInfos from './ProductInfos';
import BottomContainer from './BottomContainer';
import { useActiveGroup } from '../../../hooks';

interface LandscapeFormProps {}

const LandscapeForm: React.FC<LandscapeFormProps> = () => {
  const dispatch = useDispatch();
  const groupedAttributes = useSelector(getGroupedAttributes) || {};
  const { selectedValues } = useActiveGroup();
  const displayRecap = useSelector(getDisplayRecap);
  const isActiveAnimation = useSelector(getActiveSurpriseMe)
  const formHeight = useSelector(getFormHeight);

  return (
    <FormContainer isActiveAnimation={isActiveAnimation} style={{height: formHeight}}>
      <ProductInfos />
      <TopFormContainer>
        <BesideAttributesContainer>
          <IconsWrapper>
            <SelectionSummaryIcon
              img={SUMMARY_ICON}
              onClick={() => {
                dispatch(openRecap(true));
              }}
            />
          </IconsWrapper>
          <TitlePriceContainer>
            <ActiveAttributeTitle />
            <SelectedValuesOfAttribute selectedValues={selectedValues} page='configurator' />
          </TitlePriceContainer>
        </BesideAttributesContainer>
        <Attributes />
        <FunctionalButtons />
      </TopFormContainer>
      <BottomFormContainer style={{height: formHeight}}>
        {Object.entries(groupedAttributes).map(([groupName, attributes], idx) => {
          return (
            <BottomContainer
              key={`${idx}-bottom-container`}
              attributes={attributes}
              groupName={groupName}
            />
          );
        })}
      </BottomFormContainer>
      <RecapPage show={displayRecap} />
      <BackgroundWrapper show={displayRecap} onClick={() => dispatch(closeRecap(true))} />
    </FormContainer>
  );
};

export default LandscapeForm;
