import { IAttribute, getRecievedScannedSkus, setConfiguration } from '../../store/threekitSlicer';
import {
  ActiveAttributeSelectorContainer,
  CloseButton,
  IconsWrapper,
  ValuesContainer,
} from './activeAttributeSelector.styles';
import AttributeValues from '../AttributeValues';
import ActiveAttributeTitle from '../ActiveAttributeTitle';
import SelectedValuesOfAttribute from '../SelectedValuesOfAttribute';
import CloseIcon from '../CloseIcon';
import { useEffect, useMemo, useState } from 'react';
import { isChooseValue } from '../../utils/function/functions';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getActiveSurpriseMe, getFormHeight } from '../../store/flowSlicer';
import { t } from 'i18next';

function ActiveAttributeSelector({
  attributes,
  isActive,
  selectedValues,
  closeAttribute,
  groupName,
}: {
  attributes: IAttribute[];
  isActive: boolean;
  selectedValues: any[];
  closeAttribute: () => void;
  groupName: string;
}) {
  type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

  const formHeight = useSelector(getFormHeight);
  const isAllSelected = useMemo(() => selectedValues?.every((v) => !isChooseValue(v)), [selectedValues]);
  const recentSkus = useSelector(getRecievedScannedSkus) || [];
  const [initValues, setInitValues] = useState<Array<any>>([]);
  const dispatch = useDispatch<AppThunkDispatch>();
  const isActiveAnimation = useSelector(getActiveSurpriseMe);
  useEffect(() => {
    if (isActive) {
      setInitValues(selectedValues || []);
    }
  }, [isActive]);

  const createConfigurationObject = (attributeName: string, assetId: string) => ({
    [attributeName]: assetId ? { assetId } : {},
  });
  const setAttributeConfiguration = (attribute: IAttribute, initialValue: any) => {
    const attributeName = attribute.name;
    const attributeAssetId = initialValue?.assetId;
    return createConfigurationObject(attributeName, attributeAssetId);
  };
  const checkAttributeDeselected = (attributes: IAttribute[]) => {
    if (attributes?.length !== initValues?.length) return false;
    let isAttributeDeselected = false;
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      const initValue = initValues[i];
      const attributeValue = attribute?.values?.find((v: any) => v?.assetId === attribute?.value?.assetId);
      if (isChooseValue(attributeValue) && !isChooseValue(initValue)) {
        isAttributeDeselected = true;
        break;
      }
    }
    return isAttributeDeselected;
  };
  const onCloseAttributePopup = () => {
    const isAttributeDeselected = checkAttributeDeselected(attributes);
    let newConfiguration;
    if (attributes.length === 1) {
      const singleAttributeConfig = setAttributeConfiguration(attributes[0], initValues[0]);
      newConfiguration = singleAttributeConfig;
    } else {
      newConfiguration = attributes.reduce((configAccumulator, attribute, index) => {
        const initialAttributeValue = initValues[index];
        return {
          ...configAccumulator,
          ...setAttributeConfiguration(attribute, initialAttributeValue),
        };
      }, {});
    }
    if (!isAttributeDeselected) dispatch(setConfiguration(newConfiguration));
    closeAttribute();
  };

  useEffect(() => {
    if (!isActive) return;
  
    let newConfiguration;
    let initVal;
    if (!recentSkus.length) return;
    if (recentSkus.length === 1) {
      const scannedAssetId = recentSkus[0]?.assetId;
      initVal = { assetId: scannedAssetId };
      const singleAttributeConfig = setAttributeConfiguration(attributes[0], initVal);
      newConfiguration = singleAttributeConfig;
    } else {
      newConfiguration = recentSkus.reduce((configAccumulator: any, item: Record<string, any>, index: number) => {
        initVal = { assetId: item?.assetId };
        const initialAttributeValue = recentSkus[index];
        return {
          ...configAccumulator,
          ...setAttributeConfiguration(attributes[index], initialAttributeValue),
        };
      }, {});
    }
    dispatch(setConfiguration(newConfiguration));

    // closeAttribute();
  }, [recentSkus]);

  return (
    <ActiveAttributeSelectorContainer
      style={{
        bottom: isActive && !isActiveAnimation ? `0px` : '-110%',
        height: `${formHeight}px`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <IconsWrapper>
        <CloseIcon onClick={onCloseAttributePopup} />
      </IconsWrapper>
      <ActiveAttributeTitle />
      <SelectedValuesOfAttribute selectedValues={selectedValues} page='configurator' />
      <ValuesContainer>
        <AttributeValues
          attributeNames={attributes.map((attribute) => attribute.name)}
          selectedValues={selectedValues}
          groupName={groupName}
        />
      </ValuesContainer>
      <CloseButton onClick={closeAttribute} disabled={!isAllSelected}>
        {t('button.label.apply', 'Apply')}
      </CloseButton>
    </ActiveAttributeSelectorContainer>
  );
}

export { ActiveAttributeSelector };
