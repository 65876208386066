import { TouchEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import {
  MainWrapper,
  DataWrapper,
  CloseButton,
  Title,
  Text,
  ButtonsWrapper,
  ButtonTextWrapper,
  ButtonLabel,
  ButtonSubLabel,
  ButtonWrapper,
  ArrowImg,
  ValueImg,
  ValueImagesWrapper
} from './orderButtons.styles';
import {
  CLOSE_ICON_BLACK,
  LEFT_ARROW_SLIDER_BLACK,
  RIGHT_ARROW_SLIDER_BLACK,
} from '../../assets';
import useSwipe from '../../hooks/useSwipe';
import { extractGroupNameFromGroupKey, isEmptyObj } from '../../utils/function/functions';
import { useWindowSize } from '../../utils/threekitHooks';
import LoadingSpinner from '../LoadingSpinner';
import { t } from 'i18next';

export const fitSkusDataToOrderButtons = ({skusData, isCapucines}: {skusData: any[], isCapucines: boolean}) => {
  const mainSku = skusData?.[0];
  const elseSkusData = skusData?.slice(1);
  const validAttributesAndTheirValues: any[] = window?.dataDrivenConfiguratorExtension.getStatus()?.validAttributesAndTheirValues_typeB || [];

  const skusFullDataByGroups: Record<string, any> = {};
  const groups: string[] = [];
  validAttributesAndTheirValues.forEach((validSkuData, index) => {
    const groupName = validSkuData?.groupName ? t(validSkuData?.groupName, extractGroupNameFromGroupKey(validSkuData?.groupName)) : '';
    const groupNameSTR = typeof groupName === 'string' ? groupName : extractGroupNameFromGroupKey(validSkuData?.groupName);
    const itemMergedData = { ...validSkuData?.values?.find((v: any) => v?.selected), ...elseSkusData?.[index] }
    if (groupNameSTR) {
      if (skusFullDataByGroups.hasOwnProperty(groupNameSTR)) {
        skusFullDataByGroups[groupNameSTR].push(itemMergedData)
      } else {
        groups.push(groupNameSTR);
        skusFullDataByGroups[groupNameSTR] = [itemMergedData];
      }
    } else {
      const name = t(validSkuData?.name, validSkuData?.displayName);
      const nameSTR = typeof name === 'string' ? name : validSkuData?.displayName;
      groups.push(nameSTR)
      skusFullDataByGroups[nameSTR] = [itemMergedData];
    }
  });

  const skusToResult: Record<string, any> = {};
  groups.forEach((groupName) => {
    const groupSkusData = skusFullDataByGroups[groupName];
    // const text = groupSkusData?.map((data: any) => data?.shortDescription).join('\n\n-----\n\n');
    const text = groupSkusData[0]?.shortDescription;
    skusToResult[groupName] = {
      label: groupName,
      subLabel: groupSkusData.map((skuData: any) => skuData?.displayName).join(' | '),
      images: isCapucines ? groupSkusData.map((skuData: any) => skuData?.thumbnailPath) : [groupSkusData?.[0]?.thumbnailPath],
      text,
      next: {}
    }
  });

  const result: any = { next: {} };
  result.next.productDetails = {
    label: t('button.label.product_details', { defaultValue: 'Product details' }),
    text: mainSku?.shortDescription || '',
    next: {
      ...skusToResult
    }
  };

  return result;
}

const DataComponent = ({
  button,
  pageNumber,
  handleButtonClick,
  isLoadingSkusData,
}: {
  button: any;
  pageNumber: number;
  handleButtonClick: (button: any) => void;
  isLoadingSkusData: boolean;
}) => {
  const { isMobile } = useWindowSize();

  return (
    <>
      {button?.label && <Title firstPage={pageNumber === 1}>{button.label}{isLoadingSkusData && (<LoadingSpinner />)}</Title>}
      {button?.text && <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(button?.text || '</div>') }}></Text>}
      {button?.next && (
        <ButtonsWrapper>
          {Object.entries(button.next).map(([key, value]: [string, any]) => {
            const isNext = value?.text || !isEmptyObj(value?.next);
            return (
              <ButtonWrapper key={key} onClick={() => (isNext ? handleButtonClick(value) : {})} isClickable={isNext}>
                {value?.images && (
                  <ValueImagesWrapper countCircles={value.images?.length}>
                    {value.images.map((img: string, index: number) => (
                      <ValueImg src={img} alt={value.label} index={index} countCircles={value.images?.length} />
                    ))}
                  </ValueImagesWrapper>
                )}
                <ButtonTextWrapper>
                  <ButtonLabel pageNumber={pageNumber}>{value?.label}</ButtonLabel>
                  {value?.subLabel && <ButtonSubLabel>{value?.subLabel}</ButtonSubLabel>}
                </ButtonTextWrapper>
                {(pageNumber > 0 || isMobile) && isNext && (
                  <ArrowImg src={RIGHT_ARROW_SLIDER_BLACK} alt="right arrow" />
                )}
              </ButtonWrapper>
            );
          })}
        </ButtonsWrapper>
      )}
    </>
  );
};

export const OrderButtons = ({ data, isLoadingSkusData }: { data: any, isLoadingSkusData: boolean }) => {
  // data = buttons;
  const [openedButton, setOpenedButton] = useState<any | null>(data);
  const [prevStack, setPrevStack] = useState<any[]>([data]);
  const wrapperComponent = useRef<HTMLDivElement>(null);
  const openedComponent = useRef<HTMLDivElement>(null);
  const firstPage = useMemo(() => prevStack.length === 2, [prevStack]);
  const opened = useMemo(() => prevStack.length > 1, [prevStack]);

  useEffect(() => {
    let next: any = data;
    const updatedPrevStack = prevStack.map((stack, index) => {
      next = Object.values(next)[0];
      return next;
    });
    setPrevStack(updatedPrevStack);
    setOpenedButton(updatedPrevStack[updatedPrevStack.length - 1] || data);
  }, [data]);

  const slideComponent = useCallback((direction: 'left' | 'right') => {
    if (!openedComponent.current) return;
    openedComponent.current.style.transition = 'none';
    openedComponent.current.style.transform =
      direction === 'right' ? 'translateX(100%)' : 'translateX(-100%)';
    setTimeout(() => {
      if (!openedComponent.current) return;
      openedComponent.current.style.transition = 'transform 0.3s, top 0.3s';
      openedComponent.current.style.transform = 'translateX(0)';
    }, 0);
  }, []);

  const handleButtonClick = useCallback(
    (button: any) => {
      if (opened) slideComponent('right');
      setPrevStack([...prevStack, button]);
      setOpenedButton(button);
    },
    [prevStack, opened, slideComponent]
  );

  const handleClose = useCallback(() => {
    if (!openedComponent.current) return;
    if (prevStack.length > 2) slideComponent('left');
    const prevStackVar = [...prevStack];
    const prevButton = prevStackVar[prevStackVar.length - 2];
    setOpenedButton(prevButton || null);
    setPrevStack(prevStackVar.slice(0, prevStackVar.length - 1));
  }, [prevStack, slideComponent]);

  const { handleTouchStart, handleTouchEnd } = useSwipe(handleClose, () => { }, 100);

  return (
    <>
      <MainWrapper>
        <DataComponent
          button={data}
          handleButtonClick={handleButtonClick}
          pageNumber={0}
          isLoadingSkusData={isLoadingSkusData}
        />
      </MainWrapper>

      <DataWrapper
        open={opened}
        ref={wrapperComponent}
        onTouchStart={handleTouchStart as unknown as TouchEventHandler<HTMLDivElement>}
        onTouchEnd={handleTouchEnd as unknown as TouchEventHandler<HTMLDivElement>}
      >
        <div ref={openedComponent} style={{ position: 'relative' }}>
          <CloseButton
            className={firstPage ? 'close' : 'back'}
            onClick={handleClose}
            src={firstPage ? CLOSE_ICON_BLACK : LEFT_ARROW_SLIDER_BLACK}
          />
          <DataComponent
            button={openedButton}
            pageNumber={prevStack.length - 1}
            handleButtonClick={handleButtonClick}
            isLoadingSkusData={isLoadingSkusData}
          />
        </div>
      </DataWrapper>
    </>
  );
};
