import styled, { css } from 'styled-components';
import {
  ADDTOBAG_BUTTON_LABEL,
  DONE_BUTTON_LABEL,
  SHARE_BUTTON_LABEL,
  ZOOM_BUTTON_LABEL,
  HELPER_BUTTON_LABEL,
  SURPRISE_ME_BUTTON_LABEL,
  MODIFY_BUTTON_LABEL,
  WISH_BUTTON_LABEL,
  COPY_TO_CLIPBOARD_BUTTON_LABEL,
  ITEM_UNVAILABLE_BUTTON_LABEL,
} from './constants';
import { HELPER_ICON } from '../../assets';

const COLORS = {
  black: '#000000',
  white: '#FFFFFF',
  lightGray: '#E6E6E6',
  darkGray: '#2C2C2E',
  darkBrown: '#18110C',
  softWhite: '#F5F5F3',
  mediumGray: '#E1E1E1',
};

interface IDevice {
  isIpad?: boolean;
  isDesktop?: boolean;
  isMobile?: boolean;
  appName?: string;
  isFullScreen?: boolean;
  disabled?: boolean;
  showLoader?: boolean;
}

const FONT_SIZES = {
  small: '14px',
  medium: '17px',
};

const baseButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  color: ${COLORS.black};
  font-size: ${FONT_SIZES.small};
  font-weight: 400;
  background-color: ${COLORS.white};
  padding: 10px 13px;
  font-family: 'LouisVuitton_Regular';
  pointer-events: auto;
`;

const disabledStyle = ({ buttonName }: { buttonName: string }) => css`
  cursor: default;
  ${buttonName !== DONE_BUTTON_LABEL ? `pointer-events: none;` : ''}
  opacity: 0.5;
`;

const responsivePaddingStyle = ({ isIpad, isDesktop }: IDevice) => css`
  ${isIpad || isDesktop ? 'padding: 10px 20px;' : ''}
`;

const addtoBagButtonStyle = ({ isMobile, isIpad, isDesktop, appName, disabled }: IDevice) => css`
  background-color: ${COLORS.darkBrown};
  border: none;
  color: ${COLORS.white};
  height: 48px;
  border-radius: 24px;
  width: 100%;
  text-transform: capitalize;
  font-size: ${FONT_SIZES.small};
  ${!disabled ? `&:hover {
    border: 1px solid ${COLORS.black};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  }` : ''}
`;

const modifyButtonStyle = ({ isMobile }: IDevice) => css`
  border-radius: 100px;
  border: 1px solid transparent;
  width: 100%;
  height: 48px;
  color: ${COLORS.black};
  font-size: ${FONT_SIZES.small};
  font-family: 'LouisVuitton_Medium';
  text-transform: capitalize;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, #d5e47e, #9dcdd9, #e39cbc) border-box;
  &:hover {
      box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
      background:
      linear-gradient(white, white) padding-box,
      linear-gradient(to right, #e39cbc, #9dcdd9, #d5e47e) border-box;
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
`;

const shareButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border: none;
  padding: 0;
  > img {
    width: 16px;
  }
`;
const wishButtonStyle = ({ isMobile, isDesktop, appName }: IDevice) => css`
  border: none;
  padding: 0;
  > img {
    width: 16px;
  }
`;

const doneButtonStyle = ({ isMobile, disabled, showLoader }: IDevice) => css`
  justify-content: center;
  font-size: 13px;
  width: 100%;
  height: ${isMobile ? '48px' : '44px'};
  background-color: ${disabled
    ? COLORS.mediumGray
    : COLORS.darkBrown};
  color: ${(disabled) ? '#5A5A5A' : COLORS.white};
  border-radius: 70px;
  font-size: ${FONT_SIZES.small};
  text-transform: capitalize;
  border: 1px solid transparent;
  ${!disabled && !showLoader ? `&:hover {
    border: 1px solid ${COLORS.black};
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  }` : ''}
`;

const surpriseButtonStyle = ({ isMobile }: IDevice) => css`
  border-radius: 70px;
  border: 1px solid transparent;
  width: 100%;
  height: ${isMobile ? '48px' : '44px'};
  color: ${COLORS.black};
  font-size: ${FONT_SIZES.small};
  font-family: 'LouisVuitton_Regular';
  text-transform: capitalize;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, #00ff47, #00fff0, #6c5eec, #ff00e6) border-box;
  &:hover {
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
    background:
      linear-gradient(white, white) padding-box,
      linear-gradient(to right, #ff00e6, #6c5eec, #00fff0, #00ff47) border-box;
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
`;

const zoomButtonStyle = ({
  isIpad,
  isDesktop,
  appName,
  isMobile,
  isFullScreen,
}: IDevice) => css`
  position: ${isMobile && !isFullScreen ? '' : 'absolute'};
  bottom: ${isFullScreen && isMobile
    ? ''
    : isFullScreen && !isMobile
      ? '3vmin'
      : '18vmin'};
  top: ${isFullScreen && isMobile ? '3vmin' : ''};
  z-index: ${isFullScreen ? 99999999 : ''};
  right: 3vmin;
  padding: 7px;
  border-radius: 100px;
  border: 1px solid ${COLORS.lightGray};
  > img {
    width: 20px;
  }
  ${isMobile
    ? 'margin-left: auto; width: max-content;'
    : isDesktop && appName === 'OOB_APPNAME'
      ? 'border: none; flex-direction: row-reverse;'
      : `border: 1px solid ${COLORS.lightGray};`}
`;

const helpButtonStyle = ({
  isIpad,
  isDesktop,
  appName,
  isMobile,
  isFullScreen,
}: IDevice) => css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${HELPER_ICON});
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  z-index: 1;
  font-size: 0;
  margin-left: auto;
  @media (hover: hover) {
    &:hover {
      background-color: #f0f0f0;
    }
    &:active {
      background-color: #e0e0e0;
    }
  }
`;

const copyButton = () => css`
  padding: 5px;
  border: none;

  img {
    width: 10.5pt;
    height: 10.5pt;
  }
`;
interface ButtonContainerProps {
  theme?: {
    device: { isMobile: boolean; isIpad: boolean; isDesktop: boolean };
    appName: string;
  };
  buttonName?: string;
  summaryPage?: boolean;
  clientPage?: boolean;
  disabled?: boolean;
  isFullScreen?: boolean;
  displayTutorial?: boolean;
  showLoader?: boolean;
}

export const ButtonContainer = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'buttonName',
      'summaryPage',
      'clientPage',
      'disabled',
      'isFullScreen',
      'displayTutorial',
      'showLoader',
    ].includes(prop),
})<ButtonContainerProps>(
  ({
    theme,
    buttonName = '',
    summaryPage,
    disabled,
    isFullScreen,
    showLoader,
  }: ButtonContainerProps) => css`
    ${baseButtonStyle}
    ${disabled ? disabledStyle({ buttonName }) : ''}
    ${responsivePaddingStyle(theme?.device as IDevice)}
    ${buttonName === ADDTOBAG_BUTTON_LABEL &&
    addtoBagButtonStyle({ ...theme?.device, appName: theme?.appName, disabled })}
    ${buttonName === ITEM_UNVAILABLE_BUTTON_LABEL &&
    addtoBagButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === MODIFY_BUTTON_LABEL &&
    modifyButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === SHARE_BUTTON_LABEL &&
    shareButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === WISH_BUTTON_LABEL &&
    wishButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === DONE_BUTTON_LABEL &&
    doneButtonStyle({
      ...theme?.device,
      appName: theme?.appName,
      disabled,
      showLoader,
    })}
    ${buttonName === SURPRISE_ME_BUTTON_LABEL &&
    surpriseButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === HELPER_BUTTON_LABEL &&
    helpButtonStyle({ ...theme?.device, appName: theme?.appName })}
    ${buttonName === ZOOM_BUTTON_LABEL &&
    zoomButtonStyle({
      ...theme?.device,
      appName: theme?.appName,
      isFullScreen,
    })}
    ${buttonName === COPY_TO_CLIPBOARD_BUTTON_LABEL && copyButton()}
  `
);
