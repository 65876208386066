import styled from 'styled-components';

export const MainWrapper = styled.div(
  ({
    theme: {
      device: { isMobile },
    },
  }) => `
  color: #19110b;
  overflow-y: auto;
  background-color: #fff;
  transition: transform 0.3s, height 0.3s;
`
);

export const DataWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['open'].includes(prop),
})<{
  open: boolean;
}>(
  ({
    theme: {
      device: { isMobile },
    },
    open,
  }) => `
  color: #19110b;
  overflow-y: auto;
  background-color: #fff;
  transition: all 0.3s;
  height: 100%;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 100;
  overflow-x: hidden;
  ${open
      ? `
      top: 0;
  `
      : `
    top: 100vh;
    top: 100svh;
  `
    }
  ${!isMobile ? `
    position: absolute;
    padding: min(15%, 100px) 30px;
    ${!open ? `
      height: 0;
      padding: 0;
    ` : ''}
  ` : `
    padding: 10% 20px;
  `}
`
);

export const CloseButton = styled.img(
  ({
    theme: {
      device: { isMobile },
    },
  }) => `
  position: absolute;
  cursor: pointer;
  top: 0;
  &.back {
  left: 0;
  width: 9px;
  }
  &.close {
  right: 0;
  width: 16px;
  }
`
);

export const Title = styled.div.withConfig({
  shouldForwardProp: (prop) => !['firstPage'].includes(prop),
})<{
  firstPage: boolean;
}>(
  ({
    theme: {
      device: { isMobile },
    },
    firstPage,
  }) => `
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 30px;
  margin-left: ${firstPage ? '0' : '20px'};
  display: flex;
  gap: 10px;
`
);

export const Text = styled.div(
  ({
    theme: {
      device: { isMobile },
    },
  }) => `
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
  white-space: break-spaces;
  letter-spacing: 0.4px;
`
);

export const ButtonsWrapper = styled.div(
  ({
    theme: {
      device: { isMobile },
    },
  }) => `
  display: flex;
  flex-direction: column;
  gap: 30px;
`
);

export const ButtonWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isClickable'].includes(prop),
})<{isClickable: boolean}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    isClickable
  }) => `
  display:flex;
  align-items: center;
  gap: 10px;
  cursor: ${isClickable ? 'pointer' : 'default'};
`
);

export const ButtonTextWrapper = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
  }) => `
  display: flex;
  flex-direction: column;
  gap: 5px;
`
);

export const ButtonLabel = styled.div.withConfig({
  shouldForwardProp: (prop) => !['firstPage', 'pageNumber'].includes(prop),
})<{
  pageNumber: number;
}>(
  ({
    theme: {
      device: { isMobile },
    },
    pageNumber,
  }) => `
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  position: relative;

  ${pageNumber === 0
      ? `
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 1px;
    background-color: black;
    transition: width 0.3s ease;
  }
  &:hover::after {
        width: 100%;
    }
  }`
      : ''
    }
`
);

export const ButtonSubLabel = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
  }) => `
  color: #5E5A56;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
`
);

export const ArrowImg = styled.img`
  height: 12px;
  margin-left: auto;
`;


const imageWidth = 40;

export const ValueImagesWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['index'].includes(prop),
})<{countCircles: number  }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    countCircles
  }) => {
    return `
  position: relative;
  display: flex;
  width: ${countCircles > 1 ? imageWidth + imageWidth/countCircles : imageWidth}px;
  left: ${countCircles > 1 ? imageWidth/countCircles : 0}px;
  height: ${imageWidth}px;
  align-items: center;
`;
  });

export const ValueImg = styled.img.withConfig({
  shouldForwardProp: (prop) => !['index'].includes(prop),
})<{index: number; countCircles: number  }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    index,
    countCircles
  }) => {
    return `
  position: absolute;
  width: ${imageWidth}px;
  height: auto;
  border-radius: 50%;
  left: -${(imageWidth/countCircles) * (countCircles - index - 1)}px;
  z-index: ${countCircles - index};
  aspect-ratio: 1/1;
  cursor: pointer;
`;
  })
;
