import { ButtonContainer } from '../buttons.style';
import { HELPER_BUTTON_LABEL } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayHelper, setDisplayHelper } from '../../../store/flowSlicer';


function HelperButton() {
  const dispatch = useDispatch();
  const displayHelper = useSelector(getDisplayHelper);

  return (
    <>
      <ButtonContainer
        buttonName={HELPER_BUTTON_LABEL}
        data-dtname={HELPER_BUTTON_LABEL}
        onClick={() => dispatch(setDisplayHelper(!displayHelper))}
      >
      </ButtonContainer>
      <span className='sr-only'>How to Custome your Lucky Louis</span>
    </>
  );
}

export default HelperButton;
