import styled from 'styled-components';

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isCapucines'].includes(prop),
})<{ isCapucines: boolean }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    isCapucines,
  }) => `
  display: flex;
  gap: ${isCapucines ? '20px' : '10px'};
  min-width: 250px;
  height: fit-content;
  width: ${isMobile ? '100%' : '30%'};
  justify-content: flex-end;
  align-items: center;

  > button {
    @media (orientation: landscape) {
    max-width: 121px;
    }
  }

 ${
   isMobile
     ? `
      justify-content:center; 
       margin-top:auto;`
     : ``
 }  
`
);
