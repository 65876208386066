import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchSkusAvailability, fetchSkusAvailabilityWhenDone, skusAvailabilityResponse, skusAvailabilityWhenDoneResponse } from '../../utils/ApiCalls/ApiCalls';
import { getParams } from '../../utils/function/navigationParams';

export const useStock = ({ skus = [], isEnabled = true, type }: { skus: string[], isEnabled?: boolean, type?: 'done' }) => {
  const params = getParams();
  const { storeCode = '', appName } = params || {};

  const queryKey = useMemo(() => ['productStock', storeCode, ...skus], [storeCode, skus]);

  const skuToQtyArr = useMemo(() => {
    const skuToQty: {
      [key: string]: number;
    } = skus
      ?.filter((el) => el?.length > 4)
      ?.reduce((acc: any, sku) => {
        acc[sku] = (acc[sku] || 0) + 1;
        return acc;
      }, {});

    //   const arr = [];

    //   for (const [key, value] of Object.entries(skuToQty)) {
    //     if (value > 1) {
    //       for (let i = 0; i < value; i++) {
    //         arr.push({ item_id: key, qty: i+1 });
    //       }
    //     } else {
    //       arr.push({ item_id: key, qty: 1 });
    //     }
    //   }

    const arr = Object.entries(skuToQty).map(([sku, qty]) => ({
      item_id: sku,
      qty,
    }));

    return arr;
  }, [skus]);

  const skuToQtyArrDone = useMemo(() =>
    skus?.filter((el) => el?.length > 4)
    , [skus]);

  const queryFunction = useCallback(async () => {
    if (type === 'done') {
      const response = await fetchSkusAvailabilityWhenDone({ items: skuToQtyArrDone, storeCode, appName });
      return response as skusAvailabilityWhenDoneResponse;
    }
    const response = await fetchSkusAvailability({ items: skuToQtyArr, storeCode, appName });
    return response as skusAvailabilityResponse;
  }, [skuToQtyArr, skuToQtyArrDone, storeCode, appName, type]);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: queryKey,
    queryFn: queryFunction,
    staleTime: 300000,
    enabled: skus?.length > 0 && isEnabled,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return { data, isLoading, isError, error };
};

export default useStock;
