import { useMemo } from 'react';
import { PICTURE_PLACEHOLDER, PLUS_ATTRIBUTE_ICON } from '../../assets';
import {
  AttributeCircleContainer,
  AttributeName,
  CircleContainer,
  CircleImage,
  CirclesContainer,
  Image,
} from './attributeCircle.styles';
import { useProductType } from '../../hooks';

interface IAttributeCircle {
  attributeName: string;
  selectedValues: any[];
  isActive: boolean;
  inStock: boolean[];
  isSelected: boolean[];
  isTutorialCurrentStep?: boolean;
  openAttribute: () => void;
}

function AttributeCircle({
  attributeName,
  selectedValues,
  isActive,
  inStock,
  isSelected,
  isTutorialCurrentStep = false,
  openAttribute,
}: IAttributeCircle) {
  const { isCapucines } = useProductType();

  const allValuesSelected = useMemo(() => isSelected.every((isSelected) => isSelected === true), [isSelected]);

  const images = useMemo(() => {
    if (isCapucines) {
      return selectedValues.map(
        (value) => value?.alternateThumbnailPath || value?.thumbnailPath || PLUS_ATTRIBUTE_ICON
      );
    }
    const firstSelectedValue = selectedValues?.find((value) => value?.alternateThumbnailPath || value?.thumbnailPath);
    return allValuesSelected
      ? [firstSelectedValue?.alternateThumbnailPath || firstSelectedValue?.thumbnailPath || PICTURE_PLACEHOLDER]
      : [PLUS_ATTRIBUTE_ICON];
  }, [selectedValues, isCapucines, allValuesSelected]);

  return (
    <AttributeCircleContainer isActive={isActive}>
      <CirclesContainer countCircles={images.length}>
        {images.map((image, index) => (
          <CircleContainer
            key={index}
            countCircles={images.length}
            index={index}
            onClick={openAttribute}
            img={image}
            isSelected={isCapucines ? isSelected?.[index] : allValuesSelected}
            isTutorialCurrentStep={isTutorialCurrentStep}
          >
            <CircleImage
              inStock={isCapucines ? inStock[index] !== false : inStock.every((inStock) => inStock !== false)}
            >
              <Image src={image} />
            </CircleImage>
          </CircleContainer>
        ))}
      </CirclesContainer>
      <AttributeName isTutorialCurrentStep={isTutorialCurrentStep} isActive={isActive}>
        {attributeName}
      </AttributeName>
    </AttributeCircleContainer>
  );
}

export default AttributeCircle;
