import AttributeValues from '../../../components/AttributeValues';
import { useActiveGroup } from '../../../hooks';
import { ValuesContainer } from './landscapeForm.styles';

interface BottomContainerProps {
  attributes: any;
  groupName: string;
}
const BottomContainer: React.FC<BottomContainerProps> = (props) => {
  const { attributes, groupName } = props;
  const { activeGroup, selectedValues } = useActiveGroup();
  return (
    <ValuesContainer
      key={groupName}
      style={{
        opacity: activeGroup === groupName ? '1' : '0',
        zIndex: activeGroup === groupName ? '1' : '-1',
        transform:
          activeGroup === groupName ? 'translateY(0)' : 'translateY(100%)',
      }}
    >
      <AttributeValues
        attributeNames={attributes.map((attribute: any) => attribute.name)}
        selectedValues={selectedValues}
        groupName={groupName}
      />
    </ValuesContainer>
  );
};

export default BottomContainer;
