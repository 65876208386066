import { popUpTypes } from '../../components/PopUp/PopUpType';
import { showPopUp } from '../../components/PopUp/ShowPopUp';
import { apiRequest } from '../../services/request';
import { DEFAULT_SERVER_URL, IS_LOCALHOST } from '../constants';
import { getParams } from '../function/navigationParams';

//NOT SURE - need to check how the data is coming in
interface LeadTimeResponse {
  distribution_leadtime?: {
    min: number;
    max: number;
  };
  min: number;
  max: number;
}
interface FetchStockQuantityParams {
  storeCode: string;
  sku: string;
  isMobile: boolean;
}

interface StockQuantityResponse {
  totalQuantity: number;
}

interface FetchPriceParams {
  storeCode: string | undefined;
  sku: string | undefined;
  country: string | undefined;
  mode: string | undefined;
  isMobile: boolean;
}

interface PriceSpecification {
  priceCurrency: string;
  price: number;
}

interface SkuItem {
  sku: string;
  priceSpecification: PriceSpecification;
}

interface PriceResponse {
  priceData: {
    price_value: number | null;
    currency: string;
  };
  price_value: number | null;
  currency: string;
}

interface GetProductIdParams {
  sku: string | undefined;
}

interface GetSavedConfigParams {
  recipeId: string;
}

interface CheckTextPurityParams {
  text: string;
  lng: string;
  isMobile: boolean;
}

interface CheckTextResponse {
  valid: boolean;
}

export interface skusAvailabilityResponse {
  items: Record<
    string, { qty_available_to_order: number; }
  >;
  leadTime: Record<string, number>;
  allSkusAvailable: boolean;
}

export interface skusAvailabilityWhenDoneResponse {
  items: {
    under_validation: string;
    timestamp: string;
    sourcing_location_type: string;
    score: string;
    launch_date: string | null;
    group_items: string[];
    fulfillment_type: string[];
    distribution_leadtime: Record<string, number>;
    available_to_order: 'N' | 'Y';
  }[];
}

function timeoutPromise(milliseconds: number): Promise<never> {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out'));
    }, milliseconds);
  });
}

const { sku, appName, token, timestamp } = getParams() || {};
const baseURL = IS_LOCALHOST ? DEFAULT_SERVER_URL : `https://${window.location.host}`;

export const fetchConfigVariables = async (): Promise<any> => {
  try {
    const data = await apiRequest({
      method: 'GET',
      path: 'getConfigVariables',
    });
    return data || {};
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    return Promise.reject(errorMessage);
  }
};

export const fetchStockQuantity = async ({
  storeCode,
  sku,
  isMobile,
}: FetchStockQuantityParams): Promise<StockQuantityResponse> => {
  try {
    const data = await apiRequest({
      method: 'GET',
      path: 'getStockQuantity',
      params: {
        storeCode: String(storeCode),
        sku: String(sku),
      },
      timeout: 20000,
    });

    return data || { totalQuantity: 0 };
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.quantityError });
    return Promise.reject(errorMessage);
  }
};

export const fetchPrice = async ({
  storeCode,
  sku,
  country,
  isMobile,
  mode
}: FetchPriceParams): Promise<PriceResponse | undefined> => {
  try {
    const countryProp: string = country !== undefined ? country : 'fr';
    const queryParams = new URLSearchParams({
      storeCode: String(storeCode),
      sku: String(sku),
      country: countryProp,
      appName: String(appName),
      timestamp: String(timestamp),
      token: String(token),
      mode: String(mode)
    }).toString();
    const response = await fetch(`${baseURL}/api/getPrice?${queryParams}`);

    // if (!response.ok) {
    //   const errorResponse = await response.json();
    //   throw new Error(
    //     errorResponse.error || `HTTP error! status: ${response.status}`
    //   );
    // }

    const data = await response.json();

    return data.priceData || { price_value: null, currency: '' };
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.priceError });
    return Promise.reject(errorMessage);
  }
};

export const getCatalogProductBySku = async ({ sku }: GetProductIdParams) => {
  try {
    const data = await apiRequest({
      method: 'GET',
      path: 'getCatalogProductBySku',
      params: {
        sku: String(sku),
      },
    });

    return data || {};
  } catch (error) {
    console.error('Error:', error.message);
    return Promise.reject(error.message);
  }
};

type savedConfig = {
  attachments?: any;
  configuration?: any;
  skus?: string[];
  lvCustomData?: any;
};

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getSavedConfig = async ({
  recipeId
}: GetSavedConfigParams): Promise<savedConfig> => {
  try {
    // await sleep(3000);

    const data = await apiRequest({
      method: 'GET',
      path: 'getCustomConfiguration',
      params: {
        recipeId: String(recipeId),
      },
    });

    if (!data || typeof data !== 'object') {
      throw new Error('Invalid JSON data in response');
    }

    return data || {};
  } catch (error) {
    const errorMessage = (error as Error)?.message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.getConfigurationError });
    return Promise.reject(errorMessage);
  }
};

export const checkTextPurity = async ({
  text,
  lng,
  isMobile,
}: CheckTextPurityParams): Promise<CheckTextResponse> => {
  try {
    const data = await apiRequest({
      method: 'GET',
      path: 'checkText',
      params: {
        text: String(text),
        lang: String(lng),
      },
    });

    if (data?.found === '1') {
      return { valid: false };
    } else {
      return { valid: true };
    }
  } catch (error) {
    const errorMessage = (error as Error).message;
    console.error('Error:', errorMessage);
    showPopUp({ popUpType: popUpTypes.webPurifyError });
    return Promise.reject(errorMessage);
  }
};

export const fetchSkusAvailability = async ({
  items,
  storeCode,
  appName = '',
}: {
  items: { item_id: string; qty: number }[];
  storeCode: string;
  appName?: string;
}): Promise<skusAvailabilityResponse> => {
  try {
    const data = await apiRequest({
      method: 'POST',
      path: 'getSkusAvailability',
      params: {
        storeCode,
        appName,
      },
      body: {
        items,
      }
    });
    return data;
  } catch (error) {
    console.error('Error:', error);
    return Promise.reject(error);
  }
};

export const fetchSkusAvailabilityWhenDone = async ({
  items,
  storeCode,
  appName = '',
}: {
  items: string[];
  storeCode: string;
  appName?: string;
}): Promise<skusAvailabilityWhenDoneResponse> => {
  try {
    const data = await apiRequest({
      method: 'GET',
      path: 'getSkusAvailabilityWhenDone',
      params: {
        items: JSON.stringify(items),
        storeCode,
        appName,
      },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchSkusData = async ({
  skus,
}: {
  skus: string[];
}) => {
  try {
    // await new Promise(resolve => setTimeout(resolve, 7000));
    const data = await apiRequest({
      method: 'GET',
      path: 'getSkusData',
      params: { skus },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
