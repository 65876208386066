import { ButtonContainer } from '../buttons.style';
import { ITEM_UNVAILABLE_BUTTON_LABEL } from '../constants';

interface ItemUnavailableButtonProps {
  disable?: boolean;
}

function ItemUnavailableButton({ disable = false }: ItemUnavailableButtonProps) {
  return (
    <>
      <ButtonContainer
        buttonName={ITEM_UNVAILABLE_BUTTON_LABEL}
        data-dtname={ITEM_UNVAILABLE_BUTTON_LABEL}
        disabled={disable}
      >
        {ITEM_UNVAILABLE_BUTTON_LABEL}
      </ButtonContainer>
    </>
  );
}

export default ItemUnavailableButton;
