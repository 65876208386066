import React, { useEffect, useRef } from 'react';
import {
  AboveFormContainer,
  FormContainer,
  FormContentWrapper,
  SlideBar,
  SlideBarContainer,
} from './portraitForm.styles';
import Attributes from '../../../components/Attributes';
import useSliderBar from '../../../hooks/useSliderBar';
import {
  closeRecap,
  getActiveSurpriseMe,
  getDisplayRecap,
  getFormHeight,
  getRecapOpenPercentage,
  openRecap,
  setRecapOpenPercentage,
} from '../../../store/flowSlicer';
import { useDispatch, useSelector } from 'react-redux';
import RecapPage from '../../RecapPage';
import FunctionalButtons from '../../../components/FunctionalButtons';
import {
  PORTRAIT_FORM_MAX_HEIGHT_IN_PX,
  PORTRAIT_FORM_SLIDE_PERCENTS_TO_CLOSE,
  PORTRAIT_FORM_SLIDE_PERCENTS_TO_OPEN,
} from '../../../utils/constants';
import ProductInfos from './ProductInfos';
import { ResetButton } from '../../../components/Buttons';
import { useProductType } from '../../../hooks';

interface PortraitFormProps {}

const PortraitForm: React.FC<PortraitFormProps> = () => {
  const dispatch = useDispatch();
  const displayRecap = useSelector(getDisplayRecap);
  const formHeight = useSelector(getFormHeight);
  const { isCapucines } = useProductType();
  const formRef = useRef<HTMLDivElement>(null);
  const isActiveAnimation = useSelector(getActiveSurpriseMe);
  const [slidePercentsToOpenRecap, setSlidePercentsToOpenRecap] = React.useState(
    displayRecap ? PORTRAIT_FORM_SLIDE_PERCENTS_TO_CLOSE : PORTRAIT_FORM_SLIDE_PERCENTS_TO_OPEN
  );
  const { handleTouchStart, handleTouchMove, handleTouchEnd, openPercentage } = useSliderBar({
    onSlideUp: () => {
      dispatch(openRecap(true));
    },
    onSlideDown: () => {
      dispatch(closeRecap(true));
    },
    baseHeightInPX: formHeight,
    maxHeightInPX: PORTRAIT_FORM_MAX_HEIGHT_IN_PX,
    dragElementRef: formRef,
    slidePercentsToOpen: slidePercentsToOpenRecap,
  });
  const recapOpenPercentage = useSelector(getRecapOpenPercentage);

  useEffect(() => {
    dispatch(setRecapOpenPercentage(openPercentage));
  }, [openPercentage, dispatch]);

  useEffect(() => {
    setSlidePercentsToOpenRecap(
      displayRecap ? PORTRAIT_FORM_SLIDE_PERCENTS_TO_CLOSE : PORTRAIT_FORM_SLIDE_PERCENTS_TO_OPEN
    );
  }, [displayRecap]);
  return (
    <FormContainer
      ref={formRef}
      recap={displayRecap}
      baseHeightInVH={formHeight}
      maxHeightInVH={PORTRAIT_FORM_MAX_HEIGHT_IN_PX}
      isActiveAnimation={isActiveAnimation}
    >
      <RecapPage
        show={displayRecap}
        slidePercentsToOpen={slidePercentsToOpenRecap}
        handleTouchStart={handleTouchStart}
        handleTouchMove={handleTouchMove}
        handleTouchEnd={handleTouchEnd}
        handleCloseRecap={() => {
          formRef.current?.scrollTo(0, 0);
          dispatch(closeRecap(false));
        }}
      />
      <FormContentWrapper openPercentage={recapOpenPercentage} slidePercentsToClose={slidePercentsToOpenRecap}>
        <SlideBarContainer onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
          <SlideBar />
        </SlideBarContainer>
        <AboveFormContainer>
          <ProductInfos />
          {isCapucines && <ResetButton all />}
        </AboveFormContainer>
        <Attributes />
        <FunctionalButtons />
      </FormContentWrapper>
    </FormContainer>
  );
};

export default PortraitForm;
