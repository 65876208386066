import { PRICE_PLACEHOLDER } from '../../utils/constants';
import currencyArray from '../currency.json';
import i18next from 'i18next';

export const getPriceWithCurrency = (price: {
  price_value: number | null;
  currency: string;
}): string => {
  if (!price?.price_value || !price?.currency) {
    return i18next.t('product.label.missing_price', PRICE_PLACEHOLDER);
  }
  //@ts-ignore
  const currency = currencyArray[price?.currency] || price?.currency || '';
  return `${price?.price_value || ''} ${currency}`;
};

export const calculateLeadtime = ({
  min,
  max,
}: {
  min: number;
  max: number;
}): {
  unit: string | null;
  value: string;
} => {
  let unit, value, newMin, newMax;
  newMin = min;
  newMax = max;

  if (min <= 0 && max <= 0) return { unit: null, value: '' };

  if (min < 7) {
    unit = 'days';
  } else {
    unit = 'weeks';
    newMin = Math.floor(min / 7);
    newMax = Math.floor(max / 7);
  }

  if (newMin === newMax) {
    value = `${newMin}`;
  } else {
    value = `${newMin}/${newMax}`;
  }

  return { unit, value };
};
