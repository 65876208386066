import React, { useMemo } from 'react';
import {
  SelectedValue,
  SelectedValuesContainer,
} from './selectedValuesOfAttribute.styles';
import { isChooseValue } from '../../utils/function/functions';
import { t } from 'i18next';
import { useValuesStock } from '../../hooks';

function SelectedValuesOfAttribute({ selectedValues, page='configurator' }: { selectedValues: any[], page?: 'configurator' | 'summary' }) {
  const isSkusInStock = useValuesStock();
  const isSelectedValuesInStock = useMemo(() => isSkusInStock({valuesToCheck: selectedValues}), [isSkusInStock, selectedValues]);


  return (
    <SelectedValuesContainer page={page}>
      {selectedValues?.map((value, i) => {
        const inStock = isSelectedValuesInStock?.[i] === true;
        return (
          <SelectedValue key={`selectedValue-${value?.displayName}-${i}`} inStock={inStock} page={page}>
            {!isChooseValue(value) && (
              <>
                {t(value?.displayName)}
                <span style={{ color: '#767676' }}>{i !== selectedValues.length - 1 && '  |  '}</span>
              </>
            )}
          </SelectedValue>
        );
      })}
    </SelectedValuesContainer>
  );
}

export default SelectedValuesOfAttribute;
