import styled from 'styled-components';

export const ConfigurationWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['page'].includes(prop),
})<{ page?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ page }) => (page === 'recap' ? '25px' : '0')};
  height: -webkit-fill-available;
`;

export const AttributeWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['page'].includes(prop),
})<{page: string}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page
  }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  ${page === 'recap' ? `
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  &:last-child {
    border-bottom: none;
  }` : ''}
`
);

export const AttributeTitle = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['page', 'inStock'].includes(prop),
})<{page?: string, inStock: boolean}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page, inStock
  }) => `
  font-size: 14px;
  text-wrap: nowrap;
  min-width: 22%;
  margin-right: auto;
  display: flex;
  color: ${page === 'summary' ? '#19110B' : !inStock ? '#C53929' : '#000'};
  ${page === 'recap' && !inStock ? `
    &::after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #C53929;
      margin: 1px 0 0 2px;
      align-self: flex-start;
    }
    ` : ''}
`
);

export const AttributeValues = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['page'].includes(prop),
})<{page: string}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page
  }) => `
  display: flex;
  gap: 10px;
  align-items:center;
  max-width: 72%;
`);

export const ValueWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => ![''].includes(prop),
})<{  }>`
  display: flex;
  align-items: center;
`;

export const Value = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isEmpty'].includes(prop),
})<{ isEmpty?: boolean }>`
  font-size: ${({ isEmpty }) => (isEmpty ? '20px' : '14px')};
  margin: ${({ isEmpty }) => (isEmpty ? '0' : '5px')};
  color: ${({ isEmpty }) => (isEmpty ? '#999999' : '')};
  text-align: end;
`;

export const ValueImgWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isEmpty', 'page'].includes(prop),
})<{ isEmpty?: boolean, page?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isEmpty, page }) => (page === 'summary' ? '40px' : isEmpty ? '33px' : '32px')};
  height: ${({ isEmpty, page }) => (page === 'summary' ? 'auto' : isEmpty ? '33px' : '32px')};
`;

export const ValueImg = styled.img.withConfig({
  shouldForwardProp: (prop) => !['isEmpty', 'page', 'isAlternateThumbnailPath', 'img'].includes(prop),
})<{ page?: string, isAlternateThumbnailPath?: boolean }>`
  width: ${({ page, isAlternateThumbnailPath }) =>
    page === 'summary' ? '40px' : isAlternateThumbnailPath ? '10px' : '100%'};
  height: ${({ page, isAlternateThumbnailPath }) =>
    page === 'summary' ? '40px' : isAlternateThumbnailPath ? '10px' : '100%'};
  border-radius: 50%;
`;

export const PlusContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['img'].includes(prop),
})<{
  img: any;
}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    img,
  }) => {
    return `
  width: 100%;
  max-width: 2rem;
  background-image: url(${img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  cursor: pointer;
  @media (orientation: landscape) {
    width: unset;
    max-height: 60%;
  }
`;
  }
);

export const PlusImage = styled.img`
  visibility: hidden;
  width: 100%;
  @media (orientation: landscape) {
    width: unset;
    height: 100%;
  }
`;

export const ImgTextWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['page'].includes(prop),
})<{page: string}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    page
  }) => `
  display: flex;
  gap: 10px;
  flex: 1;
  ${page === 'recap' ? `
  cursor: pointer;` : ''}
`);

export const TitleValueWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
