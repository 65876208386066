import { createSlice } from '@reduxjs/toolkit';
import * as globalSettingsReducers from './reducers';
import { TK_SAVED_CONFIG_PARAM_KEY } from '../../utils/constants';

export interface IGlobalSettingsState {
  step: number;
  isRetailApp: boolean;
  configVariables: any;
  params: globalSettingsReducers.IParams;
}

const initialState: IGlobalSettingsState = {
  step: -1,
  isRetailApp: false,
  configVariables: {},
  params: {
    sku: '',
    appName: '',
    storeCode: '',
    configId: '',
    lng: '',
    country: '',
    productName: '',
    [TK_SAVED_CONFIG_PARAM_KEY]: '',
    mode: '',
  },
};

const globalSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState,
  reducers: {
    ...globalSettingsReducers,
  },
});

export const {
  setGlobalSettings,
  setGlobalSettingsParams,
  setStep,
  setConfigVariables,
  setIsRetailApp
} =
  globalSettingsSlice.actions;
export default globalSettingsSlice.reducer;

export * from './reducers';
export * from './selectors';
