/*****************************************************
 * Dev-Kit URL
 ****************************************************/

export const DEFAULT_SERVER_URL = `http://localhost:5000`;
export const DATATABLE_API_ROUTE = `/api/datatables`;
export const CONFIGURATIONS_API_ROUTE = `/api/configurations`;
export const CATALOG_API_ROUTE = `/api/catalog`;
export const FILES_API_ROUTE = `/api/files`;
export const ORDERS_API_ROUTE = '/api/orders';
export const ORG_API_ROUTE = '/api/orgs';
export const PRODUCTS_API_ROUTE = `/api/products`;
export const SERVER_API_ROUTE = '/api';
export const TK_SCRIPT_PATH = 'app/js/threekit-player-noconf.js';

/*****************************************************
 * Dev-Kit CSS Class
 ****************************************************/

export const DEFAULT_CLASS_NAME = 'threekit-react';
export const CLASS_NAME_PREFIX = 'tk';

export const INPUT_COMPONENT_CLASS_NAME = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-input`;
export const WIDGET_CLASS_NAME = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-widget`;
export const LAYOUT_CLASS_NAME = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-layout`;
export const TOOL_CLASS_NAME = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-tool`;
export const DISPLAY_CLASS_NAME = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-display`;

/*****************************************************
 * Saved Configuration URL Params
 ****************************************************/

export const TK_SAVED_CONFIG_PARAM_KEY = 'threekitID';
export const TK_SAVED_CONFIG_PARAM_KEY2 = 'configId';
export const TK_SAVED_CONFIG_PARAM_KEY3 = 'recipeId';

export const SKU_ATTRIBUTE_NAME = '_SKU';

/*****************************************************
 * Threekit Player initialization Defaults
 ****************************************************/

export const TK_PLAYER_DIV_ID_2D = 'threekit-player-2d';
export const TK_PLAYER_DIV_ID_3D = 'threekit-player-3d';
export const TUTORIAL_DIV_ID = 'tutorial';
export const HELPER_DIV_ID = 'helper';
export const HOME_CONTAINER = 'home-container';

export const TK_PLAYER_ATTRIBUTE_ID = '8ffdbe7b-ef39-46c4-b051-14eb1953ac88';

export const DEFAULT_PLAYER_CONFIG = {
  authToken: undefined,
  elementId: undefined,
  cache: undefined,
  stageId: undefined,
  assetId: undefined,
  showConfigurator: false,
  initialConfiguration: undefined,
  initialConfigurationId: undefined,
  showLoadingThumbnail: false,
  showLoadingProgress: false,
  onLoadingProgress: undefined,
  showAR: false,
  showShare: false,
  locale: undefined,
  allowMobileVerticalOrbit: false,
  publishStage: 'published',
  attrGroupingTableId: undefined,
  scriptPath: undefined,
  threekitEnv: undefined,
  useProxy: undefined,
  threekitProductEnv: undefined,
  authProductToken: undefined,
};

/*****************************************************
 * Attributes
 ****************************************************/

export const ATTRIBUTES_RESERVED = {
  step: '_step',
  camera: '_camera',
  dimensions: '_dimensions',
};

export const ATTRIBUTE_TYPES = {
  asset: 'Asset',
  string: 'String',
  number: 'Number',
  color: 'Color',
  boolean: 'Boolean',
  arraySelector: 'AttributesArraySelector',
  arrayEditor: 'AttributesArrayEditor',
};

export const SORT_OPTIONS = {
  ascending: 'ascending',
  descending: 'descending',
};

/*****************************************************
 * Psuedo Array Attribute
 ****************************************************/

//  Validation properties for entire array
const arrayValidations = {
  maxItems: 'maxItems',
};

//  Validation properties for each item in the array
const arrayItemValidations = {
  minAllowed: 'minAllowed',
  maxAllowed: 'maxAllowed',
  minProximityToSelf: 'minProximityToSelf',
  maxProximityToSelf: 'maxProximityToSelf',
  minProximityToStart: 'minProximityToStart',
  maxProximityToStart: 'maxProximityToStart',
  minProximityToFinish: 'minProximityToFinish',
  maxProximityToFinish: 'maxProximityToFinish',
  minProximityToEnds: 'minProximityToEnds',
  maxProximityToEnds: 'maxProximityToEnds',
  positionsNotAllowed: 'positionsNotAllowed',
  positionsAllowed: 'positionsAllowed',
};

export const ARRAY_VALIDATION = Object.assign(arrayValidations, arrayItemValidations);
/*****************************************************
 * Reserved Catalog Item Metadata Properties
 ****************************************************/

export const METADATA_RESERVED = Object.assign(
  {
    title: '_title',
    description: '_description',
    thumbnailPath: 'thumbnailPath',
    sku: 'SKU',
    valueSku: 'valueSku',
    valueCode: 'valueCode',
    filters: '_filters',
    tooltip: '_tooltip',
    price: '_price',
    translate: '_translate',
    rotate: '_rotate',
    scale: '_scale',
    sortKey: '_order',
    atributeGroupingTableId: '_attrGroupingTableId',
    thumbnail: 'thumbnail',
  },
  //  Array type related metadata
  Object.entries(arrayItemValidations).reduce((output, [key, val]) => Object.assign(output, { [key]: `_${val}` }), {})
);

/*****************************************************
 * Snapshot
 ****************************************************/

export const SNAPSHOT_FORMATS: { png: string; jpeg: string } = {
  png: 'png',
  jpeg: 'jpeg',
};

export const SNAPSHOT_OUTPUTS = {
  url: 'url',
  download: 'download',
  dataUrl: 'dataUrl',
  blob: 'blob',
};

export const DEFAULT_CAMERA_CONFIG = {
  attributeName: 'CameraAngle',
  size: { width: 610, height: 576 },
  format: SNAPSHOT_FORMATS.png,
};

export const PICTURE_FORMAT = '1.webp';

/*****************************************************
 * Additional Display options
 ****************************************************/

export const DISPLAY_OPTIONS = {
  modal: 'modal',
  drawer: 'drawer',
};

export const ATTRIBUTE_NAME_TO_HIDE = [
  'CameraAngle',
  'CharacterLimit',
  'Model Presentation',
  'Rotate Model',
  'dataDrivenConfiguratorToolSwitch',
];


export const VERSION = '1.0.765';



// SLIDE ROTATE MATRIX ANIMATION
export const MATRIX = [
  'matrix(1,0,0,1,-55,0)',
  'matrix(1,0,0,1,55,0)',
  'matrix(1,0,0,1,-55,0)',
  'matrix(1,0,0,1,55,0)',
];

export const MAIN_ATTACHMENT_KEY = 'Front';
// export const MAIN_ATTACHMENT_KEY = 'Packshot';

export const RESET_STEP_CAROUSEL = 0;
export const RESET_STEP_ACCORDION = 0;
export const MEASURE_UNIT = 'px';
export const TITLE_PLACEHOLDER = 'Title is missing';
export const DESCRIPTION_PLACEHOLDER = 'Description is missing';
export const PRODUCTNAME_PLACEHOLDER = 'Product Name is missing';
export const PRICE_PLACEHOLDER = 'Price is Missing';
export const SMALL_SIZE_LABEL = 'small';
export const MEDIUM_SIZE_LABEL = 'medium';
export const BIG_SIZE_LABEL = 'big';
export const VERTICAL_SIZE_LABEL = 'vertical';
export const AVAILABLE_ASSETS_OPACITY_VALUE = '1';
export const UNAVAILABLE_ASSETS_OPACITY_VALUE = '0.2';
export const OOB_APPNAME = 'oob';
export const WEBATG_APPNAME = 'webatg';
export const WEBTWIST_APPNAME = 'webtwist';
export const WECHAT_APPNAME = 'wechat';
export const PATCH_STYLE_LABEL = 'patch';
export const CATALOGWECOM_APPNAME = 'catalogwecom';
export const JOURNEYWECOM_APPNAME = 'journeywecom';
export const CATALOG_DESKTOP_APPNAME = 'catalogdesktop';
export const JOURNEYIOS_APPNAME = 'journeyios';
export const FAKE_SKU = 'N534234';
export const NEXT_LABEL = 'next';
export const PREVIOUS_LABEL = 'prev';
export const NONE_LABEL = 'none';
export const CAROUSSEL_CLASSNAME = 'carousel';
export const LOADING_LABEL = 'Loading';
export const SKU_ERROR_MESSAGE = 'No sku found, Please try again';
export const RECIPEID_ERROR_MESSAGE = 'No Recipeid found, Please try again';
export const MINUS_LABEL = 'minus';
export const PLUS_LABEL = 'plus';

export const NO_RECAP_APP = [WEBATG_APPNAME, WEBTWIST_APPNAME, OOB_APPNAME, WECHAT_APPNAME];

interface IPageTitles {
  [key: string]: {
    en: string;
    zh: string;
  };
}

export const PAGES_TITLES: IPageTitles = {
  home: {
    en: 'LouisVuitton - Your Product Personalization',
    zh: '个性化定制',
  },
  summary: {
    en: 'LouisVuitton - Your Product Personalization',
    zh: '概括',
  },
  client: {
    en: 'LouisVuitton - Your Product Personalization',
    zh: '配置',
  },
};



export const PRODUCT_NAME_MAPPING: Record<string, string> = {
  "LV&I": "lvi",
  "Capucines": "capucine"
}

export const LANDSCAPE_FORM_BASE_HEIGHT_IN_PX = 248;
export const PORTRAIT_FORM_BASE_HEIGHT_IN_PX = 295;
export const LANDSCAPE_FORM_MAX_HEIGHT_IN_PX = 248;
export const PORTRAIT_FORM_MAX_HEIGHT_IN_PX = 500;
export const PORTRAIT_FORM_SLIDE_PERCENTS_TO_OPEN = 4;
export const PORTRAIT_FORM_SLIDE_PERCENTS_TO_CLOSE = 97;

export const RETAIL_APP_NAMES = ['journeyios', 'journeywecom'];

export const PLAYER_VIEWS: {
  [key: string]: {
    mobile: {
      targetBox?: {
        a: { x: number; y: number; z: number };
        b: { x: number; y: number; z: number };
      };
      isRotatable: boolean;
    };
    desktop: {
      targetBox?: {
        a: { x: number; y: number; z: number };
        b: { x: number; y: number; z: number };
      };
      isRotatable: boolean;
    };
    displayTitle?: string;
    cameraAssetId?: string;
  };
} = {
  default: {
    mobile: {
      targetBox: { a: { x: 0, y: 0, z: 0 }, b: { x: 1, y: 1, z: 1 } },
      isRotatable: true,
    },
    desktop: {
      targetBox: { a: { x: 0, y: 0, z: 0 }, b: { x: 1, y: 1, z: 1 } },
      isRotatable: true,
    },
  },
};

export const PRODUCT_TYPES = {
  CAPUCINES: 'capucines',
};

export const IS_LOCALHOST = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
export const URL_BASE = IS_LOCALHOST ? 'https://int-product-perso-public.louisvuitton.com' : '';

// Mock data for product infos
export const buttons = {
  next: {
    findInStore: {
      label: 'Find in store',
      text: 'Find the nearest store to you',
      next: {
        store: {
          label: 'Store',
          subLabel: 'Louis Vuitton',
          img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
          text: 'Louis Vuitton Store, 5th Avenue, New York',
          next: {
            address: {
              label: 'Address',
              subLabel: 'Louis Vuitton Store, 5th Avenue, New York',
              img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
            },
            openingHours: {
              label: 'Opening hours',
              subLabel: 'Mon-Sat: 10AM - 8PM, Sun: 11AM - 6PM',
              img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
            },
          },
        },
        address: {
          label: 'Address',
          subLabel: 'Louis Vuitton Store, 5th Avenue, New York',
          img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
        },
        openingHours: {
          label: 'Opening hours',
          subLabel: 'Mon-Sat: 10AM - 8PM, Sun: 11AM - 6PM',
          img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
        },
      },
    },
    productDetails: {
      label: 'Product details',
      text: 'The Speedy 18 is the perfect miniature version of Louis Vuitton’s iconic Speedy bag. Crafted from Monogram canvas with natural cowhide trim, it features two top handles and a detachable strap for shoulder or cross-body wear. The bag’s secure zip closure opens to reveal a spacious, well-organized interior. The Speedy 18 can be carried by hand, on the shoulder, or across the body.',
      next: {
        bag: {
          label: 'Bag',
          subLabel: 'Veautwist Wisteria',
          img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
        },
        charms: {
          label: 'Charms',
          subLabel: 'LV Logo white | Cake Creme | Eiffel Tower',
          img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
          text: 'With the new Adjustable Strap, it’s easy to customize your Speedy bag to suit your style. Crafted from Monogram canvas, this versatile strap features a VVN leather shoulder pad and gold-color hardware. It’s adjustable for comfortable shoulder or cross-body wear, and can be used with a variety of bags in the Louis Vuitton collection.',
          next: {
            moreDetails: {
              label: 'More details',
              subLabel: 'Adjustable Strap',
              img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
            },
          },
        },
        carryStyle: {
          label: 'Carry Style',
          subLabel: 'Short strap 40',
          img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
        },
        addOn: {
          label: 'Add-on',
          subLabel: 'Heart purse',
          img: 'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png',
        },
      },
    },
    deliveryAndReturns: {
      label: 'Delivery & returns',
      next: null,
    },
    gifting: {
      label: 'Gifting',
      next: null,
    },
  },
};
