import { PayloadAction } from '@reduxjs/toolkit';
import { FlowState } from '..';

export const setDisplayHelper = (state: FlowState, action: PayloadAction<boolean>) => {
  state.helper.displayHelper = action.payload;
};
export const setDisplayRecap = (state: FlowState, action: PayloadAction<boolean>) => {
  state.recap.displayRecap = action.payload;
};

export const setRecapOpenPercentage = (
  state: FlowState,
  action: PayloadAction<number>
) => {
  state.recap.recapOpenPercentage = action.payload;
};

export const openRecap = (state: FlowState) => {
  state.recap.displayRecap = true;
  state.recap.recapOpenPercentage = 100;
};

export const closeRecap = (state: FlowState) => {
  state.recap.displayRecap = false;
  state.recap.recapOpenPercentage = 0;
};

export const setFormHeight = (state: FlowState, action: PayloadAction<number>) => {
  state.form.height = action.payload;
};

export const setActiveSurpriseMe = (state: FlowState,  action: PayloadAction<boolean>
  ) => {
  state.activeSurpriseMe = action.payload;
};

export const setPlayerView = (state: FlowState,  action: PayloadAction<string>
) => {
state.playerView = action.payload;
};
