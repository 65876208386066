import { t } from 'i18next';

export const mappingLanguage = (lng: string | undefined): string => {
  switch (lng) {
    // German
    case 'de':
    case 'de-DE':
    case 'deu':
    case 'de_DE':
      return 'de_DE';

    // English
    case 'en':
    case 'en-US':
    case 'en-GB':
    case 'ENG':
    case 'eng':
    case 'en_E1':
      return 'en_E1';

    // Spanish
    case 'es':
    case 'es-ES':
    case 'es-MX':
    case 'esp':
    case 'es_ES':
      return 'es_ES';

    // French
    case 'fr':
    case 'fr-FR':
    case 'fr-CA':
    case 'fra':
    case 'fr_FR':
      return 'fr_FR';

    // Italian
    case 'it':
    case 'it-IT':
    case 'ita':
    case 'it_IT':
      return 'it_IT';

    // Japanese
    case 'ja':
    case 'ja-JP':
    case 'jpn':
    case 'ja_JP':
      return 'ja_JP';

    // Korean
    case 'ko':
    case 'ko-KR':
    case 'kor':
    case 'ko_KR':
      return 'ko_KR';

    // Portuguese
    case 'pt':
    case 'pt-BR':
    case 'por':
    case 'pt_BR':
      return 'pt_BR';

    // Russian
    case 'ru':
    case 'ru-RU':
    case 'rus':
    case 'ru_RU':
      return 'ru_RU';

    // Simplified Chinese
    case 'zh':
    case 'zh-CN':
    case 'zh-Hans-CN':
    case 'zhs':
    case 'zh_CN':
      return 'zh_CN';

    // Traditional Chinese (Hong Kong)
    case 'zh-Hant-HK':
    case 'zh-HK':
    case 'zh_HK':
      return 'zh_HK';

    // Traditional Chinese (Taiwan)
    case 'zh-Hant-TW':
    case 'zh-TW':
    case 'zht':
    case 'zh_TW':
      return 'zh_TW';

    // Default to English
    default:
      return 'en_E1';
  }
};

export const localePriceMapping = (country: string | undefined): string => {
  switch (country?.toLowerCase()) {
    case 'ae':
      return 'ara-ae';

    case 'kw':
      return 'ara-kw';

    case 'qa':
      return 'ara-qa';

    case 'sa':
      return 'ara-sa';

    case 'de':
      return 'deu-de';

    case 'ae':
      return 'eng-ae';

    case 'at':
      return 'eng-at';

    case 'au':
      return 'eng-au';

    case 'be':
      return 'eng-be';

    case 'ca':
      return 'eng-ca';

    case 'dk':
      return 'eng-dk';

    case 'fi':
      return 'eng-fi';

    case 'gb':
      return 'eng-gb';

    case 'hk':
      return 'eng-hk';

    case 'id':
      return 'eng-id';

    case 'ie':
      return 'eng-ie';

    case 'in':
      return 'eng-in';

    case 'kw':
      return 'eng-kw';

    case 'lu':
      return 'eng-lu';

    case 'my':
      return 'eng-my';

    case 'nl':
      return 'eng-nl';

    case 'nz':
      return 'eng-nz';

    case 'oa':
      return 'eng-oa';

    case 'qa':
      return 'eng-qa';

    case 'sa':
      return 'eng-sa';

    case 'se':
      return 'eng-se';

    case 'sg':
      return 'eng-sg';

    case 'rus':
      return 'ru_RU';

    case 'us':
      return 'eng-us';

    case 'es':
      return 'esp-es';

    case 'mx':
      return 'esp-mx';

    case 'ca':
      return 'fra-ca';

    case 'zh_HK':
      return 'zh_HK';

    case 'fr':
      return 'fra-fr';

    case 'it':
      return 'ita-it';

    case 'jp':
      return 'jpn-jp';

    case 'kr':
      return 'kor-kr';

    case 'br':
      return 'por-br';

    case 'ru':
      return 'rus-ru';

    case 'th':
      return 'tha-th';

    case 'vn':
      return 'vie-vn';

    case 'cn':
      return 'zhs-cn';

    case 'hk':
      return 'zht-hk';

    case 'tw':
      return 'zht-tw';

    default:
      return 'eng-e1';
  }
};

export const webPurifyLanguageMappings = (lng: string): string => {
  switch (lng) {
    case 'de_DE':
      return 'de';

    case 'deu':
      return 'de';

    case 'en_E1':
      return 'en';

    case 'ENG':
      return 'en';

    case 'eng':
      return 'en';

    case 'es_ES':
      return 'es';

    case 'esp':
      return 'es';

    case 'fr_FR':
      return 'fr';

    case 'fra':
      return 'fr';

    case 'it_IT':
      return 'it';

    case 'ita':
      return 'it';

    case 'ja_JP':
      return 'ja';

    case 'jpn':
      return 'ja';

    case 'ko_KR':
      return 'ko';

    case 'kor':
      return 'ko';

    case 'pt_BR':
      return 'pt';

    case 'por':
      return 'pt';

    case 'ru_RU':
      return 'ru';

    case 'rus':
      return 'ru';

    case 'zh_CN':
      return 'zh';

    case 'zhs':
      return 'zh';

    case 'zh_HK':
      return 'zh';

    case 'zht':
      return 'zh';

    default:
      return 'en';
  }
};

export function translateLeadTime(leadTime: string, leadTimeUnit: string) {
  let translationKey: string;

  switch (leadTimeUnit) {
    case 'day_one':
      translationKey = 'stock.label.date.day_one';
      break;
    case 'days':
      translationKey = 'stock.label.date.day_other';
      break;
    case 'day_zero':
      translationKey = 'stock.label.date.day_zero';
      break;
    case 'month_one':
      translationKey = 'stock.label.date.month_one';
      break;
    case 'month_other':
      translationKey = 'stock.label.date.month_other';
      break;
    case 'week_one':
      translationKey = 'stock.label.date.week_one';
      break;
    case 'weeks':
      translationKey = 'stock.label.date.week_other';
      break;
    default:
      translationKey = 'stock.label.date.day_other';
  }

  return t(translationKey, { leadTime, interpolation: { escapeValue: false } });
}

