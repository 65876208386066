import styled from 'styled-components';

export const PopUpWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
  }) => {
    return `
      min-width: ${isMobile ? '55vw' : '350px'};  
    `});

export const PopUpImgWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['outOfStockStyle'].includes(prop),
})<{ outOfStockStyle: boolean }>(
  ({ outOfStockStyle }) => `
  ${outOfStockStyle ? `
    position: relative;
    &::after {
      content: '';
      width: 10%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: #c53929;
      color: #fff;
      position: absolute;
      top: 5px;
      right: 5px;
      text-align: center;
      font-size: 12px;
    }` : ''};
`
);
