import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getActiveGroup, getGroupedAttributes } from '../../store/threekitSlicer';
import { useAttribute } from '../../utils/threekitHooks';
import { t } from 'i18next';

function useActiveGroup() {
  const activeGroup = useSelector(getActiveGroup);
  const groupedAttributes = useSelector(getGroupedAttributes);
  const [attributeData, handleChange] = useAttribute(groupedAttributes?.[activeGroup]?.[0]?.name as string);
  const selectedValues: any[] = useMemo(() => {
    if (!attributeData?.groupName)
      return [attributeData?.values.find((value: any) => value?.selected)];
    else {
      const attributesInGroup = groupedAttributes?.[activeGroup] || [];
      const selected = attributesInGroup?.map((attribute: any) =>
        attribute.values.find((value: any) => value?.selected)
      );
      return selected;
    }
  }, [attributeData, activeGroup, groupedAttributes]);

  const attributesDataInGroup = useMemo(() => {
    if (!attributeData?.groupName) return [attributeData];
    return groupedAttributes?.[t(attributeData.groupName)] || [];
  }, [attributeData, groupedAttributes]);

  return { attributeData, attributesDataInGroup, selectedValues, handleChange, activeGroup };
}

export default useActiveGroup;
