import { useMemo } from 'react';
import { Wrapper } from './SummaryButtons.styles';
import { getIsAllSelectedValuesInStock } from '../../store/threekitSlicer';
import { useSelector } from 'react-redux';
import {ItemUnavailableButton, ModifyButton, AddToBagButton} from '../Buttons';

export const SummaryButtons = () => {
  const isSelectedValuesInStock = useSelector(getIsAllSelectedValuesInStock);

  const disabledAddToBag = useMemo(() => {
    return !isSelectedValuesInStock;
  }, [isSelectedValuesInStock]);

  return (
    <Wrapper>
      {disabledAddToBag ?
      <ItemUnavailableButton
        disable={disabledAddToBag}
      /> : <AddToBagButton />}
      <ModifyButton />
    </Wrapper>
  );
};
