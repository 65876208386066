import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/animations.scss';
import { useThreekitInitStatus, useWindowSize } from '../../utils/threekitHooks';
import { ConfiguratorContainer, Container, LVLogo, IOSEvent } from './Home.styles';
import { AnimateItem, AwaitPlayerLoad, Player2D } from '../../components';
import { useNavigate } from 'react-router';
import { getGlobalSettingsParams, getStep, setGlobalSettingsParams, setStep } from '../../store/globalSettingsSlicer';
import {
  getForm,
  getIsChina,
  getPage,
  setInitialConfiguration,
  setIsInStock,
  getIsRotable,
  setScannedSkus,
  getScannedSkus,
  setRecievedScannedSkus,
  getGroupedAttributes,
  setIsAllSelectedValuesInStock,
} from '../../store/threekitSlicer';
import {
  getDisplayHelper,
  getDisplayRecap,
  getFormHeight,
} from '../../store/flowSlicer';
import {
  RESET_STEP_ACCORDION,
  RESET_STEP_CAROUSEL,
  HOME_CONTAINER,
  TK_SAVED_CONFIG_PARAM_KEY,
  LANDSCAPE_FORM_MAX_HEIGHT_IN_PX,
  NO_RECAP_APP,
} from '../../utils/constants';
import { usePageTitle, useThreekitIdConfiguration, useValuesStock } from '../../hooks';
import SummaryPage from '../SummaryPage/SummaryPage';
import { getParams, paramsObjectToNavigationString } from '../../utils/function/navigationParams';
import PortraitForm from './components/PortraitForm';
import LandscapeForm from './components/LandscapeForm';
import { tutorialData } from '../../utils/mockData';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import useStock from '../../hooks/useStock';
import { findValueInDataDriven, isChooseValue } from '../../utils/function/functions';
import { waitForDataDrivenConfigurator } from '../../utils/function/dataDrivenFn';
import { LOUIS_VUITTON_LOGO } from '../../assets';
import Helper from '../../components/Form/Help/Helper';
import { getSavedConfigurationData } from '../../store/threekitSlicer/selectors/savedConfigurationData';
import { skusAvailabilityResponse } from '../../utils/ApiCalls/ApiCalls';

const Home = () => {
  const savedConfigurationData = useSelector(getSavedConfigurationData);
  const formHeight = useSelector(getFormHeight);
  const displayRecap = useSelector(getDisplayRecap);
  const [configuratorReady, setIsConfiguratorReady] = useState(false);
  type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
  const dispatch = useDispatch<AppThunkDispatch>();
  usePageTitle({ page: 'home' });
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const isLoaded = useThreekitInitStatus();
  const currentStep = useSelector(getStep);
  const groupedAttributes = useSelector(getGroupedAttributes);
  const [receivedFromiOS, setReceivedFromiOS] = useState([]);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const params = globalSettingsParams;
  const { appName } = params || {};
  const form = useSelector(getForm);
  const isChina = useSelector(getIsChina);
  const isValuesInStock = useValuesStock();
  const { [TK_SAVED_CONFIG_PARAM_KEY]: threekitID, sku } = globalSettingsParams || {
    threekitID: '',
    sku: '',
  };
  const actualPageIsHome = useSelector(getPage) === 'home';
  const pageToDisplay = (threekitID && params?.page === 'configurator') || actualPageIsHome;
  const dataDrivenSku = configuratorReady && window?.dataDrivenConfiguratorExtension?.getStatus()?.skus[0];

  const isRotable = useSelector(getIsRotable);
  const scannedSkus = useSelector(getScannedSkus) || [];
  const [isInitialConfigurationSet, setIsInitialConfigurationSet] = useState(false);
  const displayHelper = useSelector(getDisplayHelper);

  useEffect(() => {
    waitForDataDrivenConfigurator().then((isReady) => {
      setIsConfiguratorReady(isReady);
    });
  }, []);

  useEffect(() => {
    if (isChina === undefined) return;
    const params = getParams();
    const finalParams = paramsObjectToNavigationString(params, isChina);
    navigate(`/${finalParams}`);
  }, [isChina, dispatch, navigate]);

  useEffect(() => {
    const newParams = {
      ...globalSettingsParams,
      sku: dataDrivenSku,
    };
    if (dataDrivenSku !== sku) {
      dispatch(setGlobalSettingsParams(newParams));
    }
  }, [dataDrivenSku]);

  useEffect(() => {
    if (isInitialConfigurationSet || !dataDrivenSku || savedConfigurationData) return;
    const initiateConfiguration = async () => {
      const initialConfig = await window.threekit.configurator.getFullConfiguration();
      if (initialConfig) {
        dispatch(setInitialConfiguration(initialConfig));
        setIsInitialConfigurationSet(true);
      }
    };
    initiateConfiguration();
  }, [dataDrivenSku, isInitialConfigurationSet, savedConfigurationData, dispatch]);

  const handleMessage = useCallback(
    (event: any) => {
      const { eventName, eventData } = event.detail;
      setReceivedFromiOS(JSON.parse(eventData)?.skus);
    },
    [receivedFromiOS, scannedSkus]
  );

  useEffect(() => {
    let allScannedSkus = scannedSkus?.concat(receivedFromiOS);
    dispatch(setScannedSkus([...new Set(allScannedSkus)]));
    dispatch(setRecievedScannedSkus(receivedFromiOS));
  }, [receivedFromiOS]);

  useEffect(() => {
    window.addEventListener('scanProductSkus', handleMessage);
    return () => {
      window.removeEventListener('scanProductSkus', handleMessage);
    };
  }, []);

  const attributesFromWindow = useMemo(
    () => (configuratorReady ? window?.threekit?.controller?.getAttributes() || {} : {}),
    [configuratorReady, window?.threekit?.controller]
  );

  const allValuesSKUs: any = useMemo(
    () => {
      return Object.values(attributesFromWindow)
        ?.map((attribute: any) =>
          attribute?.values?.map((value: any) => {
            const valueFromDataDriven = findValueInDataDriven(value?.assetId);
            return !isChooseValue(value) && valueFromDataDriven?.sku && valueFromDataDriven?.sku?.length > 4
              ? valueFromDataDriven?.sku
              : '';
          })
        )
        ?.flat()
        ?.filter((value) => value !== '');
    },

    [attributesFromWindow]
  );

  const {
    data: skusInStock,
    isLoading,
    isError,
    error,
  } = useStock({
    skus: allValuesSKUs || [],
    isEnabled: isLoaded,
  }) as {
    data: skusAvailabilityResponse;
    isLoading: boolean;
    isError: boolean;
    error: any;
  };

  useEffect(() => {
    if (skusInStock?.items) dispatch(setIsInStock(skusInStock));
  }, [skusInStock, dispatch]);

  const isSelectedValuesInStock = useMemo(() => {
    const allSelectedValues = Object.values(groupedAttributes || {}).map((group) => {
      return group.map((attribute) => {
        return attribute.values.filter((value: Record<string, any>) => {
          return value?.selected === true && !isChooseValue(value);
        });
      }).flat();
    }).flat();
    return isValuesInStock({ valuesToCheck: allSelectedValues });
  }, [isValuesInStock, groupedAttributes]);

  useEffect(() => {
    if (isLoaded) dispatch(setIsAllSelectedValuesInStock(isSelectedValuesInStock.every((value) => value === true)));
  }, [dispatch, isSelectedValuesInStock, isLoaded]);

  useEffect(() => {
    if (NO_RECAP_APP.includes(appName as string)) {
      const eventDataShare = { eventName: displayHelper || displayRecap ? 'modalOpen' : 'modalClose' };
      window.parent.postMessage(eventDataShare, '*');
    }
  }, [displayHelper, displayRecap]);

  return configuratorReady ? (
    <>
      <Container overscroll={pageToDisplay} id={HOME_CONTAINER} pageToDisplay={pageToDisplay}>
        <LVLogo src={LOUIS_VUITTON_LOGO} alt="Louis Vuitton Logo" />
        <IOSEvent>{receivedFromiOS?.toString()}</IOSEvent>
        <ConfiguratorContainer aria-hidden="true" show={pageToDisplay}>
          <AwaitPlayerLoad>
            <Player2D
              homePage
              cssDisplay={pageToDisplay}
              isMobile={isMobile}
              isRotable={isRotable}
              isLoaded={isLoaded}
              tutorialData={tutorialData}
              height={
                isMobile
                  ? `calc(100svh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - ${formHeight}px)`
                  : `calc(100svh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - ${formHeight}px)`
              }
            >
              <AnimateItem />
            </Player2D>
            {pageToDisplay && isLoaded && (isMobile ? <PortraitForm /> : <LandscapeForm />)}
          </AwaitPlayerLoad>
        </ConfiguratorContainer>
        {!pageToDisplay && isLoaded && <SummaryPage />}
        {displayHelper && <Helper displayHelper={displayHelper} />}
      </Container>
    </>
  ) : null;
};

export default Home;
