import { useCallback, useEffect } from 'react';
import { ViewDot, ViewDotsContainer, Arrow, ArrowContainer } from './viewsViewer.styles';
import {
  LEFT_ARROW_SLIDER_BLACK,
  RIGHT_ARROW_SLIDER_BLACK,
} from '../../assets';
import useSwipe from '../../hooks/useSwipe';
import { HELPER_DIV_ID } from '../../utils/constants';

import { useDispatch } from 'react-redux';
import { useWindowSize } from '../../utils/threekitHooks';
import { setDisplayHelper } from '../../store/flowSlicer';

function ViewsViewer({
  data,
  activeStep,
  setActiveStep,
  setSlideDirection,
}: any) {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const tutorialDataLength = data?.steps?.length;

  const previousIndex = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      setSlideDirection('prev');
    }
  }, [activeStep, setActiveStep, setSlideDirection]);

  const nextIndex = useCallback(() => {
    if (activeStep < data?.steps?.length - 1) {
      setActiveStep(activeStep + 1);
      setSlideDirection('next');
    }
  }, [activeStep, setActiveStep, setSlideDirection]);

  const { handleTouchStart, handleTouchEnd } = useSwipe(previousIndex, nextIndex, 100);

  useEffect(() => {
    const tuturialDiv = document.getElementById(HELPER_DIV_ID);
    if (!tuturialDiv) return;

    tuturialDiv.addEventListener('touchstart', handleTouchStart, false);
    tuturialDiv.addEventListener('touchend', handleTouchEnd, false);

    return () => {
      tuturialDiv.removeEventListener('touchstart', handleTouchStart, false);
      tuturialDiv.removeEventListener('touchend', handleTouchEnd, false);
    };
  }, [handleTouchStart, handleTouchEnd]);

  const handleKeydown = (evt: KeyboardEvent) => {
    const listener = keyListenersMap.get(evt.keyCode);
    return listener && listener();
  };

  const handleEscape = () => {
    dispatch(setDisplayHelper(false));
  };

  const keyListenersMap = new Map([
    [27, handleEscape],
    [39, nextIndex],
    [37, previousIndex],
  ]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      // Detach listener when component unmounts
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [activeStep]);

  return (
    <>
      <ViewDotsContainer >
        {data?.steps?.map((view: Record<string, any>, key: number) => (
          <ViewDot
            key={key}
            isActive={activeStep === key}
            onClick={() => {
              setActiveStep(key);
            }}
          />
        ))}
      </ViewDotsContainer>
      {!isMobile && data?.steps?.length > 0 && (
        <ArrowContainer>
          <Arrow role='button' id="arrow_left" tabIndex={activeStep < 1 ? -1 : 0} onClick={previousIndex} disable={activeStep < 1}>
            <img src={LEFT_ARROW_SLIDER_BLACK} />
          </Arrow>
          <Arrow role='button' id="arrow_right" tabIndex={0} onClick={nextIndex} disable={activeStep > tutorialDataLength - 2}>
            <img src={RIGHT_ARROW_SLIDER_BLACK} />
          </Arrow>
        </ArrowContainer>
      )}
    </>
  );
}

export default ViewsViewer;
