import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { ButtonContainer } from '../buttons.style';
import { DONE_BUTTON_LABEL } from '../constants';
import { showPopUp } from '../../PopUp/ShowPopUp';
import { popUpTypes } from '../../PopUp/PopUpType';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAllSelectedValuesInStock, getIsChina, getPreviousCamera, getScannedSkus, setIsActionFired, setSavedConfigurationData } from '../../../store/threekitSlicer';
import {
  getConfigVariables,
  getGlobalSettingsParams,
  getIsRetailApp,
  IParams,
  setGlobalSettingsParams,
} from '../../../store/globalSettingsSlicer';
import { useAttribute, useWindowSize } from '../../../utils/threekitHooks';
import { usePrice, useProductName } from '../../../hooks';
import { fetchSkusAvailabilityWhenDone, getSavedConfig, skusAvailabilityWhenDoneResponse } from '../../../utils/ApiCalls/ApiCalls';
import { isEmptyObj } from '../../../utils/function/functions';
import { TK_SAVED_CONFIG_PARAM_KEY } from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { getSavedConfigurationData } from '../../../store/threekitSlicer/selectors/savedConfigurationData';
import { setDisplayHelper } from '../../../store/flowSlicer';
import { handleItemAction } from '../utils';
import { ButtonLoader } from '../ButtonLoader';

interface DoneButtonProps {
  disable?: boolean;
  leadTime?: {
    min: number;
    max: number;
  };
  notOrderable?: boolean;
  notOrderableMessage?: string;
}

function DoneButton({
  disable = false,
  leadTime,
  notOrderable = false,
}: DoneButtonProps) {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [saveConfigurationError, setSaveConfigurationError] = useState<boolean>(false);

  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { appName = '', storeCode = '' } = globalSettingsParams || {};
  const configVariables = useSelector(getConfigVariables);
  const { orgId = '', threekitProductEnv = '' } = configVariables || {};
  const defaultCameraId = useSelector(getPreviousCamera);
  const configurationSavedData = useSelector(getSavedConfigurationData);
  const isRetailApp = useSelector(getIsRetailApp);
  const isChina = useSelector(getIsChina);
  const scannedSkus = useSelector(getScannedSkus);
  const isAllInStock = useSelector(getIsAllSelectedValuesInStock);

  const [, setCamera] = useAttribute('Camera');
  const { currency, priceWithCurrency, priceValue } = usePrice();
  const productName = useProductName();
  const { baseUrlClient } = useParams();
  const dataDrivenSku = window.dataDrivenConfiguratorExtension?.getStatus()?.skus;
  const [minLeadTime, maxLeadTime] = useMemo(() => [leadTime?.min, leadTime?.max], [leadTime]);

  useEffect(() => {
    const isLoading =
      clicked && !disable && !saveConfigurationError && !notOrderable;
    setShowLoader(isLoading);
  }, [clicked, disable, notOrderable, saveConfigurationError]);

  useEffect(() => {
    if (!isEmptyObj(configurationSavedData) && isDone) {
      handleItemAction({
        action: 'done',
        isChina,
        configurationSavedData,
        appName,
        clientUrl,
        globalSettingsParams,
        dataDrivenSku,
        productName,
        scannedSkus,
        setClicked,
        isAllInStock
      });
      setIsDone(!isDone);
      dispatch(setIsActionFired(false));
      dispatch(setDisplayHelper(false));
    }
  }, [configurationSavedData, isDone, dispatch]);

  const clientUrl = useMemo((): string => {
    if (!globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY] || !baseUrlClient) return '';

    const result = `https://${baseUrlClient}/${globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY]}`;
    return encodeURI(result);
  }, [globalSettingsParams, baseUrlClient]);

  const checkAllSkusAfterDone = useCallback(async () => {
    if (!isRetailApp) {
      try {
        const response: skusAvailabilityWhenDoneResponse = await fetchSkusAvailabilityWhenDone({
          items: dataDrivenSku?.filter((sku: string) => sku?.length > 4),
          storeCode,
          appName,
        });
        if (!response) return false;
        return response?.items?.[0]?.available_to_order;
      } catch (error) {
        return false;
      }
    } else return true;
  }, [isRetailApp, dataDrivenSku, storeCode, appName]);

  const handleFinish = useCallback(async () => {
    setShowLoader(true);
    dispatch(setIsActionFired(true));
    if (disable) {
      showPopUp({ popUpType: popUpTypes.notOrderableError });
      setShowLoader(false);
      return;
    }
    // const isAllSkusAvailable = await checkAllSkusAfterDone();
    // if (!isAllSkusAvailable) {
    //   showPopUp({
    //     popUpType: popUpTypes.outOfStockWhenDone,
    //   });
    //   setShowLoader(false);
    //   setClicked(false);
    //   return;
    // }
    dispatch(setSavedConfigurationData({}));
    const locale = globalSettingsParams['lng'] || 'en_E1';
    defaultCameraId && setCamera(defaultCameraId);

    const getSavedConfiguration = async () => {
      const data = await window?.threekit?.controller.saveConfiguration({
        priceWithCurrency,
        productName,
      });
      return data;
    };

    const getAttachementsThroughAPI = async ({ recipeId, dataSaved }: { recipeId: string; dataSaved?: any }) => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        const data = dataSaved
          ? dataSaved
          : await getSavedConfig({
            recipeId
          });

        const baseURL = `${threekitProductEnv}/api/configurations/${recipeId}/image`;

        if (isEmptyObj(data?.attachments)) {
          return {
            front: `${baseURL}/Front?orgId=${orgId}`,
            side: `${baseURL}/Side?orgId=${orgId}`,
            focus_on_strap: `${baseURL}/Focus_on_strap?orgId=${orgId}`,
            zoom_on_charms: `${baseURL}/Zoom_on_charms?orgId=${orgId}`
          };
        } else {
          return data?.attachments;
        }
      } catch (error) {
        console.error('Error:', error?.message);
        return {};
      }
    };
    getSavedConfiguration().then(async (data) => {
      window.dataDrivenConfiguratorExtension
        .saveConfiguration({}, locale, {
          currency: currency,
          price: priceValue,
          productName: productName,
          sku_item: dataDrivenSku[0],
          kit_item_sku: dataDrivenSku.slice(1),
          appName: appName,
          clientUrl,
          leadTime: maxLeadTime || minLeadTime || 0,
          inStore: configurationSavedData?.inStore || false,
          editUrl: '',
        })
        .then(async (recipeId: string) => {
          const dataSaved = await getSavedConfig({
            recipeId
          });
          const attachments = await getAttachementsThroughAPI({ recipeId, dataSaved });
          const params: IParams = {
            ...globalSettingsParams,
            sku: dataDrivenSku,
            recipeId,
            price: priceWithCurrency,
            productName: productName,
          };
          if (recipeId) {
            params[TK_SAVED_CONFIG_PARAM_KEY] = recipeId;
          }
          if (params?.[TK_SAVED_CONFIG_PARAM_KEY]) delete params[TK_SAVED_CONFIG_PARAM_KEY];
          if (params?.page) delete params['page'];
          dispatch(setGlobalSettingsParams(params));
          dispatch(setSavedConfigurationData({ ...dataSaved, attachments }));
          setIsDone(true);
        })
        .catch((error: any) => {
          showPopUp({ popUpType: popUpTypes.saveConfigurationError });
          setShowLoader(false);
        });
    });
  }, [
    checkAllSkusAfterDone,
    defaultCameraId,
    setCamera,
    dispatch,
    priceValue,
    priceWithCurrency,
    globalSettingsParams,
    dataDrivenSku,
    productName,
    isMobile,
    disable,
    currency,
    appName,
    clientUrl,
    configurationSavedData?.inStore,
    maxLeadTime,
    minLeadTime,
  ]);

  return (
    <>
      <ButtonContainer
        buttonName={DONE_BUTTON_LABEL}
        data-dtname={DONE_BUTTON_LABEL}
        onClick={handleFinish}
        showLoader={showLoader}
        disabled={disable}
      >
        {showLoader ? <ButtonLoader /> : <>{t('button.label.done', { defaultValue: DONE_BUTTON_LABEL })}</>}
      </ButtonContainer>
    </>
  );
}

export default DoneButton;
