import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Router from './router/Router';
import i18n from 'i18next';
import { launch, setIsChina } from './store/threekitSlicer';
import useParams from './hooks/useParams';
import { AwaitPlayerLoad } from './components';
import { setConfigVariables, setGlobalSettingsParams, setIsRetailApp } from './store/globalSettingsSlicer';
import { getParams } from './utils/function/navigationParams';
import { isEmptyObj, isRightToLeft } from './utils/function/functions';
import { RETAIL_APP_NAMES, TK_SCRIPT_PATH } from './utils/constants';
import { useThreekitIdConfiguration } from './hooks';
import Controller from './controller';

const App: FC<any> = () => {
  const [isThreekitLaunch, setIsThreekitLaunch] = useState(false);
  const [isScriptPreload, setIsScriptPreload] = useState(false);
  useThreekitIdConfiguration(isThreekitLaunch);
  const dispatch = useDispatch();
  const params = getParams();
  const { sku, appName = '' } = params || {};
  window.dataDrivenConfiguratorInitialSku = sku;
  const { newParams: config, isChina, isLoading, error } = useParams();

  useEffect(() => {
    if (isEmptyObj(config)) return;
    const scriptPromise = async () => {
      // await Controller.createThreekitScriptEl(config?.threekitEnv, TK_SCRIPT_PATH, true);
      setIsScriptPreload(true);
    }
    scriptPromise();
  }, [config]);

  useEffect(() => {
    if (!isScriptPreload || isLoading || error) return;
    dispatch(setIsChina(isChina));
    dispatch(setGlobalSettingsParams(params));
    const isRetailApp = RETAIL_APP_NAMES.includes(appName);
    dispatch(setIsRetailApp(isRetailApp));
    dispatch(setConfigVariables(config));
    dispatch<any>(launch(config));
    setIsThreekitLaunch(true);
  }, [dispatch, isScriptPreload, config, isChina, isLoading, error]);

  useEffect(() => {
    const isRtl = isRightToLeft();
    const dir = isRtl ? 'rtl' : 'ltr';
    document.documentElement.dir = dir;
  }, [params.lng]);

  if (error) {
    return (
      <div style={{ margin: '20px' }}>{error}</div>
    );
  }

  if (!i18n.isInitialized || isLoading) {
    return (
      <AwaitPlayerLoad />
    );
  }

  return (
    <AwaitPlayerLoad>
      <Router />
    </AwaitPlayerLoad>
  );
};

export default App;
