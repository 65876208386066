import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAttribute,
  getActiveGroup,
  getGroupedAttributes,
  setActiveGroup,
} from '../../store/threekitSlicer';
import AttributeCircle from '../AttributeCircle';
import { AttributesContainer, AttributesFlex } from './attributes.styles';
import { useWindowSize } from '../../utils/threekitHooks';
import { ActiveAttributeSelector } from '../ActiveAttributeSelector';
import { getActiveSurpriseMe, getDisplayRecap, setRecapOpenPercentage } from '../../store/flowSlicer';
import { isChooseValue } from '../../utils/function/functions';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { usePlayerView, useProductType, useValuesStock, useActiveGroup } from '../../hooks';
import { t } from 'i18next';
import Controller from '../../controller';

interface AttributesProps {
  tutorialStep?: number;
  displayTutorial?: boolean;
}

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const Attributes: React.FC<AttributesProps> = ({ tutorialStep, displayTutorial }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isPrefetch, setIsPrefetch] = useState(true);
  const groupedAttributes = useSelector(getGroupedAttributes);
  const activeGroup = useSelector(getActiveGroup);
  const displayRecap = useSelector(getDisplayRecap);
  const { isMobile, width } = useWindowSize();
  const activeSurpriseMe = useSelector(getActiveSurpriseMe);
  const { changeView } = usePlayerView();
  const isValuesInStock = useValuesStock();
  const { selectedValues } = useActiveGroup();
  const { isCapucines } = useProductType();

  const openGroup = useCallback(
    (attribute: string) => {
      dispatch(setActiveGroup(attribute));
    },
    [dispatch]
  );

  const closeAttribute = useCallback(() => {
    dispatch(setActiveGroup(''));
    changeView({ newView: 'default' });
  }, [dispatch, changeView]);

  useEffect(() => {
    if (!activeSurpriseMe) {
      changeView({ newView: activeGroup! });
    } else {
      const firstAttribute =
        window.dataDrivenConfiguratorExtension?.getStatus()?.validAttributesAndTheirValues_typeB[0]?.name;
        changeView({ newView: "default" });
      }
  }, [activeGroup, activeSurpriseMe]);

  useEffect(() => {
    if (!groupedAttributes) return;
    if (width !== 0 && !isMobile && !activeGroup && Object.keys(groupedAttributes).length) {
      openGroup(Object.keys(groupedAttributes)?.[0]);
    } else if (isMobile && displayRecap && activeGroup) {
      closeAttribute();
      dispatch(setRecapOpenPercentage(100));
    }
  }, [activeGroup, openGroup, isMobile, groupedAttributes, width, displayRecap, closeAttribute, dispatch]);

  useEffect(() => {
    if (!isCapucines) {
      if (selectedValues.length > 1 && isPrefetch) {
        Controller.removePrefetch();
        setIsPrefetch(false);
      } else if (selectedValues.length === 1 && !isPrefetch) {
        Controller.addPrefetch();
        setIsPrefetch(true);
      }
    }
  }, [activeGroup]);

  return (
    <AttributesContainer>
      <AttributesFlex childrenLength={Object.keys(groupedAttributes||{}).length}>
        {Object.entries(groupedAttributes||{}).map(([groupName, attributes]: [string, IAttribute[]], index: number) => {
          const selectedValues = attributes.map((attribute) => attribute.values.find((value: any) => value?.selected));
          const isGroup = attributes[0]?.groupName;
          const attributeName = isGroup ? groupName : t(attributes?.[0]?.name, attributes?.[0]?.displayName) || '';
          const attributeNameSTR = typeof attributeName === 'string' ? attributeName : '';
          const inStock = isValuesInStock({valuesToCheck: selectedValues});

          return (
            <React.Fragment key={groupName}>
              <AttributeCircle
                attributeName={attributeNameSTR}
                isActive={activeGroup === groupName}
                isTutorialCurrentStep={tutorialStep === index && displayTutorial}
                selectedValues={selectedValues}
                isSelected={selectedValues?.map((value) => !isChooseValue(value))}
                openAttribute={() => openGroup(groupName)}
                inStock={inStock}
              />

              {isMobile && (
                <ActiveAttributeSelector
                  attributes={attributes}
                  selectedValues={selectedValues}
                  isActive={activeGroup === groupName}
                  closeAttribute={closeAttribute}
                  groupName={groupName}
                />
              )}
            </React.Fragment>
          );
        })}
      </AttributesFlex>
    </AttributesContainer>
  );
};

export default Attributes;
