import { useCallback, useState } from 'react';
import { COPY_ICON } from '../../../assets';
import { copyTextToClipboard } from '../../../utils/function/functions';
import { ButtonContainer } from '../buttons.style';
import { COPY_TO_CLIPBOARD_BUTTON_LABEL } from '../constants';
import { CATALOG_DESKTOP_APPNAME, OOB_APPNAME } from '../../../utils/constants';
import { PopUpType, showPopUp } from '../../PopUp/ShowPopUp';
import { popUpTypes } from '../../PopUp/PopUpType';
import { getGlobalSettingsParams } from '../../../store/globalSettingsSlicer';
import { useSelector } from 'react-redux';

interface CopyToClipboardButtonProps {
  buttonText?: string;
}

function CopyToClipboardButton({ buttonText = '' }: CopyToClipboardButtonProps) {
  const [clicked, setClicked] = useState<boolean>(false);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { appName = '' } = globalSettingsParams || {};

  const handleCopy = useCallback(async (): Promise<void> => {
    setClicked(true);
    if (!buttonText) {
      console.error('Empty text');
      return;
    }

    if (appName === OOB_APPNAME || appName === CATALOG_DESKTOP_APPNAME) {
      const eventDataShare = {
        eventName: 'ShareClientUrlAlpha',
        eventData: { clientUrl: buttonText },
      };
      window.parent.postMessage(eventDataShare, '*');
    } else await copyTextToClipboard(buttonText);

      showPopUp({
        popUpType: popUpTypes.textCopied as PopUpType,
        functionOnClose: () => setClicked(false),
      });
    },
    [appName, buttonText]
  );

  return (
    <ButtonContainer
      buttonName={COPY_TO_CLIPBOARD_BUTTON_LABEL}
      data-dtname={COPY_TO_CLIPBOARD_BUTTON_LABEL}
      onClick={handleCopy}
    >
      <img data-dtname={COPY_TO_CLIPBOARD_BUTTON_LABEL} alt="" src={COPY_ICON} />
    </ButtonContainer>
  );
}

export default CopyToClipboardButton;
