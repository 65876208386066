import { IAttribute } from '../../store/threekitSlicer';
import { getParams } from './navigationParams';
import { JOURNEYIOS_APPNAME, URL_BASE } from '../constants';
import { animatedLogoProgress, animatedMultiple } from '../../assets';
import { setActiveSurpriseMe } from '../../store/flowSlicer';
import { t } from 'i18next';

export const isMobileScreen = (): boolean => {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  return Boolean(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i.test(userAgent));
};

const isObject = (object: any): boolean => object != null && typeof object === 'object';

export const shallowCompare = (value1: any, value2: any): boolean => {
  if (typeof value1 !== typeof value2) return false;

  if (Array.isArray(value1)) {
    if (value1.length !== value2.length) return false;
    for (let i = 0; i < value1.length; i++) if (value1[i] !== value2[i]) return false;
  }

  if (typeof value1 !== 'object') return value1 === value2;

  const keys1 = Object.keys(value1);
  const keys2 = Object.keys(value2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (value1[key] !== value2[key]) {
      return false;
    }
  }

  return true;
};

export const deepCompare = (item1: any, item2: any): boolean => {
  if (typeof item1 !== typeof item2) return false;
  else if (Array.isArray(item1)) {
    if (item1.length !== item2.length) return false;
    for (let i = 0; i < item1.length; i++) if (!deepCompare(item1[i], item2[i])) return false;
  } else if (isObject(item1)) {
    const keys1 = Object.keys(item1);
    const keys2 = Object.keys(item2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!deepCompare(item1[key], item2[key])) return false;
    }
  } else if (item1 !== item2) return false;

  return true;
};

export const attrNameToRegExp = (name: string | RegExp): RegExp =>
  typeof name === 'string' ? new RegExp(`${name} [0-9]`) : name;

export const dataURItoFile = (dataURI: string, filename: string): File => {
  var arr = dataURI.split(','),
    //@ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const copyTextToClipboard = async (text: string): Promise<void> => {
  await navigator.clipboard.writeText(text);
};

export const easeInOutCubic = (val: number): number =>
  val < 0.5 ? 4 * val * val * val : 1 - Math.pow(-2 * val + 2, 3) / 2;

export const metadataValueToObject = (data: string): Record<string, number | string> =>
  data.split(',').reduce((output, keVal) => {
    const [key, value] = keVal
      .trim()
      .split('=')
      .map((el) => el.trim());
    return Object.assign(output, { [key]: parseFloat(value) || value });
  }, {});

export const isEmptyObj = (obj: object): boolean => Object.keys(obj || {}).length === 0 && obj?.constructor === Object;

export const isEmpty = (variable: any): boolean => {
  if (variable === undefined || variable === null) {
    return true;
  }
  if (typeof variable === 'number' && isNaN(variable)) {
    return true;
  }
  if (typeof variable === 'string' && variable.trim().length > 0) {
    return false;
  }
  if (Array.isArray(variable) && variable.length > 0) {
    return false;
  }
  if (typeof variable === 'object' && Object.keys(variable).length > 0) {
    return false;
  }
  return true;
};

export const addOpacityAnimation = (elementId: string, time: number = 500, mode?: string) => {
  const playerEl = document.getElementById(elementId);
  const animationToDisplay = mode ? `opacity-animation-${mode}` : 'opacity-animation';
  if (!playerEl) return;
  playerEl.classList.add(animationToDisplay);
  setTimeout(() => {
    playerEl.classList.remove(animationToDisplay);
  }, time);
};

export const createScriptIfRequired = (): void => {
  if (!document.querySelector('script[src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"]')) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js';
    document.body.appendChild(script);
  }
};

export function getEventPosition(event: MouseEvent | TouchEvent) {
  if (event?.type && event.type.startsWith('touch')) {
    const touchEvent = event as TouchEvent;
    const touch = touchEvent?.changedTouches[0] || touchEvent?.touches[0];
    return { x: touch?.clientX, y: touch?.clientY };
  } else {
    const mouseEvent = event as MouseEvent;
    return { x: mouseEvent?.clientX, y: mouseEvent?.clientY };
  }
}

export function isPinchZoom(event: MouseEvent | TouchEvent) {
  if (event?.type && event?.type?.startsWith('touch')) {
    const touchEvent = event as TouchEvent;
    const touch = touchEvent.changedTouches || touchEvent.touches || [];
    return touch?.length > 1;
  } else {
    return false;
  }
}
type coordinate = { x: number; y: number };

export function isWithinTapArea(pos1: coordinate, pos2: coordinate, tolerance: number = 25): boolean {
  const dx = pos1.x - pos2.x;
  const dy = pos1.y - pos2.y;
  return dx * dx + dy * dy < tolerance * tolerance;
}

export const hasPatch = (): boolean => {
  const attributes3D = window.threekit?.configurator
    ?.getDisplayAttributes()
    ?.find(
      (el: any) =>
        el.values?.find(
          (attr: IAttribute) => attr.metadata?.isPlayer3D == 'true' && attr?.assetId == el?.value?.assetId
        )
    );

  if (!attributes3D) return false;

  const yesAttributes = window?.dataDrivenConfiguratorExtension
    ?.getStatus()
    ?.validAttributesAndTheirValues_typeB.find((attr: any) => attr?.name === attributes3D?.name);
  const yes3D = yesAttributes?.values?.find((el: any) => el.selected)?.name === 'yes';
  return yes3D;
};

export const isTextAttribute = (currentGroup: Record<string, string>) => {
  let isTextAttribute = false;
  const hasPatch = Object.keys(currentGroup).findIndex((element) => element === 'has patch');
  const hasText = Object.keys(currentGroup).findIndex((element) => element === 'perso + / patch text line 1');

  const hasLockNumber = Object.keys(currentGroup).findIndex((element) => element === 'has lock number');
  const persoLockNumber = Object.keys(currentGroup).findIndex((element) => element === 'perso + / Lock number exists');

  if ((hasPatch !== -1 || hasLockNumber !== -1) && (hasText !== -1 || persoLockNumber !== -1)) {
    isTextAttribute = true;
  }
  return isTextAttribute;
};
export const isRightToLeft = (): boolean => {
  const params = getParams();
  return params.lng === 'ara' || document.documentElement.dir === 'rtl';
};

export const isRTLCharacter = (char: string): boolean => {
  const rtlCharacters = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlCharacters.test(char);
};

export const extractGroupNameFromGroupKey = (groupKey: string): string => {
  const name = groupKey.split('_').slice(1).join('-');
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const findValueInDataDriven = (assetId: string): any => {
  const dataDrivenAttributes =
    window?.dataDrivenConfiguratorExtension?.getStatus()?.validAttributesAndTheirValues_typeB;
  const allValues = dataDrivenAttributes.map((el: any) => el.values).flat();
  const value = allValues.find((value: any) => value.assetId === assetId);
  return value;
};

export const getMergedAttributesAndGrouped = ({
  attributes: updatedAttributes,
}: {
  attributes: Record<string, IAttribute>;
}) => {
  const mergedAttributesWithDataDriven: Record<string, IAttribute> = {};
  const mergedAttributesWithDataDrivenGrouped: Record<string, IAttribute[]> = {};
  const dataDrivenStatus = window?.dataDrivenConfiguratorExtension?.getStatus();
  if (dataDrivenStatus) {
    const validAttributesAndTheirValues_typeB = dataDrivenStatus.validAttributesAndTheirValues_typeB;
    validAttributesAndTheirValues_typeB?.forEach((attribute: any) => {
      const fullAttributeData = {
        ...attribute,
        ...updatedAttributes[attribute.name],
        values: attribute?.values?.map((value: any) => {
          return {
            ...value,
            ...updatedAttributes[attribute.name]?.values?.find((el: any) => el.name === value.name),
          };
        }),
      };
      const translatedGroupName = attribute?.groupName
        ? t(attribute?.groupName, extractGroupNameFromGroupKey(attribute?.groupName || ''))
        : t(attribute?.name || attribute?.displayName, attribute?.displayName);
      const translatedGroupNameSTR =
        typeof translatedGroupName === 'string'
          ? translatedGroupName
          : attribute?.groupName || attribute?.name || attribute?.displayName;
      mergedAttributesWithDataDriven[attribute.name] = {
        ...fullAttributeData,
      };
      if (!mergedAttributesWithDataDrivenGrouped[translatedGroupNameSTR]) {
        mergedAttributesWithDataDrivenGrouped[translatedGroupNameSTR] = [fullAttributeData];
      } else {
        mergedAttributesWithDataDrivenGrouped[translatedGroupNameSTR].push(fullAttributeData);
      }
    });
    return [mergedAttributesWithDataDriven, mergedAttributesWithDataDrivenGrouped];
  }
};

export const getSelectedSkus = (groupedAttributes: Record<any, any>): String[] => {
  return (
    Object.values(groupedAttributes)
      ?.map(
        (group) =>
          group
            ?.map((product: any) => product?.values?.find((element: any) => element?.selected === true)?.sku)
            .filter((sku: string) => sku !== 'NA')
            .toString()
      )
      .filter((item) => item?.length > 0) || ''
  );
};

export const isChooseValue = (value: any) => value?.name?.toUpperCase()?.startsWith('CHOOSE');

export const constructImageUrl = (url: string, format: string) => {
  if (!url) return url;
  try {
    const urlObj = new URL(url);

    const baseURL = urlObj?.origin + urlObj?.pathname;

    const queryParams = urlObj?.search;

    const formattedURL = `${baseURL},${format}`;

    const finalURL = `${formattedURL}${queryParams}`;

    return finalURL;
  } catch (error) {
    console.error('Error while constructing image URL', error);
    return url;
  }
};

export const getChooseValue = (values: any[]) =>
  values.find((value) => value?.name?.toUpperCase()?.startsWith('CHOOSE'));

export const sendMail = async ({
  email = '',
  subject = '',
  body = '',
}: {
  email?: string;
  subject?: string;
  body?: string;
}) => {
  const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
  window.location.href = mailtoLink;
};

const formatedDate = () => {
  const date = new Date();
  const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  return formattedDate;
};

export const openTuto = () => {
  const isTutoDisplayed = localStorage.getItem('isTutoDisplayed');
  if (isTutoDisplayed && isTutoDisplayed === formatedDate().toString()) {
    return false;
  } else {
    const openedDate = formatedDate().toString();
    localStorage.setItem('isTutoDisplayed', openedDate);
    return true;
  }
};

export function focusOnTargetBox(
  { a: { x: ax = 0, y: ay = 0, z: az = 0 }, b: { x: bx = 1, y: by = 1, z: bz = 1 } },
  rotation = 0,
  stabilized = true,
  isMobile: boolean
) {
  let Ax;
  const canvas = document.getElementById('canvas') as HTMLCanvasElement;
  const container = document.getElementById('threekit-player-2d');

  // Convert the rotation in degrees to radians
  const radians = rotation * (Math.PI / 180);

  // Compute the radius of the circle that is "drawn" by the point A when it is rotated around the y axis
  const radiusBoxA = Math.sqrt(Math.pow(ax - 0.5, 2) + Math.pow(0.5 - az, 2));
  if (radiusBoxA === 0 || rotation === 0) {
    // If the point A is in the center of the box, or if we do not have any rotation, we do not need to do trigonometry
    Ax = ax;
  } else {
    // Compute the initial angle of the point A
    var thetaA = Math.acos((ax - 0.5) / radiusBoxA);
    // Math.acos always return theta in [0, PI] because +theta and -theta have the same cosinus
    // But cos(radians + theta) is generally not equal to cos(radians - theta), so it is important to know if our "real" theta is in [0, PI] or [0, -PI]
    // To do so, we use az to determine if theta is positive or negative
    // The "|| 1" is useful in case az is 0.5: Math.sign(0) returns 0, but that would nullify the value of theta.
    // This would be an issue because if az === 0.5, theta could either be 0 or PI, and cos(radians + PI) !== cos(radians + 0).
    // So, if Math.sign returns 0 (which is falsy), we replace it with 1 (since anyway cos(radians + PI) is equal to cos(radians - PI))
    const orientedThetaA = (Math.sign(0.5 - az) || 1) * thetaA;
    // Compute the cosinus of the initial angle plus the additionnal rotation
    const cosRotatedA = Math.cos(thetaA + radians);
    // The cosinus is in [-1, 1], that correspond to [-radiusBoxA, radiusBoxA] in the main box coordinates, plus 0.5 because the main box is centered in (0.5, 0.5)
    Ax = cosRotatedA * radiusBoxA + 0.5;
  }
  // Good news is, the y coordinate does not change with the rotation around the y axis
  let Ay = ay;
  let Bx;
  // We do the same for B
  const radiusBoxB = Math.sqrt(Math.pow(bx - 0.5, 2) + Math.pow(0.5 - bz, 2));
  if (radiusBoxB === 0 || rotation === 0) {
    Bx = bx;
  } else {
    const thetaB = (Math.sign(0.5 - bz) || 1) * Math.acos((bx - 0.5) / radiusBoxB);
    const cosRotatedB = Math.cos(thetaB + radians);
    Bx = cosRotatedB * radiusBoxB + 0.5;
  }
  let By = by;

  // If Ax is bigger than Bx, we swap them
  // so A is always the top left corner of the box and B the bottom right corner
  if (Ax > Bx) {
    let temp = Ax;
    Ax = Bx;
    Bx = temp;
  }

  // console.log("Focus on target box", { ax, Ax, Ay, az }, { bx, Bx, By, bz }, rotation)
  let targetWidth;
  let targetHeight;
  // Compute the size and ratio of the target box
  if (stabilized) {
    // If we want to stabilize the box, we use the same width and height for the target box at all the angles
    // It removes the "zoom in / zoom out" effect you can have in two situations:
    // 1 - when the target box is not a cube, but a rectangular box, the width and height of the target box will change depending on the angle
    // 2 - when the box is rotated, it is "wider" when we look at it from 45° angles than when we look at it from 0° or 90° angles
    targetWidth = bx - ax;
    targetHeight = by - ay;
  } else {
    targetWidth = Bx - Ax;
    targetHeight = By - Ay;
  }
  const targetRatio = targetWidth / targetHeight;

  // Compute the size and ratio of the container
  const containerWidth = container?.clientWidth;
  const containerHeight = container?.clientHeight;
  const containerRatio = containerWidth! / containerHeight!;

  // We know that the initial size (visually speaking) of the canvas (before any transform) is made to fit inside the container
  // Must be coherent with the css rule: #canvas-item { width: min(100cqw, 100cqh);  height: min(100cqw, 100cqh); }
  const initialSize = Math.min(containerWidth!, containerHeight!);

  let scale = 1;
  if (targetRatio > containerRatio) {
    // The target is wider than the container
    // We scale the canvas so the target width is equal to the container width
    scale = containerWidth! / (targetWidth * initialSize);
  } else {
    // The target is taller than the container
    // We scale the canvas so the target height is equal to the container height
    scale = containerHeight! / (targetHeight * initialSize);
  }
  scale += isMobile ? 0 : 0;

  // We translate in such way the center of the target box is in the center of the container
  const translateX = (0.5 - (Bx + Ax) / 2) * 100;
  const translateY = (0.5 - (By + Ay) / 2) * 100;

  // Apply the scale and translate transformations to the canvas
  // Note: be careful, scaling than translating is not the same as translating than scaling

  container!.style.transform = `translate(${translateX}%, ${translateY}%) scale(${scale}) `;

  // TODO for the "non stabilized" version: using only 2 points A & B is not enough to determine correctly the 3D target box:
  // If A is the most left point (and most back) and B the most right point (and most front) at angle 0, then after having rotated 135°, they are both aligned in the middle of the box and do not reflect anymore the "width" of the target box
  //   A --- +   ⋰ + ⋱   + --- B   ⋰ B ⋱
  //   |     |  A     B  |     |  +      +
  //   + --- B   ⋱ + ⋰   A --- +   ⋱ A ⋰
  //      0°      45°      90°      135°
  // This schema represent the top view of the target box; on this view, the absice is the x coordinate and the ordinate is the z coordinate
  // We should track the position of all the 8 points of the box (or at least 4), and keep into account the ones that are the most left, right, top, bottom, front and back at any time

  // TODO for the "stabilized" version: instead of computing Ax and Bx, and then make the sum of both in the translation,
  // we could directly compute the center C of the target box and apply once the trigonometric functions to compute the impact of the rotation
  // And we could probably find ways to simplify the algorithm if we are only looking for this "center" movement
}
export const surpiseMeAnimation = ({ dispatch }: any) => {
  const canvas = <HTMLCanvasElement>document.getElementById('canvas-annimation');
  const ctx = canvas!.getContext('2d');

  // document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.display = 'flex';
  const multiple_image = new Image();

  multiple_image.onload = function () {
    canvas!.style.opacity = '100';
    document.getElementById('canvas')!.style.opacity = '0';
    const widthResolution = Math.max(window.innerWidth, document.documentElement.clientWidth);
    const heightResolution = Math.min(window.innerHeight, document.documentElement.clientHeight);
    const isMobile = widthResolution <= 769 || heightResolution > widthResolution
  
    let width = 0;
    let height = 0;
    let directionWidth = 1024;
   
    const ctxDrawImageValue = isMobile ? 512 : 1024;
    const multipleProduct = setInterval(() => {
      if (width === 0 && directionWidth === -1024) {
        dispatch(setActiveSurpriseMe(false));

        canvas!.style.opacity = '0';

        document.getElementById('canvas')!.style.opacity = '100';

        //document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.display = 'block';

        clearInterval(multipleProduct);
      } else if (width === 6144 && directionWidth === 1024) {
        directionWidth = -1024;
        width = width + directionWidth;
        ctx?.clearRect(0, 0, 1024, 1024);
      

        ctx?.drawImage(multiple_image, width, height, directionWidth, 1024, 0, 0, ctxDrawImageValue, ctxDrawImageValue);
      } else if (width === 0) {
        ctx?.drawImage(multiple_image, width, height, directionWidth, 1024, 0, 0, ctxDrawImageValue, ctxDrawImageValue);
        width = width + directionWidth;
        document.getElementById('canvas')!.style.opacity = '0';
      } else {
        ctx?.clearRect(0, 0, 1024, 1024);

        ctx?.drawImage(multiple_image, width, height, directionWidth, 1024, 0, 0, ctxDrawImageValue, ctxDrawImageValue);
        width = width + directionWidth;
      }
    }, 250);
  };
  multiple_image.src = animatedMultiple;
};

export const logoLoadingProgress = ({ loadingPercent, playerSize: { clientWidth, clientHeight } }: any) => {
  const canvas = document.getElementById('canvas-loading-progress') as HTMLCanvasElement;
  const ctx = canvas!.getContext('2d');

  // Set canvas position, zIndex, and centering styles
  canvas!.style.position = 'absolute';
  canvas!.style.zIndex = '9999999999999999';
  canvas!.style.left = '50%';
  canvas!.style.top = '50%';
  canvas!.style.transform = 'translate(-50%, -50%)'; // Center the canvas with transform

  // Set canvas dimensions (e.g., 600x600)
  const canvasWidth = 600;
  const canvasHeight = 600;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  const totalImages = 11; // Total frames in the sprite sheet
  const spriteOriginalWidth = 512; // Width of each frame in the sprite sheet
  const spriteOriginalHeight = 512; // Height of each frame in the sprite sheet

  // Scale the image down (adjust the scale factor as needed)
  const scaleFactor = 0.25; // Adjust this to control the size of the sprite
  const spriteWidth = spriteOriginalWidth * scaleFactor;
  const spriteHeight = spriteOriginalHeight * scaleFactor;

  const spriteSheet = new Image();

  spriteSheet.onload = function () {
    const drawImageBasedOnPercent = () => {
      // Calculate which frame to display based on loadingPercent
      const imageIndex = Math.min(Math.floor((loadingPercent / 100) * totalImages), totalImages - 1);
      const sx = imageIndex * spriteOriginalWidth; // Horizontal frame selection

      // Center the frame within the canvas
      const dx = (canvasWidth - spriteWidth) / 2; // Center horizontally in the canvas
      const dy = (canvasHeight - spriteHeight) / 2; // Center vertically in the canvas

      // Step 1: Fade out the canvas
      canvas.style.opacity = '0'; // Hide the canvas

      // Step 2: Wait for the fade-out to complete, then change the frame
      setTimeout(() => {
        // Clear the canvas and draw the new frame centered in the canvas
        ctx?.clearRect(0, 0, canvas.width, canvas.height);
        ctx?.drawImage(
          spriteSheet,
          sx,
          0,
          spriteOriginalWidth,
          spriteOriginalHeight, // Select current frame from sprite sheet
          dx,
          dy,
          spriteWidth,
          spriteHeight // Always draw the frame centered in the canvas
        );

        // Step 3: Fade back in
        setTimeout(() => {
          canvas.style.opacity = '1';
        }, 50);
      }, 50);
    };

    // Call the function to draw the frame based on current loadingPercent
    drawImageBasedOnPercent();
  };

  spriteSheet.src = animatedLogoProgress; // Set your sprite sheet image source here
};

export const isTouchScreenFunc = () => 'ontouchstart' in window || navigator.maxTouchPoints;

export const scanAction = (
  isChina: boolean,
  postData: Record<string, any>,
  attributeNames: string[],
  scannedSkus: any[]
) => {
  let skus = [];
  let data = postData;
  const allAttribute = window.dataDrivenConfiguratorExtension?.getStatus()?.validAttributesAndTheirValues_typeB;
  if (!attributeNames?.length) {
    return;
  }
  if (attributeNames?.length === 1) {
    let newSkus = allAttribute.find((e: Record<string, any>) => e?.name === attributeNames[0])?.values;
    skus = newSkus?.map((item: Record<string, any>) => {
      const findScannedSku = scannedSkus.find((element) => element.assetId === item.assetId);
      if (findScannedSku) {
        item.rfid = findScannedSku.rfid;
        item.batchNumber = findScannedSku?.batchNumber;
      }
      if (item?.selected) {
        item.isSelected = true;
        delete item?.selected;
      }
      return item;
    });
  } else {
    const testskus = allAttribute.find((element: any) => element?.name === attributeNames[0]).values;
    const newSkus = attributeNames?.reduce((acc: any, name) => {
      allAttribute
        .find((element: any) => element?.name === name)
        .values.map((item: any, key: number) => {
          const findScannedSku = scannedSkus.filter((element) => element.assetId === item.assetId);

          const findIndex = acc.findIndex((indexE: any) => indexE?.assetId === item?.assetId);
          if (findScannedSku.length) {
            findScannedSku.map((scannedSku) => {
              let element = acc[findIndex];
              if (element.selected === true) {
                element.isSelected = true;
                delete element.selected;
              }
              acc.push({ ...element, ...{ rfid: scannedSku.rfid, batchNumber: scannedSku.batchNumber } });
            });
            delete acc[findIndex];
          } else {
            if (item.selected === true) {
              if (findIndex && item?.sku !== 'NA') {
                acc[findIndex].isSelected = item?.selected;
                if (acc[findIndex]?.selected) {
                  delete acc[findIndex]?.selected;
                }
              }
            }
          }
        });

      return acc;
    }, testskus);
    skus = newSkus.slice(1);
  }
  data.skus = skus;

  //@ts-ignore
  wx?.miniProgram.getEnv(function (res) {
    if (res.miniprogram) {
      //@ts-ignore
      wx?.miniProgram.navigateTo({
        url: `/pages/camera-view/camera-view`,
      });
    }
  });

  if (window.webkit?.messageHandlers?.scanAction) {
    window.webkit.messageHandlers.scanAction?.postMessage(data);
  }
};

export const isAllScanned = (scannedSkus: Record<string, string>[], appName?: string) => {
  let result = true;
  appName === JOURNEYIOS_APPNAME
    ? Object.values(window?.dataDrivenConfiguratorExtension?.getStatus()?.attributes)?.map((item: any, key) => {
        if (item !== 'NA') {
          const findElement = scannedSkus?.find((e: any) => e?.sku === item);
          if (!findElement) {
            result = false;
          }
        }
      })
    : (result = true);
  return result;
};

export const formatAssetUrlForChina = ({ isChina, url }: { isChina: boolean; url: string }) =>
  `${URL_BASE}${isChina ? url?.replace('media/ww', 'media/cn') : url}`;

export const isVideoUrl = (url: string) => {
  const videoExtensions = ['.mp4', '.mkv', '.avi', '.mov', '.wmv', '.flv', '.webm', '.ogg'];
  const extension = url?.split('.')?.pop()?.toLowerCase();
  return videoExtensions.includes('.' + extension);
};
