import styled from 'styled-components';
import { PLAYER_GREY_BACKGROUND } from '../../assets';

export const SliderContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isFullScreenOpen', 'isSlideView'].includes(prop),
})<{isFullScreenOpen: boolean, isSlideView: boolean}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    isFullScreenOpen,
    isSlideView
  }) => `
    display: flex;
    flex-direction: column;
    gap: ${isMobile ? '' : 'max(40px, 12%)'};
    align-items: center;
    margin: auto;
    height: 100%;
    cursor: pointer;
    ${!isSlideView ? `{
      gap: 0;
    }` : ''}
      transition: all 0.5s ease;
    ${isFullScreenOpen ?
      `
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background-image: url(''), url('${PLAYER_GREY_BACKGROUND}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain, cover;
      cursor: auto !important;
    ` : ``}
  `
);


export const AssetsContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isSlideView', 'scrollDownDesktop', 'isFullScreenOpen'].includes(prop),
})<{isSlideView?: boolean; scrollDownDesktop?: boolean; isFullScreenOpen?: boolean}>(
  ({
    theme: {
      device: { isMobile },
    },
    isSlideView,
    scrollDownDesktop,
    isFullScreenOpen,
  }) => `
  display: flex;
  overflow-x: hidden;
    height: 100%;
  ${!isSlideView && scrollDownDesktop && !isFullScreenOpen ? `
    flex-direction: column;
    > div {
     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
     }
    ` : ``}
`
);

export const AssetContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isError', 'isFullScreenOpen', 'isSlideView'].includes(prop),
})<{isError?: boolean; isFullScreenOpen?: boolean, isSlideView: boolean}>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    isError,
    isFullScreenOpen,
    isSlideView,
  }) => `
  ${!isFullScreenOpen && isSlideView ? `
    flex: 0 0 auto;
    height: 100%;
  ` : ''}
  ${isFullScreenOpen ? `
    flex: 0 0 auto;
    height: 100%;` : ''}
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: ${isError ? 'cover' : 'contain'};
  }
`);


const IconBtnWrapperCss = `
  width: 38px;
  height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  user-select: none;
  &:hover {
    border: 1px solid rgb(0, 0, 0, 0.7);
  }
  &:active {
    border: 1px solid rgb(0, 0, 0, 0.9);
    background-color: rgba(255, 255, 255);
  }
`;

const ArrowWrapperCss = `
  ${IconBtnWrapperCss}
  > img {
    height: 12px;
  }
`;

export const CloseIconWrapper = styled.div`
  ${IconBtnWrapperCss}
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const LeftArrow = styled.div`
  ${ArrowWrapperCss}
  left: 20px;
`;

export const RightArrow = styled.div`
  ${ArrowWrapperCss}
  right: 20px;
`;

export const ProgressBarContainer = styled.div(
  ({
    theme: {
      device: { isMobile },
    },
  }) => `
  display: flex;
  margin: ${isMobile ? 'auto 0 0 0' : '0 auto 20px'};
  width: 100%;
`
);
