import React from 'react';
import i18n from 'i18next';
import { LoaderContainer } from './LVLoader.styles';
import { LV_LOADER } from '../../assets';

interface LVLoaderProps {
  display?: boolean;
  isPlayerLoading?: boolean;
  isPrefetchLoading?: boolean;
  currentMode?: string;
  currentSize?: {
    height: number;
    width: number;
  };
}

const loader = LV_LOADER;

const LVLoader: React.FC<LVLoaderProps> = ({
  display,
  isPlayerLoading,
  isPrefetchLoading,
  currentMode,
  currentSize,
}) => {
  return !i18n.isInitialized || display || isPlayerLoading || isPrefetchLoading ? (
    <LoaderContainer currentMode={currentMode!} playerSize={currentSize!} layoutPosition={isPrefetchLoading}>
      <img src={loader} alt={'loading animation'} />
    </LoaderContainer>
  ) : null;
};

export default LVLoader;
