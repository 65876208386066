import { Buffer } from 'buffer';
import { JOURNEYIOS_APPNAME, TK_SAVED_CONFIG_PARAM_KEY, TK_SAVED_CONFIG_PARAM_KEY2, TK_SAVED_CONFIG_PARAM_KEY3, WECHAT_APPNAME } from "../../utils/constants";

export const handleItemAction = async ({
  action,
  isChina,
  configurationSavedData,
  appName,
  clientUrl,
  globalSettingsParams,
  dataDrivenSku,
  productName,
  scannedSkus,
  setClicked,
  isAllInStock
}: any) => {
  setClicked(true);
  let json: any = {
    ...configurationSavedData,
    createdAt: new Date().toISOString(),
    clientUrl,
    productName,
    inStore: configurationSavedData?.inStore || false,
    inStock: isAllInStock,
    kit_item_sku: dataDrivenSku.slice(1),
    sku_item: dataDrivenSku[0],
    skus: dataDrivenSku,
  };
  if (appName === JOURNEYIOS_APPNAME) json.scannedSkus = scannedSkus;
  console.log(json);
  let parsedJsonToSend: any = { ...json };

  parsedJsonToSend.threekitID =
    globalSettingsParams[TK_SAVED_CONFIG_PARAM_KEY] ||
    globalSettingsParams[TK_SAVED_CONFIG_PARAM_KEY2] ||
    globalSettingsParams[TK_SAVED_CONFIG_PARAM_KEY3] ||
    '';
  parsedJsonToSend.action = action;
  const recipeId =
    globalSettingsParams[TK_SAVED_CONFIG_PARAM_KEY] ||
    globalSettingsParams[TK_SAVED_CONFIG_PARAM_KEY2] ||
    globalSettingsParams[TK_SAVED_CONFIG_PARAM_KEY3] ||
    '';

  if (isChina) {
    if (appName !== JOURNEYIOS_APPNAME) {
      parsedJsonToSend = Buffer.from(JSON.stringify(parsedJsonToSend)).toString('base64');
    }

    if (appName === WECHAT_APPNAME) {
      console.log(`We chat navigate to /sub/goodsDetails/goodsDetails?recipeId=${recipeId}&from=lucky-louis-h5`);
      //@ts-ignore
      wx?.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          // @ts-ignore
          wx?.miniProgram.navigateTo({
            url: `/sub/goodsDetails/goodsDetails?recipeId=${recipeId}&from=lucky-louis-h5`,
          });
        }
      });
    } else {
      //@ts-ignore
      wx?.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          //@ts-ignore
          wx.miniProgram.postMessage({
            data: { threekitPersoProduct: parsedJsonToSend },
          });
          // @ts-ignore
          wx?.miniProgram.switchTab({
            url: `packageA/scanKitting/scanKitting`,
          });
        }
      });
    }
  }

  const eventDataShare = {
    eventName: 'handleItemAction',
    eventData: parsedJsonToSend,
  };
  window.parent.postMessage(eventDataShare, '*');
  console.log({ parsedJsonToSend });

  if (window.webkit?.messageHandlers?.handleItemAction) {
    window.webkit.messageHandlers.handleItemAction.postMessage({
      message: parsedJsonToSend,
    });
    console.log('handleItemAction', parsedJsonToSend);
  } else {
    console.info('handleItemAction', parsedJsonToSend);
  }

  setTimeout(() => {
    console.log('AddToCartReleased');
    setClicked(false);
  }, 10000);
};