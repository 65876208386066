import { t } from 'i18next';
import { ButtonContainer } from '../buttons.style';
import { ADDTOBAG_BUTTON_LABEL } from '../constants';
import { handleItemAction } from '../utils';
import { useSelector } from 'react-redux';
import { getIsAllSelectedValuesInStock, getIsChina, getScannedSkus } from '../../../store/threekitSlicer';
import { useMemo, useState } from 'react';
import { TK_SAVED_CONFIG_PARAM_KEY } from '../../../utils/constants';
import { getGlobalSettingsParams } from '../../../store/globalSettingsSlicer';
import { useParams } from 'react-router-dom';
import { getSavedConfigurationData } from '../../../store/threekitSlicer/selectors/savedConfigurationData';
import { useProductName } from '../../../hooks';

function AddToBagButton() {
  const [clicked, setClicked] = useState<boolean>(false);
  const isChina = useSelector(getIsChina);
  const configurationSavedData = useSelector(getSavedConfigurationData);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const scannedSkus = useSelector(getScannedSkus);
  const isAllInStock = useSelector(getIsAllSelectedValuesInStock);
  
  const { appName = '' } = globalSettingsParams || {};
  const { baseUrlClient } = useParams();
  const dataDrivenSku = window.dataDrivenConfiguratorExtension?.getStatus()?.skus;
  const productName = useProductName();

  const clientUrl = useMemo((): string => {
    if (!globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY] || !baseUrlClient) return '';

    const result = `https://${baseUrlClient}/${globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY]}`;
    return encodeURI(result);
  }, [globalSettingsParams, baseUrlClient]);


  return (
    <ButtonContainer
      buttonName={ADDTOBAG_BUTTON_LABEL}
      data-dtname={ADDTOBAG_BUTTON_LABEL}
      onClick={() => {
        handleItemAction({
          action: 'cart',
          isChina,
          configurationSavedData,
          appName,
          clientUrl,
          globalSettingsParams,
          dataDrivenSku,
          productName,
          scannedSkus,
          setClicked,
          isAllInStock
        });
      }}
    >
      {t('button.label.add_to_bag', {
    defaultValue: ADDTOBAG_BUTTON_LABEL,
  })}
    </ButtonContainer>
  );
}

export default AddToBagButton;
