import { enqueueSnackbar, closeSnackbar, VariantType, SnackbarOrigin } from 'notistack';
import { t } from 'i18next';
import { popUpPosition } from './PopupPosition';
import { PopUpImgWrapper, PopUpWrapper } from './showPopUp.styles';

export interface PopUpType {
  popUpMessage: string;
  popUpMessageKey: string;
  autoHideDuration?: number;
  preventDuplicate?: boolean;
  variant?: VariantType;
  style?: any;
  [key: string]: any;
}

interface ShowPopUpParams {
  popUpType: PopUpType;
  message?: string;
  isMobile?: boolean;
  functionOnClose?: () => void;
  img?: string;
}

export function showPopUp({
  popUpType,
  message,
  img,
  functionOnClose,
}: ShowPopUpParams): void {
  const {
    popUpMessage,
    popUpMessageKey,
    autoHideDuration,
    preventDuplicate,
    variant,
    style,
    outOfStockStyle,
  } = popUpType || {};

  const messageToDisplay = message
    ? t(popUpMessageKey, {
      field: message,
      defaultValue: `${popUpMessage}: ${message}`,
    })
    : t(popUpMessageKey, popUpMessage);
  if (!messageToDisplay) return;
  const width = Math.max(
    window.screen.width,
    window.innerWidth,
    document.documentElement.clientWidth
  );

  const height = Math.min(
    window.screen.height,
    window.innerHeight,
    document.documentElement.clientHeight
  );

  enqueueSnackbar(t(messageToDisplay, messageToDisplay), {
    autoHideDuration,
    preventDuplicate,
    variant,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    style,
    onClose: functionOnClose ? () => functionOnClose() : undefined,
    content: (key, message) => (
      <PopUpWrapper key={key} style={{ ...style }}>
        {(img || style.leftImage) && (
          <PopUpImgWrapper outOfStockStyle={outOfStockStyle} style={style.leftImageWrapperStyle}>
            <img src={img || style.leftImage} style={style.leftImageStyle} alt='img' />
          </PopUpImgWrapper>
        )}
        <div style={{ textAlign: 'left', margin: '10px 0 10px 10px' }}>
          {message}
        </div>
        <div
          onClick={() => closeSnackbar(key)}
          style={{
            cursor: 'pointer',
            fontSize: '18px',
            width: '100%',
            maxWidth: '50px',
            fontWeight: 'bolder',
            marginLeft: 'auto',
          }}
        >
          &#x2715;
        </div>
      </PopUpWrapper>
    ),
  });
}
