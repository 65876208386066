import { useDispatch, useSelector } from 'react-redux';
import { PLAYER_VIEWS, TK_PLAYER_DIV_ID_2D } from '../../utils/constants';
import { focusOnTargetBox } from '../../utils/function/functions';
import { useAttribute, useWindowSize } from '../../utils/threekitHooks';
import { getGroupedAttributes, setConfiguration, setIsRotable } from '../../store/threekitSlicer';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { setActiveSurpriseMe, setPlayerView } from '../../store/flowSlicer';
import Controller from '../../controller';
import { animatedMultiple, animatedMultipleCapucine, animatedRotation, animatedRotationCapucine } from '../../assets';
import { useCallback, useMemo } from 'react';

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

function usePlayerView() {
  const width = Math.max(window.innerWidth, document.documentElement.clientWidth);
  const height = Math.min(window.innerHeight, document.documentElement.clientHeight);
  const isMobile = width <= 769 || height > width
  const dispatch = useDispatch<AppThunkDispatch>();
  const [cameraAttribute] = useAttribute('Camera');
  const groupedAttributes = useSelector(getGroupedAttributes);
  const defaultCameraAssetId = cameraAttribute?.defaultValue?.assetId;
  const defaultPlayerViewData = useMemo(() => PLAYER_VIEWS?.default, []);
  const viewDataByGroupName = useMemo(() => {
    const res: Record<string, any> = {};
    if (!groupedAttributes) return res;
    Object.entries(groupedAttributes).forEach(([groupName, groupData]: [string, Record<string, any>[]]) => {
      const viewData = groupData?.[0]?.metadata?.zoomSetting;
      if (viewData) {
        res[groupName] = JSON.parse(viewData);
      }
    });
    return res;
  }, [groupedAttributes]);

  const changeView = useCallback(
    ({ newView }: { newView: string }) => {
      const playerViewData = newView === 'default' || !newView ? defaultPlayerViewData : viewDataByGroupName[newView];
      const playerView = isMobile ? playerViewData?.mobile : playerViewData?.desktop;
      const targetBox = playerView?.targetBox;
      const isRotatable = playerView?.isRotatable;
      const cameraAssetId = playerViewData?.cameraAssetId || defaultCameraAssetId;
      if (targetBox) focusOnTargetBox(targetBox, 1, true, isMobile);
      dispatch(setIsRotable(playerView?.isRotatable));
      dispatch(setConfiguration({ Camera: { assetId: cameraAssetId } }));
      dispatch(setPlayerView(newView === 'default' ? Object.keys(PLAYER_VIEWS)[0] : newView));
    },
    [defaultCameraAssetId, dispatch, isMobile, defaultPlayerViewData, viewDataByGroupName]
  );

  const launchAnimation = useCallback(
    async (isCapucines: boolean) => {
      try {
        let canvas: HTMLCanvasElement;
        if (!document.getElementById('canvas-annimation')) {
          canvas = document.createElement('canvas');
          canvas.setAttribute('id', 'canvas-annimation');
        } else {
          canvas = <HTMLCanvasElement>document.getElementById('canvas-annimation');
          return
        }

        const translate = isMobile ? '-50%' : '-50%';
        canvas.style.willChange = 'auto'
        canvas.width = isMobile ? 512 : 1024;
        canvas.height = isMobile ? 512 : 1024;
        canvas.style.position = 'absolute';
        canvas.style.left = '-50%';
        canvas.style.top = isMobile ? '50%' : '45%';
        let scale = isMobile ? 1 : 0.87;
        scale = isCapucines ? scale - 0.2 : scale;
        canvas.style.transform = `translate(${translate}, ${translate}) scale(${scale}) `;
        canvas.style.width = 'min(100cqw, 100cqh)';
        canvas.style.height = 'min(100cqw, 100cqh)';
        canvas.style.transition = 'left 1.5s ease-out';
        const ctx = canvas.getContext('2d');
        let swidth = isCapucines ? 4096 : 4096;
        let sheight = isCapucines ? 4096 : 4096;
        document.getElementById('threekit-player-2d')?.appendChild(canvas);
        const container = document.getElementById('threekit-player-2d');
        const base_image = new Image();
        const multiple_image = new Image();
        base_image.src = isCapucines ? animatedRotationCapucine : animatedRotation;
        base_image.onload = function () {
          let animationStart = false;
          let req: number;

          let lastDate: number | undefined;

          function draw(timestamp?: number) {
            if (timestamp && lastDate && lastDate + 188 > timestamp) {
              req = requestAnimationFrame(draw);
              return;
            }

            if (swidth !== 6144 && sheight !== 6144) {
              const ctxDrawImageValue = isMobile ? 512 : 1024;

              ctx?.clearRect(0, 0, ctxDrawImageValue, ctxDrawImageValue);
              ctx?.drawImage(base_image, swidth, sheight, 1024, 1024, 0, 0, ctxDrawImageValue, ctxDrawImageValue);
              swidth = swidth + 1024;
              if (swidth === 6144) {
                swidth = 0;
                sheight = sheight + 1024;
              }
              lastDate = timestamp;
              req = requestAnimationFrame(draw);
            } else {
              multiple_image.src = animatedMultiple;
              cancelAnimationFrame(req);
            }
            if (!animationStart) {
              canvas.style.left = '50%';
              animationStart = true;
            }
          }

          function drawCapucines(timestamp?: number) {
            if (timestamp && lastDate && lastDate + 166 > timestamp) {
              req = requestAnimationFrame(drawCapucines);
              return;
            }

            if (swidth !== base_image.width) {
              ctx?.clearRect(0, 0, 1024, 1024);

              ctx?.drawImage(base_image, swidth, sheight, 1024, 1024, 0, 0, 512, 512);
              swidth = swidth + 1024;

              lastDate = timestamp;
              req = requestAnimationFrame(drawCapucines);
            } else {
              multiple_image.src = animatedMultipleCapucine;
              cancelAnimationFrame(req);
            }
            if (!animationStart) {
              canvas.style.left = '50%';
              animationStart = true;
            }
          }

          if (isCapucines) {
            drawCapucines();
          } else {
            draw();
          }
        };
        multiple_image.onload = function (image) {
          let width = 0;
          let height = 0;
          let directionWidth = 1024;
          const multipleProduct = setInterval(() => {
            if (width === 4096) {
              clearInterval(multipleProduct);

              dispatch(setActiveSurpriseMe(false));
              const ctxDrawImageValue = isMobile ? 512 : 1024;
              ctx?.drawImage(multiple_image, width, height, directionWidth, 1024, 0, 0, ctxDrawImageValue, ctxDrawImageValue);

              Controller.attachPlayerToComponent(TK_PLAYER_DIV_ID_2D);
              if (document.getElementById('canvas')) {
                document.getElementById('canvas')!.style.opacity = '0';
                document.getElementById('canvas-annimation')!.style.opacity = '0';
                const isMobile = container!?.clientHeight >= container!?.clientWidth;
                const resolution = isMobile ? 512 : 2048;

                document.getElementById('canvas')!.style.width = isMobile ? '512px' : '2048px';
                document.getElementById('canvas')!.style.height = isMobile ? '512px' : '2048px';
                let scale = isMobile ? container!?.clientWidth / resolution : container!?.clientHeight / resolution - 0.05;
                scale = isCapucines ? scale - 0.1 : scale;
                document.getElementById('canvas')!.style.transform = `translate(-50%,-50%) scale(${scale})`;
                changeView({ newView: 'default' });

                document.getElementById('canvas')!.style.opacity = '100';
              }
              return;
            } else {
              const ctxValue = isMobile ? 512 : 1024;
              ctx?.clearRect(0, 0, ctxValue, ctxValue);
              ctx?.drawImage(multiple_image, width, height, directionWidth, 1024, 0, 0, ctxValue, ctxValue);
              width = width + directionWidth;
            }
          }, 300);
        };
      } catch (e) {
        console.log(e);
      }
    },
    [changeView, dispatch, isMobile]
  );

  return { launchAnimation, changeView, viewDataByGroupName };
}

export default usePlayerView;
