import styled, { css } from 'styled-components';

export const BackgroundWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['show'].includes(prop),
})<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ show }) => (show ? 'rgba(0, 0, 0, 0.52)' : 'transparent')};
  z-index: ${({ show }) => (show ? 99 : -1)};
  transition: ${({ show }) => (show ? 'all 0.7s ease' : 'all 0.2s ease')};
`;

export const FormContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isActiveAnimation'].includes(prop),
})<{ isActiveAnimation: boolean }>`
  position: absolute;
  width: 100%;
  bottom: ${({ isActiveAnimation }) =>
    isActiveAnimation
      ? `
  -248px;
`
      : '0'};
  display: flex;
  flex-direction: column;
  padding: 26px 0 10px;
  border-radius: 24px 24px 0 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow: hidden;
  transition: bottom 1s ease-in-out;
`;

export const TopFormContainer = styled.div.attrs({ className: 'TopFormContainer' })`
  display: flex;
  justify-content: space-between;
  max-height: 50%;
  padding: 0 25px;
`;

export const BottomFormContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const TitlePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
`;

export const SelectionSummaryIcon = styled.div.withConfig({
  shouldForwardProp: (prop) => !['img'].includes(prop),
})<{ img: string }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  z-index: 1;
  font-size: 0;
  margin-left: auto;
  @media (hover: hover) {
    &:hover {
      background-color: #f0f0f0;
    }
    &:active {
      background-color: #e0e0e0;
    }
  }
`;

export const BesideAttributesContainer = styled.div`
  display: flex;
  gap: 30px;
  min-width: 250px;
  width: 30%;
`;

interface IValuesContainer {
  theme: {
    device: { isMobile: boolean; isIpad: boolean; isDesktop: boolean };
  };
}
export const ValuesContainer = styled.div.attrs({
  className: 'ValuesContainer',
})<IValuesContainer>(
  ({
    theme: {
      device: { isMobile },
    },
  }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 ${isMobile ? '25px' : '50px'};
    transition: all 0.5s ease-in-out;
    ${!isMobile &&
    css`
      display: flex;
      overflow-x: auto;
      gap: 20px;
      height: -webkit-fill-available;
      align-items: center;
    `}
  `
);
