import styled from 'styled-components';

export const AttributesContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (orientation: landscape) {
    width: 40%;
    height: fit-content;
  }
`;

export const AttributesFlex = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['childrenLength'].includes(prop),
})<{ childrenLength: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.childrenLength}, 1fr);
  width: 100%;
  max-width: 500px;
  margin: 0 0 13px 0;
  padding: 0;

  @media (orientation: landscape) {
    height: 100%;
  }
`;
