import React, { useCallback } from 'react';
import {
  AttributeTitle,
  AttributeValues,
  AttributeWrapper,
  ConfigurationWrapper,
  ImgTextWrapper,
  PlusContainer,
  PlusImage,
  TitleValueWrapper,
  ValueImg,
  ValueImgWrapper,
  ValueWrapper,
} from './configurationSummary.styles';
import { closeRecap } from '../../store/flowSlicer';
import { getGroupedAttributes, getScannedSkus, IAttribute, setActiveGroup } from '../../store/threekitSlicer';
import { isChooseValue, scanAction } from '../../utils/function/functions';
import { PLUS_ATTRIBUTE_ICON, SCAN_ICON, VALID_ICON } from '../../assets';
import { useDispatch, useSelector } from 'react-redux';
import SelectedValuesOfAttribute from '../SelectedValuesOfAttribute';
import { getParams } from '../../utils/function/navigationParams';
import { JOURNEYIOS_APPNAME } from '../../utils/constants';
import { useProductType, useValuesStock } from '../../hooks';


function ConfigurationSummary({ page }: { page: 'recap' | 'summary' }) {
  const dispatch = useDispatch();
  const groupedAttributes = useSelector(getGroupedAttributes) || {};
  const scannedSkus = useSelector(getScannedSkus) || [];
  const { appName } = getParams();
  const { isCapucines } = useProductType();
  const isValuesInStock = useValuesStock();
  const isScanned = (product: any) => {
    let result = false;
    result = scannedSkus?.find((item: any) => item?.rfid && item?.sku === product?.sku) ? true : false;
    return result;
  };
  const isValid = (values: any) => {
    let result = false;

    result = values.every((element: any) => isScanned(element));
    return result;
  };

  const scanItem = (attributeNames: string[]) => {
    const data = {
      message: 'scanProductSkus',
      mustScan: true, // it's a configuration mode, the app has to select components, not only scan
      numberOfComponents: attributeNames.length, // the number of skus to be configured (ex: 1 for the bag, 3 for the charms, etc)
      canSelectMultipleTimes: isCapucines && attributeNames.length, // true if we can add several times the same sku, false otherwise
      skus: [],
    };
    scanAction(true, data, attributeNames, scannedSkus);
  };

  const singleAttributeComponent = useCallback(
    ({
      attribute,
      value,
      values,
      attributeDisplayName,
      groupName,
      inStock,
    }: {
      attribute: IAttribute;
      value: any;
      values: any[];
      attributeDisplayName?: string;
      groupName?: string;
      inStock: boolean;
    }): JSX.Element => {
      const isEmptyFirstValue = isChooseValue(value);
      const imageToUse = isEmptyFirstValue ? PLUS_ATTRIBUTE_ICON : value?.alternateThumbnailPath || value?.thumbnailPath;
      const isAlternateThumbnailPath = isCapucines ? false : !!value?.alternateThumbnailPath;
      return (
        <AttributeWrapper
          page={page}
          key={`${attributeDisplayName}-${value?.sku}`}
          onClick={() => {
            if (page === 'recap') {
              dispatch(closeRecap(true));
              dispatch(setActiveGroup(groupName || attribute?.displayName));
            }
          }}
        >
          <ImgTextWrapper
            page={page}
            onClick={() => {
              if (page === 'recap') {
                dispatch(closeRecap(true));
                dispatch(setActiveGroup(attribute?.name));
              }
            }}
          >
            <TitleValueWrapper>
              <AttributeTitle page={page} inStock={inStock}>
                {attributeDisplayName || attribute?.displayName || attribute?.name}
              </AttributeTitle>
              <SelectedValuesOfAttribute selectedValues={values} page="summary" />
              <ValueImgWrapper page={page} isEmpty={isEmptyFirstValue}>
                <ValueImg
                  page={page}
                  src={imageToUse}
                  alt={attribute?.name}
                  isAlternateThumbnailPath={isAlternateThumbnailPath}
                />
              </ValueImgWrapper>
            </TitleValueWrapper>
          </ImgTextWrapper>
        </AttributeWrapper>
      );
    },
    [page, dispatch, isCapucines]
  );

  const groupAttributeComponent = useCallback(
    ({ groupName, values }: { groupName: string; values: any[] }): JSX.Element => {
      const inStockGroup = isValuesInStock({ valuesToCheck: values })?.every((value: any) => value !== false);

      return (
        <AttributeWrapper
          page={page}
          key={`${groupName}-${values?.length}`}
          onClick={() => {
            if (page === 'recap') {
              dispatch(closeRecap(true));
              dispatch(setActiveGroup(groupName));
            }
          }}
        >
          <AttributeTitle page={page} inStock={inStockGroup}>
            {groupName}
          </AttributeTitle>
          <AttributeValues page={page}>
            {values?.length ? (
              values.map((value, idx) => {
                const isEmpty = isChooseValue(value);
                return (
                  <ValueWrapper key={`${value?.name}-${idx}`}>
                    <ValueImgWrapper page={page} isEmpty={isEmpty}>
                      <ValueImg
                        src={
                          isEmpty
                            ? PLUS_ATTRIBUTE_ICON
                            : value?.alternateThumbnailPath ||
                            value?.thumbnailPath ||
                            'https://lv-kitting.s3.eu-north-1.amazonaws.com/tutorial/product-new-0.png'
                        }
                        alt={value?.name}
                        isAlternateThumbnailPath={!!value?.alternateThumbnailPath}
                      />
                    </ValueImgWrapper>
                  </ValueWrapper>
                );
              })
            ) : (
              <PlusContainer img={PLUS_ATTRIBUTE_ICON}>
                <PlusImage src={PLUS_ATTRIBUTE_ICON} />
              </PlusContainer>
            )}
          </AttributeValues>
        </AttributeWrapper>
      );
    },
    [page, dispatch, isValuesInStock]
  );

  return (
    <ConfigurationWrapper page={page}>
      {Object.keys(groupedAttributes)?.map((key, index) => {
        const attributeNames = groupedAttributes[key].map((item) => item.name);
        const isGroup = groupedAttributes[key]?.length > 1;
        const values = groupedAttributes?.[key]
          ?.map((attribute) => attribute?.values?.filter((value: any) => value?.selected))
          ?.flat();
        const valid = isValid(values);
        const inStock = isValuesInStock({ valuesToCheck: values });

        return (
          <React.Fragment key={key}>
            {isGroup &&
              isCapucines &&
              values.map((v, idx) =>
                singleAttributeComponent({
                  attribute: groupedAttributes?.[key]?.[idx],
                  value: values?.[idx],
                  values: [values[idx]],
                  attributeDisplayName: key + ` ${idx + 1}`,
                  groupName: key,
                  inStock: inStock?.[idx] === true,
                })
              )}
            {isGroup && !isCapucines && groupAttributeComponent({ groupName: key, values })}
            {!isGroup &&
              singleAttributeComponent({
                attribute: groupedAttributes?.[key]?.[0],
                value: values?.[0],
                values,
                attributeDisplayName: key,
                groupName: key,
                inStock: inStock?.[0] === true,
              })}
            {appName === JOURNEYIOS_APPNAME &&
              (valid ? (
                <PlusContainer img={VALID_ICON} style={{ width: '25px', height: '25px' }}>
                  <PlusImage src={VALID_ICON} />
                </PlusContainer>
              ) : (
                <PlusContainer
                  img={SCAN_ICON}
                  style={{ width: '25px', height: '25px' }}
                  onClick={() => scanItem(attributeNames)}
                >
                  <PlusImage src={SCAN_ICON} />
                </PlusContainer>
              ))}
          </React.Fragment>
        );
      })}
    </ConfigurationWrapper>
  );
}

export default ConfigurationSummary;
