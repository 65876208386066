import { t } from 'i18next';
import { useActiveGroup } from '../../hooks';

function ActiveAttributeTitle() {
  const { attributeData, activeGroup, selectedValues } = useActiveGroup();
  const count = selectedValues.length;

  return attributeData?.groupName || attributeData?.name ? (
    <h2 style={{ fontSize: '18px', lineHeight: '24px', margin: 0, fontWeight: 400 }}>
      {t(`label.title.select.${attributeData?.groupName || attributeData?.name}`, `Select ${count > 1 ? `${count} ` : ''}${activeGroup}`, {
        count: count,
      })}
    </h2>
  ) : null;
}

export default ActiveAttributeTitle;
