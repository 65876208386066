import styled from 'styled-components';

export const RecapWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['show', 'openPercentage', 'slidePercentsToOpen'].includes(prop),
})<{
  show: boolean;
  openPercentage: number;
  slidePercentsToOpen: number;
}>(
  ({
    theme: {
      device: { isMobile },
    },
    show,
    openPercentage,
    slidePercentsToOpen,
  }) => {
    return `
    display: flex;
    flex-direction: column;
    padding: 20px 25px 20px;
    overflow-y: auto;
    opacity: ${
      openPercentage
        ? openPercentage >= slidePercentsToOpen
          ? openPercentage / 100
          : 0
        : openPercentage === 0
        ? 0
        : 1
    };
    transition: all 0.5s ease;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: ${show ? 100 : -1};
  ${
    !isMobile
      ? `
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%;
      margin: 0;
      background-color: #fff;
      @media (max-height: 760px) {
      padding: 40px 8% 20px;
      }
      @media (min-height: 765px) {
        padding: 100px 8% 40px;
      }
      transform: ${show ? `translateX(0)` : `translateX(100%)`};
    `
      : ``
  }
  `;
  }
);

export const DoneButtonWrapper = styled.div`
  @media (orientation: landscape) {
    width: 360px;
    margin: auto;
  }
`;

export const TitleCloseIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div(
  ({
    theme: {
      device: { isMobile },
    },
  }) => {
    return `margin-top: ${isMobile ? '30px' : '0'};`;
  }
);
