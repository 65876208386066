import { PayloadAction } from '@reduxjs/toolkit';
import { IGlobalSettingsState } from '..';
import { TK_SAVED_CONFIG_PARAM_KEY } from '../../../utils/constants';

export const setGlobalSettings = (
  state: IGlobalSettingsState,
  action: PayloadAction<IGlobalSettingsState>
) => {
  return action.payload;
};
export interface IParams {
  sku?: string;
  appName?: string;
  storeCode?: string;
  configId?: string;
  lng?: string;
  country?: string;
  timestamp?: number;
  token?: string;
  productName?: string;
  recipeId?: string;
  price?: string;
  step?: number;
  notistack?: boolean;
  page?: string;
  [TK_SAVED_CONFIG_PARAM_KEY]?: string;
  mode?: string;
}

export const setGlobalSettingsParams = (
  state: IGlobalSettingsState,
  action: PayloadAction<IParams>
) => {
  state.params = action.payload;
};

export const setStep = (state: IGlobalSettingsState, action: PayloadAction<number>) => {
  state.step = action.payload;
};

export const setIsRetailApp = (
  state: IGlobalSettingsState,
  action: PayloadAction<boolean>
) => {
  state.isRetailApp = action.payload;
};

export const setConfigVariables = (
  state: IGlobalSettingsState,
  action: PayloadAction<any>
) => {
  state.configVariables = action.payload;
};
