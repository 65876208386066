import { t } from 'i18next';
import { ButtonContainer } from '../buttons.style';
import { SURPRISE_ME_BUTTON_LABEL } from '../constants';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsInStock, getLastAngle, setActiveGroup, setConfiguration, setLastAngle } from '../../../store/threekitSlicer';
import { surpiseMeAnimation } from '../../../utils/function/functions';
import { setActiveSurpriseMe } from '../../../store/flowSlicer';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { usePlayerView, useProductType } from '../../../hooks';

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

interface SurpriseMeButtonProps {
  attributeNames?: string[];
  attributesObject?: Record<any, any>;
}

function SurpriseMeButton({
  attributeNames,
  attributesObject,
}: SurpriseMeButtonProps) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { isCapucines } = useProductType();
  const [disableSurpriseME, setDisableSurpriseMe] = useState<boolean>(
    Object.keys(window?.dataDrivenConfiguratorExtension?.getStatus()).length === 0
  );
  const inStockData = useSelector(getIsInStock);
  const previousAngleValue = useSelector(getLastAngle);
  const { changeView } = usePlayerView();

  const changeRotationWithoutSave = useCallback(
    (prevAngle: number) => {
      return new Promise<void>((resolve, reject) => {
        const configurator = window.threekit.configurator;
        let angle = prevAngle;
        const initialRotation = setInterval(async () => {
          if (angle <= 0 || angle >= 360) {
            configurator.setConfiguration({ 'Rotate Model': 0 });

            dispatch(setLastAngle(0));

            resolve();
            clearInterval(initialRotation);
          }else {
            if (angle >= 180) {
              angle = angle + 10;
            } else {
              angle = angle - 10;
            }
  
            if (angle >= 360) {
              configurator.setConfiguration({ 'Rotate Model': 0 });
            } else {
              configurator.setConfiguration({ 'Rotate Model': angle });
            }

          }

        
        }, 10);
      });
    },
    [dispatch]
  );

  const handleSupriseME = useCallback(async () => {
    let angle = previousAngleValue;

    if (typeof window.dataDrivenConfiguratorExtension?.startRandomAnimation === 'function') {
      window.dataDrivenConfiguratorExtension?.startRandomAnimation();
    }

    if (attributeNames && attributeNames.length > 0) {
      try {
        dispatch(setActiveGroup(""))

        await changeRotationWithoutSave(angle);
    dispatch(setActiveSurpriseMe(true));

        setTimeout(() => {

          surpiseMeAnimation({
            dispatch,
          });
        }, 1500);
        setTimeout(() => {
          setDisableSurpriseMe(true);
          if (attributeNames!.length === 6 && attributesObject) {
            const newConfig = attributeNames?.reduce(async function (acc: any, item: string) {
              const values = attributesObject![item]?.data?.values;
              let assetIds: any[] = values
                ?.filter(
                  (element: any) =>
                    element?.sku !== 'NA' && Number(inStockData?.[element?.sku]?.qty_available_to_order) > 0
                )
                ?.map((data: any) => {
                  return data?.assetId;
                });

              if (assetIds.length === 0)
                assetIds = values
                  ?.filter((element: any) => element?.sku !== 'NA')
                  ?.map((data: any) => {
                    return data?.assetId;
                  });
              const randomAsset = assetIds[Math.floor(Math.random() * assetIds?.length)];
              let noDuplicateAsset = randomAsset;
              const newAcc = await acc;
              while (Object.values(newAcc).some((element: any) => element.assetId === noDuplicateAsset)) {
                noDuplicateAsset = assetIds[Math.floor(Math.random() * assetIds?.length)];
              }
              return {
                ...(await acc),
                [item]: { assetId: noDuplicateAsset },
              };
            }, {});

            newConfig.then((conf: any) => {
              let hasDuplicate = Object.values(conf).some((val, i) => Object.values(conf).indexOf(val) !== i);
              if (!(Object.values(conf).some((element: any) => typeof element.assetId !== 'string') && !hasDuplicate)) {
                dispatch(setConfiguration(conf));
                setDisableSurpriseMe(false);
              } else {
                setDisableSurpriseMe(false);

                //handleSupriseME()
              }
            });
          }
        }, 4000);
      } catch (e) {
        console.log(e);
      }
    }
  }, [
    attributesObject,
    attributeNames,
    dispatch,
    previousAngleValue,
    changeRotationWithoutSave,
    changeView,
    inStockData
  ]);

  return !isCapucines ?
    <ButtonContainer
      buttonName={SURPRISE_ME_BUTTON_LABEL}
      data-dtname={SURPRISE_ME_BUTTON_LABEL}
      onClick={handleSupriseME}
      disabled={disableSurpriseME}
    >
      {t('button.label.surprise_me', {
        defaultValue: SURPRISE_ME_BUTTON_LABEL,
      })}
    </ButtonContainer> : null;
}

export default SurpriseMeButton;
