import memoizeOne from 'memoize-one';
import { RootState } from 'store';

export const getDisplayHelper = (state: RootState) => state.flow.helper.displayHelper;
export const getRecap = memoizeOne((state: RootState) => state.flow.recap);
export const getDisplayRecap = (state: RootState) => state.flow.recap.displayRecap;
export const getRecapOpenPercentage = (state: RootState) => state.flow.recap.recapOpenPercentage;
export const getForm = (state: RootState) => state.flow.form;
export const getFormHeight = (state: RootState) => state.flow.form.height;
export const getActiveSurpriseMe = (state: RootState) => state.flow.activeSurpriseMe;
export const getPlayerView = (state: RootState) => state.flow.playerView;
