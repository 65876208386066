import { css, styled } from 'styled-components';

interface DeviceInfo {
  isMobile: boolean;
  isIpad: boolean;
  isDesktop: boolean;
}

interface Theme {
  device: DeviceInfo;
  appName: string;
}

interface StepSlideProps {
  theme: Theme;
  isActive: boolean;
}

export const Section = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
  }) => {
    return `
          ${isMobile
        ? `align-items: center;
              `
        : isIpad
          ? 'border: none;'
          : isDesktop
            ? 'border: none;'
            : ''
      }
            `;
  }
);
export const Container = styled(Section).withConfig({
  shouldForwardProp: (prop) => !['clientPage'].includes(prop),
})<{ open: boolean }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    open,
  }) => {
    return `
        
        transform-origin: 0 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        z-index: 99999;
        height: 100svh;
        height: 100vh;
        overflow: hidden;
        transition: transform 0.5s;
        transform: ${open ? 'scaleY(1);' : 'scaleY(0);'}

        `;
  }
);

export const InnerContainer = styled.div<{ open: boolean }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
    open,
  }) => {
    return `
    position: absolute;
    display:flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction:column;
    background-color: #FFFFFF;
    width: ${isMobile ? '100%' : '50%'};
    z-index: 99999;
    height: 95vh;
    height: 95svh;
    max-height: 1200px;
    max-width:600px;
    bottom: 0;
    left: 50%;
    border-radius: 8px 8px 0px 0px;
    padding :${!isMobile ? '0px 48px 0px 48px' : '0 24px 0px 24px'} ;
    transition: all 0.3s ease-out;
    gap: 30px;
    transform: translate(-50%, 0%);
    @media (min-width: 1024px) {
      max-width: 700px;
    }
    `;
  }
);

export const Title = styled.label(() => {
  return `
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    font-family: LouisVuitton_Regular;


    `;
});

export const TitleContainer = styled.div(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
    },
  }) => {
    return `
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    z-index: 100;
    ${!isMobile ? 'padding: 48px 0px 0px 0px;' : 'padding-top: 32px;'}
    `;
  }
);
export const Icon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:focus{
    border: solid 2px black
  }
`;

export const ContentContainer = styled.div(() => {
  return `
    display: block;
    flex-direction: column;
    height:100%
    `;
});

export const SlideContainer = styled.div`
  position: relative;
  background-color: #efefef;
`;

export const PictureContainer = styled.div
  .attrs<{
    image: string;
    slideDirection: string;
  }>((props) => ({}))
  .withConfig({
    shouldForwardProp: (prop) => !['image', 'slideDirection'].includes(prop),
  })`
  ${({ image, slideDirection }) => `
    position: relative ;
    flex: 2;
    width: 100%;
    z-index: 5;
    background-image: url(${image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 1 / 1;
     @keyframes slideInFromRight {
        from {
            transform: translateX(100%);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
      }

      @keyframes slideInFromLeft {
        from {
            transform: translateX(-100%);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
      }
    animation: ${slideDirection === 'next' ? 'slideInFromRight' : 'slideInFromLeft'
    } 0.5s forwards;
    `}
`;

export const VideoContainer = styled.video
  .attrs<{
    slideDirection: string;
  }>((props) => ({}))
  .withConfig({
    shouldForwardProp: (prop) => !['slideDirection'].includes(prop),
  })`
  ${({ slideDirection }) => `
    position: relative ;
    flex: 2;
    width: 100%;
    height: 100%;
    z-index: 5;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    animation: ${slideDirection === 'next' ? 'slideInFromRight' : slideDirection === 'prev' ? 'slideInFromLeft' : ''
    } 0.5s forwards;
    `}
`;

export const ImageContainer = styled.img
.attrs<{
  slideDirection: string;
}>((props) => ({}))
.withConfig({
  shouldForwardProp: (prop) => !['slideDirection'].includes(prop),
})`
${({ slideDirection }) => `
  position: relative ;
  flex: 2;
  width: 100%;
  height: 100%;
  z-index: 5;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  animation: ${slideDirection === 'next' ? 'slideInFromRight' : slideDirection === 'prev' ? 'slideInFromLeft' : ''
  } 0.5s forwards;
  `}
`;

export const DescriptionContainer = styled.div
  .attrs<{
    slideDirection: string;
  }>((props) => ({}))
  .withConfig({
    shouldForwardProp: (prop) => !['slideDirection'].includes(prop),
  })`
${({ slideDirection }) => `
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 128px;  
    width: 100%;
    @keyframes slideInFromRight {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }

    @keyframes slideInFromLeft {
      from {
        transform: translateX(-100%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
    animation: ${slideDirection === 'next' ? 'slideInFromRight' : 'slideInFromLeft'} 0.5s forwards;

    `}`;

export const StepTitle = styled.label(() => {
  return `
       font-size: 10px;
       line-height: 16px;
       font-family: LouisVuitton_Regular;
       font-weight:400;

    `;
});

export const DescriptionTitle = styled.label(() => {
  return `
         font-size: 18px;
         line-height: 24px;
         font-family: LouisVuitton_Regular;
         color:#000000;
         font-weight:400;
  
      `;
});

export const DescriptionText = styled.p(() => {
  return `
    font-size: 16px    ;
    line-height: 24px;
    font-family: LouisVuitton_Regular;
    color:#000000;
    margin: 0;
    `;
});
export const StepSlide = styled.div
  .attrs<StepSlideProps>((props) => ({}))
  .withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
  })`
  ${({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    isActive,
  }) => `
  display: ${isActive ? '' : 'none'};
  `}
`;
