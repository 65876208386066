import styled from 'styled-components';

export const FormContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'recap',
      'tutorial',
      'baseHeightInVH',
      'maxHeightInVH',
      'isActiveAnimation',
    ].includes(prop),
})<{
  recap: boolean;
  tutorial?: boolean;
  baseHeightInVH: number;
  maxHeightInVH: number;
  isActiveAnimation: boolean;
}>`
  position: absolute;
  flex-direction: column;
  width: 100%;
  bottom: ${({ isActiveAnimation,baseHeightInVH }) =>
  isActiveAnimation ? `-${baseHeightInVH}px` : `0`};
  height: ${({ recap, baseHeightInVH, maxHeightInVH }) =>
     recap ? `${maxHeightInVH}px` : `${baseHeightInVH}px`};
  transition: height 0.3s ,bottom 1s ease-in-out;
  ${({ tutorial }) =>
    !tutorial
      ? `
    overflow-y: auto;
    z-index: 100;
    background-color: white;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  `
      : ''}
    ${({ recap }) => (recap ? 'overflow-y: auto !important;' : 'overflow: hidden;')}
  border-radius: 24px 24px 0 0;
`;

export const AboveFormContainer = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 20px;
  align-items: flex-start;
  justify-content: space-between;
`;

export const FormContentWrapper = styled.div
  .attrs({ className: 'FormContentWrapper' })
  .withConfig({
    shouldForwardProp: (prop) =>
      !['openPercentage', 'slidePercentsToClose'].includes(prop),
  })<{ openPercentage?: number; slidePercentsToClose: number }>`
  display: flex;
  flex-direction: column;
  padding: 0 25px 20px;
  opacity: ${({ openPercentage, slidePercentsToClose }) =>
    openPercentage
      ? openPercentage < slidePercentsToClose
        ? 0.7 - openPercentage / 100
        : 0
      : 1};
  position: absolute;
  width: 100%;
  height: 100%;
 
`;

export const SlideBarContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
`;

export const SlideBar = styled.div`
  width: 56px;
  min-height: 4px;
  background-color: #b4b4b4;
  border-radius: 2px;
  margin: auto;
`;
