import { createSlice } from '@reduxjs/toolkit';
import * as configurationReducers from './reducers';

interface PlayerSize {
  width: number;
  height: number;
}

interface DataDrivenConfiguratorExtensionStatus {
  validAttributes: string[];
  sku: string;
}

export interface SettingsState {
  isThreekitLoaded: boolean;
  isPlayerLoading: boolean;
  language?: string;
  isChina?: boolean;
  playerSize: PlayerSize;
  notInStockUnselected: boolean;
  isPrefetchLoading: boolean;
}
export interface IAttribute {
  name: string;
  [key: string]: any;
}
export interface IForm {
  [key: string]: {
    [key: string]: IAttribute;
  };
}

export interface ConfigurationState {
  product?: Record<string, any>;
  initialConfiguration?: Record<string, any>;
  form?: IForm;
  groupedAttributes?: Record<string, IAttribute[]>;
  attributes?: Record<string, any>;
  isInStock: { items: Record<string, { qty_available_to_order: number }>, allSkusAvailable: boolean, leadTime: Record<string, number> };
  isAllSelectedValuesInStock?: boolean;
  dataDrivenConfiguratorExtensionStatus: DataDrivenConfiguratorExtensionStatus;
  formValidAttributes: Record<string, any>;
  metadata?: Record<string, any>;
  name?: string;
  activeGroup: string;
  dependencies?: any;
  scannedSkus?: Record<string, string>[];
  recievedScannedSkus?: any[];
}

export interface ProductPageState {
  placeHolderError: boolean;
  page: string;
  playerFullScreen: boolean;
  hasPatch: boolean;
  isFullScreen: boolean;
  player3DImage: string;
  previousCamera: string;
  lastAngle: number;
  isRotable: boolean;

}

export interface SavedConfigurationData {
  id: string;
  recipeId: string;
  productId: string;
  productVersion: string;
  variant: Record<string, any>;
  metadata: Record<string, any>;
  createdAt: string;
  shortId: string;
  orgId: string;
  thumbnail: string;
  customerId: string;
  scope: string;
  identifier: string;
  attachments: Record<string, any>;
  sceneGraphState: Record<string, any>;
  resumableUrl: string;
  thumbnailUrls: string[];
  inStore?: boolean;
  configuration?: Record<string, any>;
}

export interface ThreekitState {
  settings: SettingsState;
  configuration: ConfigurationState;
  currentMode: string;
  viewUpdate: boolean;
  isActionFired: boolean;
  productPage: ProductPageState;
  savedConfigurationData: SavedConfigurationData | any;
  player?: any;
  modelView: string;
}

const initialState: ThreekitState = {
  settings: {
    isThreekitLoaded: false,
    isPlayerLoading: false,
    language: undefined,
    isChina: undefined,
    playerSize: { width: 0, height: 0 },
    notInStockUnselected: false,
    isPrefetchLoading: false,
  },
  configuration: {
    product: undefined,
    initialConfiguration: undefined,
    form: undefined,
    groupedAttributes: undefined,
    attributes: undefined,
    isInStock: { items: {}, allSkusAvailable: false, leadTime: {} },
    isAllSelectedValuesInStock: undefined,
    dataDrivenConfiguratorExtensionStatus: { validAttributes: [], sku: '' },
    formValidAttributes: {},
    metadata: undefined,
    scannedSkus: [],
    recievedScannedSkus: [],
    activeGroup: '',

  },
  currentMode: '2D',
  viewUpdate: false,
  isActionFired: false,
  modelView: 'Closed',
  productPage: {
    placeHolderError: false,
    page: 'home',
    playerFullScreen: false,
    previousCamera: '',
    lastAngle: 0,
    player3DImage: '',
    hasPatch: false,
    isFullScreen: false,
    isRotable: true,
  },
  savedConfigurationData: {},
  player: undefined,
};

export const threekitSlice = createSlice({
  name: 'threekit',
  initialState,
  reducers: {
    ...configurationReducers.configurationReducers,
    ...configurationReducers.currentModeReducers,
    ...configurationReducers.productPageReducers,
    ...configurationReducers.settingsReducers,
    ...configurationReducers.savedConfigurationDataReducers,
  },
});

export const {
  setInitialConfiguration,
  setThreekitLoaded,
  setPlayerLoading,
  setIsChina,
  setLanguageState,
  setNotInStockUnselected,
  setProduct,
  setForm,
  setGroupedAttributes,
  setIsInStock,
  setIsAllSelectedValuesInStock,
  setAttributes,
  setValidAttributes,
  setSku,
  setScannedSkus,
  setRecievedScannedSkus,
  setFormValidAttributes,
  setMetadata,
  setPlayerSize,
  setIsPrefetchLoading,
  setIsActionFired,
  setCurrentMode,
  setCurrentModelView,
  setIsFullScreen,
  setViewUpdate,
  setPage,
  setLastAngle,
  setSavedConfigurationData,
  setIsRotable,
  setActiveGroup,
} = threekitSlice.actions;

export default threekitSlice.reducer;

export * from './reducers';
export * from './selectors';
