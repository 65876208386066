import styled from 'styled-components';

export const ResetButtonWrapper = styled.div`
  font-family: LouisVuitton_Regular;
  font-feature-settings: 'case' on;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
  
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #000;
  }
`;
