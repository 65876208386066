import React, { useCallback, useEffect, useRef, ReactNode, useState, useMemo } from 'react';
import { PlayerBackground, PlayerElements, RotationImage, Wrapper } from './Player2D.styles';
import add2DSpinTool from '../../Tools/add2DSpin';
import { useAttribute, useThreekitInitStatus, useWindowSize } from '../../../utils/threekitHooks';
import { RotatePlayerButton, HorizontalListSelector } from '../../../components';
import {
  CLASS_NAME_PREFIX,
  DEFAULT_CLASS_NAME,
  LANDSCAPE_FORM_MAX_HEIGHT_IN_PX,
  TK_PLAYER_ATTRIBUTE_ID,
  TK_PLAYER_DIV_ID_2D,
  MATRIX,
} from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import {
  getIsFullScreen,
  getLastAngle,
  setViewUpdate,
  setIsFullScreen,
  setLastAngle,
  setPlayerLoading,
  setPlayerSize,
  setIsPrefetchLoading,
} from '../../../store/threekitSlicer';
import { addOpacityAnimation, getEventPosition, isWithinTapArea, openTuto } from '../../../utils/function/functions';
import { setDisplayHelper, getActiveSurpriseMe, setPlayerView, getDisplayHelper } from '../../../store/flowSlicer';
import { PLAYER_GREY_BACKGROUND } from '../../../assets';
import { usePlayerView, useProductType } from '../../../hooks';
import { HelperButton } from '../../Buttons';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IToggleItem } from 'components/HorizontalListSelector';

interface TutorialStep {
  index: number;
  title: string;
  description: string;
  imageUrl: string;
  stepLabelImageURL: string;
  stepLabelId: string;
}

interface TutorialData {
  productName: string;
  sku: string;
  productImageURL: string;
  steps: TutorialStep[];
}

interface Player2DProps {
  height?: string;
  width?: string;
  border?: string;
  cssDisplay?: boolean;
  className?: string;
  homePage?: boolean;
  children?: ReactNode;
  clientPage?: boolean;
  isMobile?: boolean;
  isLoaded: boolean;
  isRotable: boolean;
  tutorialData?: TutorialData;
}

type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

const Player2D: React.FC<Player2DProps> = ({
  height = `calc(100svh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - ${LANDSCAPE_FORM_MAX_HEIGHT_IN_PX}px)`,
  width = '100%',
  border = 'none',
  cssDisplay,
  className,
  isRotable,
  isLoaded,
}) => {
  const { launchAnimation, changeView, viewDataByGroupName } = usePlayerView();
  const playerRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowSize();
  const previousAngleValue = useSelector(getLastAngle);
  const [attributeData, setRotateModel] = useAttribute('Rotate Model');
  const [, setToggleButton] = useState(true);
  const isFullScreen = useSelector(getIsFullScreen);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [lastTapPosition, setLastTapPosition] = useState({ x: 0, y: 0 });
  const dispatch = useDispatch<AppThunkDispatch>();
  const playerReady = useThreekitInitStatus();
  const isActiveAnimation = useSelector(getActiveSurpriseMe);
  const toggleViewItems = Object.keys(viewDataByGroupName).map((el) => {
    return { id: el, label: el };
  });
  const [selectedViewIndex, setSelectedViewIndex] = useState(toggleViewItems?.[0]);
  const displayHelper = useSelector(getDisplayHelper);
  const { isCapucines } = useProductType();
  const [isHideSlide, setIsHideSlide] = useState(true);
  const [loadingPercent, setLoadingPercent] = useState(true);
  const [matrix, setMatrix] = useState('matrix(1,0,0,1,0,0)');
  const [sliderPosition, setSliderPosition] = useState<string>('0');
  const newClassName = `${DEFAULT_CLASS_NAME} ${CLASS_NAME_PREFIX}-player ${className}`;
  let timer: NodeJS.Timeout;

  useEffect(() => {
    if (document.getElementById('canvas')) {
      const canvas = document?.getElementById('canvas')!;
      mutationObserver.observe(canvas, {
        attributes: true,
      });
    }
  }, []);

  useEffect(() => {
    if (playerReady) {
      const { clientWidth = 0, clientHeight = 0 } = playerRef.current || {};
      setLoadingPercent(false);
      // window.player.prefetcher.on('progress', (e: { loaded: number; total: number; }) => {
      //   const loadingPercent = (e.loaded * 100) / e.total;
      //   const loadingStatus = loadingPercent < 100;
      //   setLoadingPercent(loadingStatus);
      //   //logoLoadingProgress({ loadingPercent, playerSize: { clientWidth, clientHeight } });
      // });
      window.player.prefetcher.once('end', () => console);
      dispatch(setPlayerSize({ width: clientWidth, height: clientHeight }));

      launchAnimation(isCapucines);
      setTimeout(() => {
        displayTutorial();
      }, 7000);
    }
  }, [playerReady]);

  useEffect(() => {
    dispatch(setIsPrefetchLoading(loadingPercent));
  }, [loadingPercent]);

  const canvasVisibitiy = useMemo(() => {
    return document.getElementById('canvas')?.style.opacity === '100';
  }, [document.getElementById('canvas')?.style.opacity]);

  useEffect(() => {
    let result = '0';

    if (canvasVisibitiy && !isActiveAnimation) {
      setTimeout(() => {
        const canvas = document.getElementById('canvas');
        const container = document.getElementById(TK_PLAYER_DIV_ID_2D);
        const scale = canvas!.getBoundingClientRect().height / canvas!.offsetHeight;
        const canvasHeight = canvas!.clientHeight * scale;

        if (canvasHeight >= container!.clientHeight) {
          result = isCapucines ? '10%' : '0';
        } else {
          const bottom = canvasHeight / 2;
          result = `calc(50% - ${bottom}px  ${isCapucines && !isMobile ? '+ 5%' : ''})`;
        }
        setSliderPosition(result);
      }, 1000);
    }
  }, [canvasVisibitiy, isActiveAnimation]);

  useEffect(() => {
    if (canvasVisibitiy) {
      setIsHideSlide(false);
      const matrixs = MATRIX;
      let selectedIndex = 0;
      let precTime: number;
      let req: number;
      const animate = (timestamp: number) => {
        if (!precTime) {
          precTime = timestamp;
        }
        if (precTime && timestamp - precTime >= 1000) {
          setMatrix(matrixs[selectedIndex]);
          selectedIndex = selectedIndex + 1;
          if (selectedIndex > matrixs.length - 1) {
            setIsHideSlide(true);
            cancelAnimationFrame(req);
          }
          precTime = timestamp;
          req = requestAnimationFrame(animate);
        } else {
          req = requestAnimationFrame(animate);
        }
      };
      req = requestAnimationFrame(animate);
    }
  }, [canvasVisibitiy]);

  const displayTutorial = () => {
    const showTuto = openTuto();
    if (showTuto && !displayHelper) {
      dispatch(setDisplayHelper(true));
    }
  };

  const changeRotationWithoutSave = (prevAngle: number) => {
    return new Promise<void>((resolve, reject) => {
      const configurator = window?.threekit?.configurator;
      let angle = prevAngle;

      const initialRotation = setInterval(async () => {
        if (angle <= 0 || angle >= 350) {
          await configurator.setConfiguration({ 'Rotate Model': 0 });

          dispatch(setLastAngle(0));

          resolve();
          clearInterval(initialRotation);
        }

        if (angle >= 180) {
          angle = angle + 10;
        } else {
          angle = angle - 10;
        }
        if (angle > 350) {
          setRotateModel(0);
        } else {
          setRotateModel(angle);
        }
      }, 30);
    });
  };

  useEffect(() => {
    if (isRotable) {
      setRotateModel(previousAngleValue);
    } else {
      changeRotationWithoutSave(previousAngleValue);
    }
    if (playerReady) {
      add2DSpinTool(
        { attributeId: attributeData?.id || TK_PLAYER_ATTRIBUTE_ID },
        handleLastAngle,
        isRotable,
        isFullScreen,
        setToggleButton,
        changeZoom
      );
    }
  }, [playerReady, isRotable, isFullScreen]);

  const handleLastAngle = useCallback(
    (lastAngle: number) => {
      dispatch(setLastAngle(lastAngle));
    },
    [dispatch]
  );

  const mutationObserver = new MutationObserver(async (mutations) => {
    mutations.forEach((element) => {
      if (element.attributeName === 'height' || element.attributeName === 'width') {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {}, 1000);
      }
    });
  });

  const handleDoubleTap = (event: any) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapTime;
    const currentPosition = getEventPosition(event);
    if (tapLength < 500 && tapLength > 0 && isWithinTapArea(lastTapPosition, currentPosition, 15)) {
      changeZoom();
    }
    setLastTapTime(currentTime);
    setLastTapPosition({
      ...lastTapPosition,
      ...currentPosition,
    });
  };

  const changeZoom = () => {
    dispatch(setViewUpdate(true));

    if (isFullScreen) {
      while (document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.pointerEvents !== 'none') {
        document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.setProperty('pointer-events', 'none');
      }
      dispatch(setPlayerLoading(true));
      setTimeout(() => {
        if (document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.pointerEvents === 'none') {
          addOpacityAnimation(TK_PLAYER_DIV_ID_2D, 100);
          document.getElementById(TK_PLAYER_DIV_ID_2D)!.style.removeProperty('pointer-events');
          dispatch(setIsFullScreen(!isFullScreen));
          dispatch(setPlayerLoading(false));
        }
      }, 50);

      setTimeout(() => {
        dispatch(setViewUpdate(false));
      }, 1000);

      return;
    }
    dispatch(setIsFullScreen(!isFullScreen));
    setTimeout(() => {
      dispatch(setViewUpdate(false));
    }, 1000);
  };

  return (
    <Wrapper
      className={newClassName}
      height={height}
      width={width}
      border={border}
      isRotable={isRotable}
      conditionalCSS={cssDisplay}
      ref={playerRef}
      fullScreen={isFullScreen}
      isActiveAnimation={isActiveAnimation}
      isMobile={isMobile}
    >
      {/* <canvas id="canvas-loading-progress" /> */}

      <PlayerBackground src={PLAYER_GREY_BACKGROUND} />

      <div
        id={TK_PLAYER_DIV_ID_2D}
        // onDoubleClick={changeZoom}
        // onTouchEnd={(e) => handleDoubleTap(e)}
      >
        <RotationImage
          id="RotationImage"
          isMobile={isMobile}
          sliderPosition={sliderPosition}
          show={!isActiveAnimation && isLoaded && isRotable && !isHideSlide}
          matrix={matrix}
          viewBox="0 0 488 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7827_13454)">
            <path
              d="M488 2C488 23.263 378.757 40.5 244 40.5C109.243 40.5 0 23.263 0 2"
              stroke="url(#paint0_linear_7827_13454)"
              strokeLinecap="round"
              strokeDasharray="2 6"
            />

            <circle className="anime" cx="247" cy="39.9999" r="30" fill="#ECEBEA" stroke="#B4B4B4" />
            <path
              className="anime"
              d="M250.618 43.2032L254.199 40.1016L250.618 37"
              stroke="black"
              strokeLinejoin="round"
            />
            <path
              className="anime"
              d="M242.581 37.0001L239 40.1017L242.581 43.2034"
              stroke="black"
              strokeLinejoin="round"
            />
          </g>

          <defs>
            <linearGradient
              id="paint0_linear_7827_13454"
              x1="16"
              y1="21"
              x2="488"
              y2="21"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#191919" stopOpacity="0" />
              <stop offset="0.46211" stopColor="#191919" />
              <stop offset="1" stopColor="#191919" stopOpacity="0" />
            </linearGradient>
            <clipPath id="clip0_7827_13454">
              <rect width="488" height="80" fill="white" />
            </clipPath>
          </defs>
        </RotationImage>
      </div>

      {isLoaded && (
        <PlayerElements show={!isActiveAnimation}>
          <RotatePlayerButton />
          {isCapucines && (
            <HorizontalListSelector
              items={toggleViewItems}
              selected={selectedViewIndex}
              handleClick={(item: IToggleItem) => {
                const newView = item.id;
                dispatch(setPlayerView(newView));
                changeView({ newView });
                setSelectedViewIndex(item);
              }}
            />
          )}
          <HelperButton />
        </PlayerElements>
      )}
    </Wrapper>
  );
};

export default Player2D;
