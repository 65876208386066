import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSavedConfig } from '../../utils/ApiCalls/ApiCalls';
import { useWindowSize } from '../../utils/threekitHooks';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupedAttributes, getPage, setSavedConfigurationData } from '../../store/threekitSlicer';
import { showPopUp } from '../../components/PopUp/ShowPopUp';
import { popUpTypes } from '../../components/PopUp/PopUpType';
import { getActiveSurpriseMe } from '../../store/flowSlicer';
import useValuesStock from '../useValuesStock';

export const useSavedConfiguration = (recipeId: string) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const queryKey = useMemo(() => ['savedConfiguration', recipeId], [recipeId]);
  const groupedAttributes = useSelector(getGroupedAttributes);
  const page = useSelector(getPage);
  const isActiveSurpriseMe = useSelector(getActiveSurpriseMe);
  const isSkusInStock = useValuesStock();

  const {
    data: savedConfigurationData,
    isLoading: isLoadingSavedConfigurationData,
    isError: isErrorSavedConfigurationData,
    error: errorSavedConfigurationData,
  } = useQuery({
    queryKey,
    queryFn: () => getSavedConfig({ recipeId }),
    staleTime: 300000,
    enabled: !!recipeId,
    retry: 5,
    retryDelay: (retryAttempt) => Math.min(retryAttempt * 3000, 3000)
  });

  useEffect(() => {
    if (savedConfigurationData)
      dispatch(setSavedConfigurationData(savedConfigurationData));
  }, [savedConfigurationData, dispatch]);

  const showOOSPopUps = useCallback(() => {
    const showOutOfStockPopUp = (img: string) => showPopUp({ popUpType: popUpTypes.outOfStock, img });
    Object.values(groupedAttributes || {}).forEach((group) => {
      const valuesInGroup = group
        .map((attribute) => {
          return attribute.values.filter((value: Record<string, any>) => {
            return value?.selected === true;
          });
        })
        .flat();
      const valuesStock = isSkusInStock({ valuesToCheck: valuesInGroup });
      valuesInGroup.forEach((value: Record<string, any>, i: number) => {
        if (valuesStock[i] === false) {
          const skuImg = value?.thumbnailPath;
          showOutOfStockPopUp(skuImg);
        }
      });
    });
  }, [groupedAttributes, isSkusInStock]);

  useEffect(() => {
    if (page === 'home' && recipeId && !isActiveSurpriseMe) {
      showOOSPopUps();
    }
  }, [page, isActiveSurpriseMe]);

  return {
    savedConfigurationData,
    isLoadingSavedConfigurationData,
    isErrorSavedConfigurationData,
    errorSavedConfigurationData,
  };
};

export default useSavedConfiguration;
