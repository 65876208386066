import { CLOSE_ICON_HELPER, PICTURE_PLACEHOLDER } from '../../../assets';
import {
  Container,
  SlideContainer,
  DescriptionContainer,
  DescriptionText,
  DescriptionTitle,
  Icon,
  InnerContainer,
  StepSlide,
  Title,
  TitleContainer,
  VideoContainer,
  ImageContainer,
} from './Helper.style';
import { helperData as mockHelperData } from '../../../utils/mockData';
import ViewsViewer from '../../ViewsViewer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HELPER_DIV_ID } from '../../../utils/constants';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { setDisplayHelper } from '../../../store/flowSlicer';
import { t } from 'i18next';
import { useProductType } from '../../../hooks';
import DOMPurify from 'dompurify';
import { formatAssetUrlForChina, isVideoUrl } from '../../../utils/function/functions';
interface HelperProps {
  displayHelper: boolean;
}
const Helper = ({ displayHelper }: HelperProps) => {
  type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
  const dispatch = useDispatch<AppThunkDispatch>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [slideDirection, setSlideDirection] = useState<'next' | 'prev' | ''>('');
  const ref = useRef<any>();
  const { productNameForTranslations } = useProductType();
  const isChina = window.location.hostname?.includes('lvcampaign') || false; // catalogdesktop is on china and ww

  useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (displayHelper && ref.current && evt.target.contains(ref.current)) {
        dispatch(setDisplayHelper(false));
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const helperData = useMemo(() => {
    let jsonString = window?.threekit?.configurator?.getMetadata()?.helperJson;
    return jsonString ? JSON.parse(jsonString) : mockHelperData;
  }, [window?.threekit?.configurator?.getMetadata()?.helperJson]);

  useEffect(() => {
    if (displayHelper && ref) {
      document.getElementById('close_button')?.focus();
    }
  }, [displayHelper, ref]);

  useEffect(() => {
    const activeVideo = document.getElementsByClassName('video-active')?.[0];
    const inactiveVideos = document.getElementsByClassName('video-inactive');
    if (activeVideo) {
      (activeVideo as HTMLVideoElement).play();
    }
    if (inactiveVideos) {
      for (let i = 0; i < inactiveVideos.length; i++) {
        (inactiveVideos[i] as HTMLVideoElement).pause();
        (inactiveVideos[i] as HTMLVideoElement).currentTime = 0;
      }
    }
  }, [activeStep]);


  return (
    <Container className="modal" role="dialog" aria-modal="true" ref={ref} id={HELPER_DIV_ID} open={displayHelper}>
      {helperData?.steps?.map((item: Record<string, any>, index: number) => {
        const descriptionText = DOMPurify.sanitize((t(item?.description)).replace(/\\n/g, "<br />"));
        const assetPath = item?.assetPath ? formatAssetUrlForChina({ isChina, url: item?.assetPath }) : PICTURE_PLACEHOLDER;
        const isActive = activeStep === index;

        return (
          <StepSlide key={index} isActive={activeStep === index}>
            <InnerContainer open={displayHelper}>
              <TitleContainer>
                <Title>{t(`${helperData?.global_title}`)}</Title>
                <Icon
                  tabIndex={0}
                  role="button"
                  id="close_button"
                  src={CLOSE_ICON_HELPER}
                  onClick={() => {
                    dispatch(setDisplayHelper(false));
                  }}
                />
              </TitleContainer>
              <SlideContainer>
                {isVideoUrl(assetPath) ? (
                  <VideoContainer
                    playsInline
                    className={`video-${isActive ? 'active' : 'inactive'}`}
                    key={`video-${index}`}
                    muted
                    loop
                    src={assetPath}
                    slideDirection={slideDirection}
                  />
                ) : (
                  <ImageContainer src={assetPath} slideDirection={slideDirection} />
                )}
                <ViewsViewer
                  data={helperData}
                  setActiveStep={setActiveStep}
                  setSlideDirection={setSlideDirection}
                  activeStep={activeStep}
                  isMobile={true}
                  isHelper
                  containerID={HELPER_DIV_ID}
                />
              </SlideContainer>
              <DescriptionContainer slideDirection={slideDirection}>
                <DescriptionTitle>{t(item?.title)}</DescriptionTitle>
                <DescriptionText dangerouslySetInnerHTML={{ __html: descriptionText }} />
              </DescriptionContainer>
            </InnerContainer>
          </StepSlide>
        );
      })}
    </Container>
  );
};

export default Helper;
