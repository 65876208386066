import { useSelector } from 'react-redux';
import { getMetadata } from '../../store/threekitSlicer/selectors';
import { PRODUCTNAME_PLACEHOLDER } from '../../utils/constants';
import { getGlobalSettingsParams } from '../../store/globalSettingsSlicer';
import { t } from 'i18next';

const useProductName = (): string => {
  const { productName: productNameUrl } = useSelector(getGlobalSettingsParams) || {};
  const { ProductName: productNameConfigurator } = useSelector(getMetadata) || {
    ProductName: null,
  };

  const translatedProductName = t(
    productNameConfigurator || productNameUrl,
    productNameConfigurator || productNameUrl || PRODUCTNAME_PLACEHOLDER
  );

  return typeof translatedProductName === 'string'
    ? translatedProductName
    : productNameConfigurator || productNameUrl || PRODUCTNAME_PLACEHOLDER;
};

export default useProductName;
