import { useMemo } from 'react';
import { Wrapper } from './FunctionalButtons.style';
import { useAttributes, useWindowSize } from '../../utils/threekitHooks';
import { isAllScanned } from '../../utils/function/functions';
import { getParams } from '../../utils/function/navigationParams';
import { useSelector } from 'react-redux';
import { getScannedSkus } from '../../store/threekitSlicer';
import { DoneButton, ResetButton, SurpriseMeButton } from '../Buttons';
import { useProductType } from '../../hooks';

const FunctionalButtons = () => {
  const { isMobile } = useWindowSize();
  const attributeNames =
    window.dataDrivenConfiguratorExtension
      ?.getStatus()
      ?.validAttributesAndTheirValues_typeB?.map((item: Record<string, any>) => item?.name) || [];
  const attributesObject = useAttributes(attributeNames);
  const { appName } = getParams();
  const scannedSkus = useSelector(getScannedSkus);
  const disabledDoneButton = useMemo(() => {
    let disabled = true;
    if (
      !Object.values(window?.dataDrivenConfiguratorExtension?.getStatus()?.attributes).includes('NA') &&
      isAllScanned(scannedSkus!, appName)
    ) {
      disabled = false;
    }
    return disabled;
  }, [window?.dataDrivenConfiguratorExtension?.getStatus()?.attributes]);
  const { isCapucines } = useProductType();


  return (
    <Wrapper isCapucines={isCapucines}>
      {isCapucines && !isMobile && <ResetButton all />}
        <SurpriseMeButton
          attributeNames={attributeNames}
          attributesObject={attributesObject}
          />
      <DoneButton disable={disabledDoneButton} />
    </Wrapper>
  );
};

export default FunctionalButtons;
