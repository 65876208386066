import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormTitle from '../../components/FormTitle';
import CloseIcon from '../../components/CloseIcon';
import { closeRecap, getRecapOpenPercentage } from '../../store/flowSlicer';
import ProductPrice from '../../components/ProductPrice';
import { DoneButtonWrapper, RecapWrapper, TitleCloseIconWrapper, TitleContainer } from './recapPage.styles';
import { ConfigurationSummary } from '../../components';
import { useWindowSize } from '../../utils/threekitHooks';
import { getScannedSkus } from '../../store/threekitSlicer';
import { getParams } from '../../utils/function/navigationParams';
import { isAllScanned } from '../../utils/function/functions';
import { DoneButton } from '../../components/Buttons';

function RecapPage({
  show,
  slidePercentsToOpen,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  handleCloseRecap = (dispatch?: any) => {
    dispatch(closeRecap(true));
  },
}: {
  show: boolean;
  slidePercentsToOpen?: number;
  handleTouchStart?: (e: React.TouchEvent) => void;
  handleTouchMove?: (e: React.TouchEvent) => void;
  handleTouchEnd?: (e: React.TouchEvent) => void;
  handleCloseRecap?: (dispatch: any) => void;
}) {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const scannedSkus = useSelector(getScannedSkus) || [];
  const { appName } = getParams();
  const recapOpenPercentage = useSelector(getRecapOpenPercentage);

  const disabledDoneButton = useMemo(() => {
    let disabled = true;
    if (
      !Object.values(window?.dataDrivenConfiguratorExtension?.getStatus()?.attributes).includes('NA') &&
      isAllScanned(scannedSkus, appName)
    ) {
      disabled = false;
    }
    return disabled;
  }, [window?.dataDrivenConfiguratorExtension?.getStatus()?.attributes]);

  return (
    <RecapWrapper
      show={show}
      openPercentage={recapOpenPercentage}
      slidePercentsToOpen={slidePercentsToOpen || 0}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <TitleCloseIconWrapper>
        <TitleContainer>
          <FormTitle />
          <ProductPrice />
        </TitleContainer>
        <CloseIcon
          onClick={() => {
            handleCloseRecap(dispatch);
          }}
          css={{ position: 'static', margin: '0 0 20px auto', alignSelf: isMobile ? 'flex-start' : '' }}
        />
      </TitleCloseIconWrapper>
      <ConfigurationSummary page="recap" />

      <DoneButtonWrapper>
        <DoneButton disable={disabledDoneButton} />
      </DoneButtonWrapper>
    </RecapWrapper>
  );
}

export default RecapPage;
