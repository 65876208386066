import styled from 'styled-components';

export const ActiveAttributeSelectorContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 100;
  padding: 20px 25px;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: bottom 0.3s ease-in-out;
  -moz-transition: bottom 0.3s ease-in-out;
  -ms-transition: bottom 0.3s ease-in-out;
  transition: bottom 0.3s ease-in-out;
`;

export const CloseIcon = styled.img`
  width: 1rem;
  position: absolute;
  right: 1rem;
`;

export const CloseButton = styled.button<{ disabled?: boolean }>(
  ({
    theme: {
      device: { isMobile, isIpad, isDesktop },
      appName,
    },
    disabled,
  }) => {
    return `
  width: 100%;
  min-height: 48px;
  border: none;
  margin-top: auto;
  border-radius: 24px;
  background-color: ${disabled ? '#E1E1E1' : '#18110C'};
  color: ${disabled ? '#767676' : '#fff'};
  ${
    !disabled
      ? `&:active {
    background-color: #4d4d4d;
    color: #000;
  }`
      : ''
  }
`;
  }
);

export const ValuesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  margin: 15px 0 15px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 1rem;
  gap: 1.5rem;
`;