import { ButtonContainer } from '../buttons.style';
import { ZOOM_BUTTON_LABEL } from '../constants';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getIsChina } from '../../../store/threekitSlicer';
import { UNZOOM_ICON, ZOOM_ICON } from '../../../assets';

interface ZoomButtonProps {
  isFullScreen?: boolean;
}

function ZoomButton({ isFullScreen }: ZoomButtonProps) {
  const isChina = useSelector(getIsChina);

  const scanAction = useCallback(() => {
    if (isChina) {
      //@ts-ignore
      wx?.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          //@ts-ignore
          wx?.miniProgram.navigateTo({
            url: `/pages/camera-view/camera-view`,
          });
        }
      });
    }

    if (window.webkit?.messageHandlers?.scanAction) {
      window.webkit.messageHandlers.scanAction.postMessage({
        message: 'scanTheProducts',
      });
    }
  }, [isChina]);

  return (
    <>
      <ButtonContainer
        buttonName={ZOOM_BUTTON_LABEL}
        data-dtname={ZOOM_BUTTON_LABEL}
        isFullScreen={isFullScreen}
        onClick={scanAction}
      >
        <img data-dtname={ZOOM_BUTTON_LABEL} alt="" src={isFullScreen ? UNZOOM_ICON : ZOOM_ICON} />
      </ButtonContainer>
    </>
  );
}

export default ZoomButton;
