import styled from 'styled-components';

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['progressPercents', 'barWidth', 'barHeight', 'isUseChildren'].includes(prop),
})<{
  progressPercents: string;
  barWidth: string;
  barHeight: string;
  isUseChildren: boolean;
}>`
  position: relative;
  width: ${(props) => (props.barWidth)};
  height: ${(props) => (props.barHeight)};
  background-color: #cbc4c4;
  margin: auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.progressPercents)};
    height: 1px;
    background-color: #272623;
    ${(props) => (props?.isUseChildren ? `transition: width 0.3s ease-in-out, height 0.3s ease-in-out;` : '')}
  }
`;
