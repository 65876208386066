import { Title } from './formTitle.styles';
import { useProductName } from '../../hooks';
import { t } from 'i18next';
import { PRODUCT_NAME_MAPPING } from '../../utils/constants';

function FormTitle() {
  const productName = useProductName();

  return <Title>{t(`label.productname.${PRODUCT_NAME_MAPPING?.[productName || productName?.toLowerCase()]}`, productName)}</Title>;
}

export default FormTitle;
