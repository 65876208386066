import { ButtonContainer } from '../buttons.style';
import { useSelector } from 'react-redux';
import { WISH_BUTTON_LABEL } from '../constants';
import { useCallback, useMemo, useState } from 'react';
import { getGlobalSettingsParams } from '../../../store/globalSettingsSlicer';
import { TK_SAVED_CONFIG_PARAM_KEY } from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { useProductName } from '../../../hooks';
import { handleItemAction } from '../utils';
import { getIsAllSelectedValuesInStock, getIsChina, getScannedSkus } from '../../../store/threekitSlicer';
import { getSavedConfigurationData } from '../../../store/threekitSlicer/selectors/savedConfigurationData';
import { WISH_ICON } from '../../../assets';

function WishButton() {
  const [clicked, setClicked] = useState<boolean>(false);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { appName = '' } = globalSettingsParams || {};
  const isChina = useSelector(getIsChina);
  const configurationSavedData = useSelector(getSavedConfigurationData);
  const dataDrivenSku = window.dataDrivenConfiguratorExtension?.getStatus()?.skus;
  const scannedSkus = useSelector(getScannedSkus);
  const isAllInStock = useSelector(getIsAllSelectedValuesInStock);

  const { baseUrlClient } = useParams();
  const productName = useProductName();

  const clientUrl = useMemo((): string => {
    if (!globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY] || !baseUrlClient) return '';

    const result = `https://${baseUrlClient}/${globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY]}`;
    return encodeURI(result);
  }, [globalSettingsParams, baseUrlClient]);

  const handleWish = useCallback(async (): Promise<void> => {
      handleItemAction({
        action: 'wish',
        isChina,
        configurationSavedData,
        appName,
        clientUrl,
        globalSettingsParams,
        dataDrivenSku,
        productName,
        scannedSkus,
        setClicked,
        isAllInStock
      });
  }, [
    clientUrl,
    productName,
    appName,
    configurationSavedData,
    dataDrivenSku,
    scannedSkus,
    globalSettingsParams,
    isChina,
    isAllInStock
  ]);

  return (
      <ButtonContainer buttonName={WISH_BUTTON_LABEL} data-dtname={WISH_BUTTON_LABEL} onClick={handleWish}>
        <img data-dtname={WISH_BUTTON_LABEL} alt="" src={WISH_ICON} />
      </ButtonContainer>
  );
}

export default WishButton;
