import { ReactElement } from "react";
import { Dot, LoaderWrapper, LoadingText } from "./buttonLoader.styles";
import { t } from "i18next";
import { LOADING_LABEL } from "../../../utils/constants";

export const ButtonLoader = (): ReactElement => (
    <LoaderWrapper>
      <LoadingText>{t('label.loading', LOADING_LABEL)}</LoadingText>
      <Dot delay="0s" />
      <Dot delay="0.15s" />
      <Dot delay="0.3s" />
    </LoaderWrapper>
  );