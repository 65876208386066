import styled from 'styled-components';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: #fff;
  color: #000;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const ListItem = styled.div.withConfig({
  shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{ selected: boolean }>`
  padding: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border-radius: 20px;
  transition: color 0.4s ease-in-out;
  text-wrap: nowrap;
`;

export const SelectedDiv = styled.div`
  position: absolute;
  z-index: -1;
  bottom: 0;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    left 0.4s ease,
    width 0.3s ease-in-out;
`;
