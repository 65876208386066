import { ButtonContainer } from '../buttons.style';
import { useSelector } from 'react-redux';
import { SHARE_BUTTON_LABEL } from '../constants';
import { useCallback, useMemo, useState } from 'react';
import { copyTextToClipboard, sendMail } from '../../../utils/function/functions';
import { getGlobalSettingsParams } from '../../../store/globalSettingsSlicer';
import { TK_SAVED_CONFIG_PARAM_KEY } from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { PopUpType, showPopUp } from '../../PopUp/ShowPopUp';
import { popUpTypes } from '../../PopUp/PopUpType';
import { useWindowSize } from '../../../utils/threekitHooks';
import { useProductName } from '../../../hooks';
import { handleItemAction } from '../utils';
import { getIsAllSelectedValuesInStock, getIsChina, getScannedSkus } from '../../../store/threekitSlicer';
import { getSavedConfigurationData } from '../../../store/threekitSlicer/selectors/savedConfigurationData';
import { SHARE_ICON } from '../../../assets';

function ShareButton() {
  const { isDesktop } = useWindowSize();
  const [clicked, setClicked] = useState<boolean>(false);
  const globalSettingsParams = useSelector(getGlobalSettingsParams);
  const { appName = '' } = globalSettingsParams || {};
  const isChina = useSelector(getIsChina);
  const configurationSavedData = useSelector(getSavedConfigurationData);
  const dataDrivenSku = window.dataDrivenConfiguratorExtension?.getStatus()?.skus;
  const scannedSkus = useSelector(getScannedSkus);
  const isAllInStock = useSelector(getIsAllSelectedValuesInStock);

  const { baseUrlClient } = useParams();
  const productName = useProductName();

  const clientUrl = useMemo((): string => {
    if (!globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY] || !baseUrlClient) return '';

    const result = `https://${baseUrlClient}/${globalSettingsParams?.[TK_SAVED_CONFIG_PARAM_KEY]}`;
    return encodeURI(result);
  }, [globalSettingsParams, baseUrlClient]);

  const handleShare = useCallback(async (): Promise<void> => {
    /*    if (!clientUrl) {
      console.error('No clientUrl found (probably no configId)');
      return;
    } */
    await copyTextToClipboard(clientUrl);

    showPopUp({
      popUpType: popUpTypes.linkCopied as PopUpType,
      functionOnClose: () => setClicked(false),
    });

    if (isDesktop) {
      sendMail({
        subject: `Your ${productName}`,
        body: clientUrl ? `Link: ${clientUrl}` : '',
      });
    } else
      handleItemAction({
        action: 'share',
        isChina,
        configurationSavedData,
        appName,
        clientUrl,
        globalSettingsParams,
        dataDrivenSku,
        productName,
        scannedSkus,
        setClicked,
        isAllInStock,
      });
  }, [
    clientUrl,
    productName,
    isDesktop,
    appName,
    configurationSavedData,
    dataDrivenSku,
    scannedSkus,
    globalSettingsParams,
    isChina,
    isAllInStock
  ]);

  return (
    <ButtonContainer buttonName={SHARE_BUTTON_LABEL} data-dtname={SHARE_BUTTON_LABEL} onClick={handleShare}>
      <img data-dtname={SHARE_BUTTON_LABEL} alt="" src={SHARE_ICON} />
    </ButtonContainer>
  );
}

export default ShareButton;
