import styled, { css, keyframes } from "styled-components";

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const LoadingText = styled.span(
    ({
      theme: {
        device: { isMobile },
      },
    }) => css`
      text-wrap: nowrap;
      font-family: 'LouisVuitton_Regular';
      align-items: flex-end;
    `
  );

  interface IDot {
    delay: string;
  }

  export const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
  `;
  
  export const Dot = styled.div.withConfig({
    shouldForwardProp: (prop) => !['delay'].includes(prop),
  })<IDot>(
    ({
      theme: {
        device: { isMobile },
      },
      delay,
    }) => css`
      height: 2px;
      width: 2px;
      margin: 4px 5px;
      background-color: #fff;
      border-radius: 50%;
      animation: ${bounce} 1.5s infinite ease-in-out;
      animation-delay: ${delay};
    `
  );