import React, { useMemo } from 'react';
import { ListItem, ListWrapper, SelectedDiv } from './horizontalListSelectorstyles';

export interface IToggleItem {
  id: string;
  label: string;
}
interface HorizontalListSelectorProps {
  items: IToggleItem[];
  selected: IToggleItem;
  handleClick: (item: IToggleItem) => void;
}

const HorizontalListSelector: React.FC<HorizontalListSelectorProps> = ({ items, selected, handleClick }) => {
  const selectedItemDetails = useMemo(() => {
    const selectedItemEl = document.getElementById(`list-item-${selected?.id}`);
    return {
      itemWidth: selectedItemEl?.offsetWidth || 50,
      itemPosition: selectedItemEl?.offsetLeft || 0,
    };
  }, [selected?.id]);

  return (
    <ListWrapper id="HorizontalListSelector">
      {items.map((item, index) => (
        <ListItem
          id={`list-item-${item.id}`}
          key={index}
          selected={selected.id === item.id}
          onClick={() => handleClick(item)}
        >
          {item.label}
        </ListItem>
      ))}
      <SelectedDiv
        style={{
          left: selectedItemDetails.itemPosition,
          width: selectedItemDetails.itemWidth,
        }}
      />
    </ListWrapper>
  );
};

export default HorizontalListSelector;
