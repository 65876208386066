import styled from "styled-components";

export const RotatePlayerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isRotateOpen'].includes(prop),
}) <{ isRotateOpen: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  gap: ${({ isRotateOpen }) => isRotateOpen ? '8px' : '0'};
  margin-right: auto;
  background-color: ${({ isRotateOpen }) => isRotateOpen ? '#00000033' : ''};
  padding: ${({ isRotateOpen }) => isRotateOpen ? '10px 7px' : ''};
  border-radius: 100px;
  transition: all 0.3s;
  pointer-events: auto;
`;

export const PlusIcon = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isRotateOpen', 'img'].includes(prop),
}) <{ isRotateOpen: boolean, img: string }>`
  width: 40px;
  height: 40px;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  margin-top: ${({ isRotateOpen }) => isRotateOpen ? '12px' : '0'};
  transition: margin-top 0.3s;

  @media (hover: hover) {
    &:hover {
      background-color: #f0f0f0;
    }
    &:active {
      background-color: #e0e0e0;
    }
  }
`;

export const RotateIcon = styled.div.withConfig({
  shouldForwardProp: (prop) => !['show', 'img'].includes(prop),
}) <{ show: boolean, img: string }>`
  width: 40px;
  height: 40px;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: #fff;
  ${({ show }) => !show ? 'height: 0;' : ''}
  transition: height 0.3s;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: #f0f0f0;
    }
    &:active {
      background-color: #e0e0e0;
    }
  }
`;
