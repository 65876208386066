import { useState } from 'react';

function useDoubleTap() {
  const [lastTap, setLastTap] = useState(0);

  const handleDoubleTap = (onDoubleTap: () => void) => {
    const now = new Date().getTime();
    if (now - lastTap < 300) {
      onDoubleTap();
      setLastTap(0);
    } else {
      setLastTap(now);
    }
  };
  

  return handleDoubleTap;
}

export default useDoubleTap;
