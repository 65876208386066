export const LV_LOADER = require('./lvLoader.gif');
export const LEFT_ARROW = require('./leftArrow.png');
export const RIGHT_ARROW = require('./rightArrow.png');
export const CART_ICON = require('./cartIcon.png');
export const CART_ICON_OOB = require('./cartIconOOB.png');
export const EDIT_ICON = require('./editIcon.png');
export const RESET_ICON = require('./resetIcon.png');
export const SHARE_ICON = require('./shareIcon.png');
export const STAR_ICON = require('./starIcon.png');
export const PLUS_ICON = require('./plusIcon.png');
export const MINUS_ICON = require('./minusIcon.png');
export const INSTOCK_ICON = require('./inStockIcon.png');
export const COPY_ICON = require('./copyIcon.png');
export const ICON_360 = require('./icon360.png');
export const INFO = require('./info.png');
export const PRODUCT = require('./product.png');
export const DIALOG = require('./dialog.png');
export const DESCRIPTION_ICON = require('./descriptionIcon.png');
export const BAR_LINE_ICON = require('./barLineIcon.png');
export const CHECKED_RADIO_BUTTON_ICON = require('./checkedRadioButtonIcon.png');
export const UNCHECKED_RADIO_BUTTON_ICON = require('./uncheckedRadioButtonIcon.png');
export const PICTURE_PLACEHOLDER = require('./picturePlaceholder.png');
export const ADD_OPTIONAL_SELECTED = require('./addOptionalSelected.png');
export const ADD_OPTIONAL_UNSELECTED = require('./addOptionalUnselected.png');
export const DONT_ADD_OPTIONAL_SELECTED = require('./dontAddOptionalSelected.png');
export const DONT_ADD_OPTIONAL_UNSELECTED = require('./dontAddOptionalUnselected.png');
export const WARN_ICON = require('./warnIcon.png');
export const WARN_BLACK_ICON = require('./warnIconBlack.png');
export const V_ICON = require('./vIcon.png');
export const LEFT_ARROW_SLIDER = require('./leftArrowSlider.png');
export const LEFT_ARROW_SLIDER_BLACK = require('./leftArrowSliderBlack.png');
export const RIGHT_ARROW_SLIDER = require('./rightArrowSlider.png');
export const RIGHT_ARROW_SLIDER_BLACK = require('./rightArrowSliderBlack.png');
export const ZOOM_ICON = require('./zoom.png');
export const UNZOOM_ICON = require('./unzoom.png');
export const INFO_PRESS_ICON = require('./press.png');
export const HELPER_ICON = require('./help.png');
export const DOT_ACTIVE = require('./dotActive.png');
export const DOT_INACTIVE = require('./dotInactive.png');
export const CLOSE_ICON = require('./close.png');
export const CLOSE_ICON_HELPER = require('./closeIcon.png');
export const PLUS_ATTRIBUTE_ICON = require('./plusAttrIcon.png');
export const CLOSE_ICON_BLACK = require('./closeIconBlack.png');
export const SUMMARY_ICON = require('./summaryIcon.png');
export const WISH_ICON = require('./wishIcon.png');
export const SUMMARY_IMG = require('./summaryImg.png');
export const LOUIS_VUITTON_LOGO = require('./louisVuittonLogo.png');
export const PLAYER_GREY_BACKGROUND = require('./playerGreyBackground.jpg');
export const animatedRotation = require('./animatedRotationLow.webp');
export const animatedRotationCapucine = require('./animatedRotationLowCapucine.webp');
export const animatedMultiple = require('./horizontal-lvi.webp');
export const animatedLogoProgress = require('./animatedLogoProgress.webp');
export const animatedMultipleCapucine = require('./animatedmultipleCapucine.webp');
export const LouisVuittonDemi = require('../styles/fonts/LouisVuitton-Demi.ttf');
export const LouisVuittonMedium = require('../styles/fonts/LouisVuitton-Medium.ttf');
export const LouisVuittonRegular = require('../styles/fonts/LouisVuitton-Regular.ttf');
export const SCAN_ICON = require('./scanIcon.png');
export const VALID_ICON = require('./validIcon.png');
export const ROTATE_LEFT = require('./rotateLeft.png');
export const ROTATE_RIGHT = require('./rotateRight.png');
export const OPEN_ROTATE_ICON = require('./openRotateIcon.png');
